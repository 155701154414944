import {useCallback, useEffect, useState} from 'react';
import {getUserAssignList} from '../../../api/user';


const useCardAssignController = (userId: number) => {
    const [loading, setLoading] = useState<boolean>(false);
    const [userAssignEmails, setUserAssignEmails] = useState<string[]>([]);

    const getUserAssignListApi = useCallback(() => {
        setLoading(true);
        getUserAssignList(userId)
            .then((res) => {
                if (res.error) return;

                setUserAssignEmails(res.data ? res.data : []);
            })
            .finally(() => setLoading(false));
    }, [userId]);

    useEffect(() => {
        getUserAssignListApi();
    }, [userId]);

    return {
        userAssignEmails,
        loading
    };
};

export default useCardAssignController;