import React, { FC, useState } from 'react';
import styles from './text-area.module.scss';
import i18n from '../../../translations/i18n';
import {inputValidation} from '../../../utils/validationUtils';

export interface TextAreaMessage {
    error: boolean,
    msg: string
}

interface TextAreaProps {
    id?: string
    value?: string
    onChange?: React.ChangeEventHandler<HTMLTextAreaElement>
    placeholder?: string
    className?: string
    label?: string
    labelText? : string
    notAllowedSpecialChars?: RegExp
    disabled?: boolean,
    msg?: TextAreaMessage,
    rows: number,
    required?: boolean,
    maxLength?: number
}

export const TextArea: FC<TextAreaProps> = ({
    id, label, labelText, value, onChange, placeholder, className, notAllowedSpecialChars, disabled, msg, rows, required, maxLength
}) => {
    const [error, setError] = useState<string | undefined>(undefined);
    const [inputValue, setInputValue] = useState<string>(value ?? '');

    const handleChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        const { value } = event.target;
        const sanitizedValue = notAllowedSpecialChars ? value.replace(notAllowedSpecialChars, '') : value;

        if (notAllowedSpecialChars && !inputValidation(value, notAllowedSpecialChars)) {
            setError(i18n.t('profile.error.specialCharacters'));
        } else {
            setError(undefined);
            setInputValue(sanitizedValue);
            if (onChange) {
                onChange(event);
            }
        }
    };

    const containerClassName = `${styles.container} ${className}`;
    return (
        <div className={containerClassName}>
            <div className={styles.labelContainer}>
                <label className={styles.label}>
                    {label} {required && <span>*</span>}
                </label>
                {labelText}
            </div>
            <textarea
                id={id}
                className={styles.textArea}
                onChange={handleChange}
                placeholder={placeholder}
                value={inputValue}
                rows={rows}
                disabled={disabled}
                maxLength={maxLength}
            />
            {(error || msg?.error) && <div className={styles.error}>{error || msg?.msg}</div>}
            {(msg && !msg.error) && <div className={styles.success}>{msg.msg}</div>}
        </div>
    );
};