import {SUPPORTED_LANGUAGES} from '../../../types/language';

export const getSupportedLanguageItems = (): string[] => (
    SUPPORTED_LANGUAGES.map(lang => lang.item)
);

export const getSupportedLanguageValues = (): string[] => (
    SUPPORTED_LANGUAGES.map(lang => lang.value.toUpperCase())
);

export const getDefaultLanguageValue = (userLanguage: string | undefined): string => {
    const language = userLanguage ? SUPPORTED_LANGUAGES.find(l => l.value === userLanguage)?.item : null;

    return language ? language : SUPPORTED_LANGUAGES[0].item;
};

export const getSupportedLanguageFromItem = (langItem: string): { item: string, value: string } => (
    SUPPORTED_LANGUAGES.find(l => l.item === langItem) ?? SUPPORTED_LANGUAGES[0]
);

export const getSupportedLanguageFromValue = (langValue: string): { item: string, value: string } => (
    SUPPORTED_LANGUAGES.find(l => l.value === langValue.toLocaleUpperCase()) ?? SUPPORTED_LANGUAGES[0]
);