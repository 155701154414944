import {useCallback, useState} from 'react';
import {
    deleteWalletCoverImgByIds,
    deleteWalletProfileImgByIds,
    downloadAppleWalletPass,
    downloadGoogleWalletPass,
    saveWalletCard
} from '../../api/walletCard';
import {Basic} from '../../types/cardProfile';
import {WalletStyle} from '../../types/walletCard';
import {downloadFile, downloadFileInNewTab} from '../../utils/download';
import {isAndroid, isIOS} from '../../utils/device';
import {useAlert} from '../AlertProvider';
import {ERROR} from '../../constants/common';
import {isBlank} from '../../utils/common';

const useSaveWalletCardController = (id: number, walletCardId: number | undefined) => {
    const [walletLoading, setWalletLoading] = useState<boolean>(false);
    const [addToWalletLoading, setAddToWalletLoading] = useState<boolean>(false);
    const [error, setError] = useState<boolean>(false);
    const {setAlert} = useAlert();

    const saveWalletCardInfo = async (currentProfileImage: string, currentCoverImage: string,
        wallet?: Basic, walletStyle?: WalletStyle , profileImage?: File, coverImage?: File ) => {
        setWalletLoading(true);
        const formData = new FormData();

        const walletData = {
            ...wallet,
            ...walletStyle
        };
    
        formData.append('wallet', JSON.stringify(walletData));

        if (isBlank(currentProfileImage) && !profileImage) {
            await deleteWalletProfileImgByIds([id]);
        } else {
            if (profileImage) {
                formData.append('profileImage', profileImage);
            }
        }

        if (isBlank(currentCoverImage) && !coverImage) {
            await deleteWalletCoverImgByIds([id]);
        } else {
            if (coverImage) {
                formData.append('coverImage', coverImage);
            }
        }

        saveWalletCard(id, formData)
            .then((res) => {
                if (res.error) {
                    setError(true);
                    alert(res.error.response.data.error);
                } else {
                    setError(false);
                }
            })
            .finally(() => setWalletLoading(false));
    };

    const handleAddWallet = useCallback(() => {
        if (!walletCardId || addToWalletLoading) return;

        const isAndroidDevice = isAndroid();
        const isIOSDevice = isIOS();

        if (isAndroidDevice) {
            setAddToWalletLoading(true);
            downloadGoogleWalletPass(walletCardId)
                .then(res => {
                    if (res.status === 422) {
                        setError(true);
                        setAlert(ERROR, 'Failed to generate wallet pass. Company logo is mandatory!');
                        return;
                    }
                    if (res.error) return;
                    setError(false);
                    downloadFileInNewTab(res.data);
                })
                .finally(() => setAddToWalletLoading(false));

        } else if (isIOSDevice) {
            setAddToWalletLoading(true);
            downloadAppleWalletPass(walletCardId)
                .then(res => {
                    if (res.status === 422) {
                        setError(true);
                        setAlert(ERROR, 'Failed to generate wallet pass. Company logo is mandatory!');
                        return;
                    }

                    if (res.error) return;
                    setError(false);
                    const blob = new Blob([res.data], { type: 'application/vnd.apple.pkpass' });
                    downloadFile(window.URL.createObjectURL(blob), 'loyalty.pkpass');
                })
                .finally(() => setAddToWalletLoading(false));
        }
    }, [walletCardId]);

    return {
        saveWalletCardInfo,
        walletLoading,
        handleAddWallet,
        addToWalletLoading,
        error
    };
};

export default useSaveWalletCardController;
