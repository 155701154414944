import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { CardProfileConverter } from '../../converters/api/CardProfileConverter';
import { CardProfile } from '../../types/cardProfile';
import { getMultipleApiImageCall } from '../../api/base';
import {
    cardProfileCoverImageByUuidUrl,
    cardProfileImageByUuidUrl,
    getCardProfileByUuid,
} from '../../api/cardProfile';
import {isArrayBufferEmpty, notEmpty} from '../../utils/common';

const usePreviewCardProfileController = () => {
    const [loading, setLoading] = useState<boolean>(false);
    const [cardProfileData, setCardProfileData] = useState<CardProfile | null>(null);
    const [profilePhoto, setProfilePhoto] = useState<string>('');
    const [coverPhoto, setCoverPhoto] = useState<string>('');
    const { uuid } = useParams();

    useEffect(() => {
        if (!uuid || loading) return;

        setLoading(true);
        getCardProfileByUuid(uuid)
            .then(res => {
                const data = res.error ? null : CardProfileConverter.convertFromCardProfileApiToCardProfile(res.data);
                setCardProfileData(data);

                getMultipleApiImageCall([`${cardProfileImageByUuidUrl}/${uuid}`, `${cardProfileCoverImageByUuidUrl}/${uuid}`],false)
                    .then(responses => {
                        const data = responses?.data;
                        const profileResponse = data[`${cardProfileImageByUuidUrl}/${uuid}`];
                        const coverResponse = data[`${cardProfileCoverImageByUuidUrl}/${uuid}`];
                        if (notEmpty(profileResponse) && !isArrayBufferEmpty(profileResponse.data)) {
                            const profileBlob = new Blob([profileResponse.data], { type:  'application/octet-stream' });
                            const profileUrl = URL.createObjectURL(profileBlob);
                            setProfilePhoto(profileUrl);
                        }

                        if (notEmpty(coverResponse) && !isArrayBufferEmpty(coverResponse.data)) {
                            const coverBlob = new Blob([coverResponse.data], { type: 'application/octet-stream' });
                            const coverUrl = URL.createObjectURL(coverBlob);
                            setCoverPhoto(coverUrl);
                        }
                    });
            })
            .finally(() => setLoading(false));
    }, []);


    return {
        loading,
        cardProfileData,
        profilePhoto,
        coverPhoto,
    };
};

export default usePreviewCardProfileController;