import {Company, CompanyInvitation, CompanyMember, CompanyMemberUpdater, CompanyRoleType} from '../../types/company';
import {UserConverter} from './UserConverter';
import {COMPANY_ROLE_ADMIN, COMPANY_ROLE_USER, COMPANY_ROLE_OWNER} from '../../constants/accountRole';
import {CompanyUser} from '../../types/user';
import {isEmpty} from '../../utils/common';

export const CompanyConverter = {
    convertFromCompanyApiToCompanies(data: any[]): Company[] {
        if (isEmpty(data)) return [];

        return data
            .map(d => CompanyConverter.convertFromCompanyApiToCompany(d))
            .filter((company): company is Company => company !== undefined);
    },
    convertFromCompanyApiToCompany(data: any): Company | undefined {
        if (!data) return undefined;

        return {
            id: data.id,
            name: data.name,
            creatorId: data.creatorId,
            role: CompanyConverter.convertFromCompanyRoleApiToCompanyRole(data.role)
        };
    },
    convertFromCompanyMembersApiToCompanyMembers(data: any): CompanyMember[] {
        if (isEmpty(data)) return [];

        const companyMembers: CompanyMember[] = [];

        data.forEach((d: { webUser: any; company: any; role: any; title: any; }) => {
            companyMembers.push({
                user: UserConverter.convertFromCompanyMemberApiToCompanyUser(d.webUser) as CompanyUser,
                role: CompanyConverter.convertFromCompanyRoleApiToCompanyRole(d.role),
                title: d.title
            });
        });

        return companyMembers;
    },
    convertFromCompanyRoleApiToCompanyRole(data: any): CompanyRoleType {
        if (!data) return COMPANY_ROLE_USER as CompanyRoleType;
        if (data === 'OWNER') return COMPANY_ROLE_OWNER  as CompanyRoleType;
        if (data === 'ADMIN') return COMPANY_ROLE_ADMIN  as CompanyRoleType;
        return COMPANY_ROLE_USER as CompanyRoleType;
    },
    convertFromCompanyMemberToCompanyMemberUpdater(userId: number, role: CompanyRoleType): CompanyMemberUpdater {
        return {
            userId: userId,
            role: CompanyConverter.convertFromCompanyRoleToCompanyRoleApi(role)
        };
    },
    convertFromCompanyRoleToCompanyRoleApi(data: CompanyRoleType): string {
        if (data=== COMPANY_ROLE_OWNER) return 'OWNER';
        if (data  === COMPANY_ROLE_ADMIN) return 'ADMIN';
        if (data  === COMPANY_ROLE_USER) return 'MEMBER';
        return 'MEMBER';
    },
    convertToCompanyInvitation(userEmail: string, role: CompanyRoleType): CompanyInvitation {
        return {
            userEmail: userEmail,
            role: CompanyConverter.convertFromCompanyRoleToCompanyRoleApi(role)
        };
    }
};