import { FC } from 'react';
import styles from './preview.module.scss';
import { WalletCardBasic, WalletStyle } from '../../../types/walletCard';
import useQrcodeController from '../../common/shareModal/useQrcodeController';
import i18n from '../../../translations/i18n';

interface IOSPreviewProps {
    walletBasic?: WalletCardBasic,
    coverPhoto: string
    profilePhoto: string
    walletStyle?: WalletStyle
    uuid: string,
    newProfilePhoto?: string
    newCoverPhoto?: string
}

const IOSPreview: FC<IOSPreviewProps> = ({ walletBasic, coverPhoto, profilePhoto, walletStyle, uuid }) => {
    const { qrCode } = useQrcodeController(uuid);

    return (
        <>
            <div className={styles.containerIOS} style={{ backgroundColor: walletStyle?.cardBgColor }}>
                <div className={styles.top} >
                    <div className={styles.headerContainerIOS} style={{ backgroundColor: walletStyle?.cardBgColor }}>
                        {profilePhoto &&
                            <div className={styles.companyLogoWrapperIOS}>
                                <img className={styles.companyLogoIOS} src={profilePhoto} alt=""/>
                            </div>
                        }
                        <div className={styles.company} style={{ color: walletStyle?.cardTextColor }}>
                            {walletBasic?.company || ''}
                        </div>
                    </div>
                    <div className={styles.coverContainerIOS}>
                        {coverPhoto
                            ? <img className={styles.cover} src={coverPhoto} alt="" />
                            : <div className={styles.blankCover}></div>}
                    </div>
                    <div className={styles.infosContainer}>
                        <div className={styles.infoContainer}>
                            <span
                                style={{color: walletStyle?.cardTextColor}}>{i18n.t('labels.fullname').toUpperCase()}</span>
                            <div className={styles.info}
                                style={{color: walletStyle?.cardTextColor}}>{walletBasic?.fullname?.toUpperCase() || ''}</div>
                        </div>
                        <div className={styles.companyPositionWrapper}>
                            <div className={styles.infoContainer}>
                                <span
                                    style={{color: walletStyle?.cardTextColor}}>{i18n.t('labels.company').toUpperCase()}</span>
                                <div className={styles.info}
                                    style={{color: walletStyle?.cardTextColor}}>{walletBasic?.company || ''}</div>
                            </div>
                            <div className={styles.infoContainer}>
                                <span
                                    style={{color: walletStyle?.cardTextColor}}>{i18n.t('labels.position').toUpperCase()}</span>
                                <div className={styles.info}
                                    style={{color: walletStyle?.cardTextColor}}>{walletBasic?.position?.toUpperCase() || ''}</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={styles.bottom}>
                    <div className={styles.qrcodeIOS}>
                        {qrCode &&
                            <img src={qrCode} alt="QR code" className={styles.qrCodeImg}/>
                        }
                    </div>
                </div>
            </div>
        </>
    );
};

export default IOSPreview;