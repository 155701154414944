import { jwtDecode } from 'jwt-decode';
import sign from 'jwt-encode';

const SECRET = 'icards_3og1f7';

export const decode = (token: string) => jwtDecode(token);

export const encode = (token: any) => sign(token, SECRET);


export const getBulkUrlDecodedToken = (token: string | null) => {
    if (token) {
        try {
            const decoded = decode(token) as { ids: number[] };
            return decoded.ids;
        } catch (error) {
            return [];
        }
    }

    return [];
};