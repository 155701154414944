import React, {FC, useState} from 'react';
import styles from './lost-card-modal.module.scss';
import { ReactComponent as CloseIcon } from '../../../assets/icons/close.svg';
import { ReactComponent as ArrowRightIcon } from '../../../assets/icons/arrow/arrow-right.svg';

import i18n from '../../../translations/i18n';
import Divider from '../divider/Divider';
import { CustomSwitch } from '../switch/CustomSwitch';
import { ACTIVE, CardProfileStatus, INACTIVE } from '../../../types/userCardProfile';
import ModalContainer from '../modalContainer/ModalContainer';

interface LostCardModalProps {
    isOpen: boolean;
    onClose: () => void;
    isActive: boolean;
    handleSwitchStatus: (status: CardProfileStatus) => void;
}

export const LostCardModal: FC<LostCardModalProps> = ({
    isOpen, onClose, isActive, handleSwitchStatus
}) => {

    const [checkedSwitch, setCheckedSwitch] = useState<boolean>(!isActive);

    const handleSwitchChange = (e: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
        handleSwitchStatus(checked ? INACTIVE : ACTIVE);
        setCheckedSwitch(checked);
    };

    if (!isOpen) return <></>;

    return (
        <ModalContainer>
            <div className={styles.header}>
                <div className={styles.headerTxt}>{i18n.t('modal.lostPhysicalCard')}</div>
                <div className={styles.icon} onClick={onClose}><CloseIcon stroke='#808080'/></div>
            </div>
            <Divider/>
            <div className={styles.content}>
                <div className={styles.contentDesc}>{i18n.t('modal.lostPhysicalCardText')}</div>
                <div className={styles.contentBox}>
                    <div>
                        <div className={styles.contentBoxTitle}>{i18n.t('common.setInactive')}</div>
                        <div className={styles.contentBoxDesc}>{i18n.t('modal.setInactiveText')}</div>
                    </div>
                    <div className={styles.switchWrapper}>
                        <CustomSwitch
                            checked={checkedSwitch}
                            handleChange={handleSwitchChange}
                        />
                    </div>
                </div>
                <div className={styles.contentBox}>
                    <div>
                        <div className={styles.contentBoxTitle}>{i18n.t('myCards.orderNewCard')}</div>
                        <div className={styles.contentBoxDesc}>{i18n.t('modal.orderNewCardText')}</div>
                    </div>
                    <div className={styles.icon}>
                        <ArrowRightIcon/>
                    </div>
                </div>
            </div>
        </ModalContainer>
    );
};