import {
    CardProfileAbout,
    CardProfileActivateRequest, CardProfileAssignRequest,
    CardProfileSocialLinksUpdate,
    CardProfileStyle,
    CardProfileToggle,
    ContactAndAdress
} from '../types/cardProfile';
import {CardProfileApiFilter, CardProfileStatus} from '../types/userCardProfile';
import {
    configDefault,
    getApiCall,
    configDefaultWithToken,
    postApiCall,
    patchApiCall,
    configForImages,
    deleteApiCall
} from './base';


const cardProfileUrl = '/api/card-profile';
const cardProfilesUrl = '/api/card-profile/profiles';
const cardProfileUpdateSelectAllUrl = `${cardProfileUrl}/update/select-all`;
const cardProfileByUuidUrl = `${cardProfileUrl}/uuid`;
const cardProfileAboutSectionUrl = `${cardProfileUrl}/about`;
const cardProfileGeneralSectionUrl = `${cardProfileUrl}/basic`;
const carProfileSocialLinksSectionUrl = `${cardProfileUrl}/social-links`;
const cardProfileContactSectionUrl = `${cardProfileUrl}/contact`;
const cardProfileAppearanceUrl = `${cardProfileUrl}/style`;
const cardProfileToggleUrl = `${cardProfileUrl}/toggle/bulk-update`;
const cardProfileActivateUrl = `${cardProfileUrl}/activate`;
const cardProfileAssignUrl = `${cardProfileUrl}/assign`;
const cardProfileBulkUpdateUrl = `${cardProfileUrl}/bulk-update`;

const getUserCardProfilesBasicUrl = (id: number) => `${cardProfilesUrl}/basic/${id}`;
const cardCompanyProfileUpdateSelectAllUrl = (companyId: number) => `${cardProfileUrl}/company/${companyId}/select-all`;

export const cardProfileImageByUuidUrl = `${cardProfileUrl}/profile-image/uuid`;
export const cardProfileCoverImageByUuidUrl = `${cardProfileUrl}/cover-image/uuid`;
export const cardProfileImageByIdUrl = `${cardProfileUrl}/profile-image`;
export const cardProfileCoverImageByIdUrl = `${cardProfileUrl}/cover-image`;
export const cardProfileImageBulkRemoveUrl = `${cardProfileUrl}/profile-image/bulk-remove`;
export const cardProfileCoverImageBulkRemoveUrl = `${cardProfileUrl}/cover-image/bulk-remove`;
export const cardProfileStatusUrl = `${cardProfileUrl}/status`;
export const qrCodeUrl = '/api/qrcode';
export const cardProfilesBusinessUrl = (userId: number, companyId: number) => `${cardProfilesUrl}/${userId}/business/${companyId}`;
export const cardProfilesAssignUrl = (cardProfileId: number, companyId: number) => `${cardProfileAssignUrl}/${cardProfileId}/company/${companyId}`;

export const getCardProfilesByUserId = async (id: number) => {
    const config = {
        ...(await configDefaultWithToken())
    };

    return await getApiCall(`${cardProfilesUrl}/${id}`, config);
};

export const getCardProfilesBusinessByUserId = async (userId: number, companyId: number) => {
    const config = {
        ...(await configDefaultWithToken())
    };

    return await getApiCall(cardProfilesBusinessUrl(userId, companyId), config);
};

export const getCardProfileByUuid = async (uuid: string) => {
    const config = {
        ...(await configDefault())
    };

    return await getApiCall(`${cardProfileByUuidUrl}/${uuid}`, config);
};

export const getCardProfileImage = async (uuid: string) => {
    const config = {
        ...(await configDefault())
    };

    return await getApiCall(`${cardProfileImageByUuidUrl}/${uuid}`, config);
};

export const getCardProfileImageById = async (id: number) => {
    const config = {
        ...(await configDefaultWithToken()),
        responseType: 'arraybuffer'
    };

    return await getApiCall(`${cardProfileImageByIdUrl}/${id}`, config);
};

export const getCardProfileCoverImage = async (uuid: string) => {
    const config = {
        ...(await configDefault())
    };

    return await getApiCall(`${cardProfileCoverImageByUuidUrl}/${uuid}`, config);
};

export const getCardProfileById = async (id: number) => {
    const config = {
        ...(await configDefaultWithToken())
    };

    return await getApiCall(`${cardProfileUrl}/${id}`, config);
};

export const saveAboutSection = async (id: string, aboutData?: CardProfileAbout) => {
    const config = {
        ...(await configDefaultWithToken())
    };

    return await postApiCall(`${cardProfileAboutSectionUrl}/${id}`, aboutData, config);
};

export const saveGeneralSection = async (id: string, data: FormData) => {
    const config = {
        ...(await configForImages()),
    };

    return await patchApiCall(`${cardProfileGeneralSectionUrl}/${id}`, data, config);
};

export const saveSocialLinksSection = async (id: string, links: CardProfileSocialLinksUpdate[]) => {
    const config = {
        ...(await configDefaultWithToken())
    };

    return await postApiCall(`${carProfileSocialLinksSectionUrl}/${id}`, links, config);
};

export const saveContactSection = async (id: string, data: ContactAndAdress) => {
    const config = {
        ...(await configDefaultWithToken())
    };

    return await patchApiCall(`${cardProfileContactSectionUrl}/${id}`, data, config);
};

export const getUserCardProfiles = async (userId: number, filter: CardProfileApiFilter) => {

    const config = {
        ...(await configDefaultWithToken()),
        params: {
            searchByName: filter.searchByName,
            status: filter.status,
            page: filter.page
        }
    };

    return await getApiCall(getUserCardProfilesBasicUrl(userId), config);
};

export const saveAppearanceSection = async (id: string, data?: CardProfileStyle) => {
    const config = {
        ...(await configDefaultWithToken())
    };

    return await patchApiCall(`${cardProfileAppearanceUrl}/${id}`, data, config);
};

export const updateCardProfileStatus = async (id: number, data: CardProfileStatus) => {
    const config = {
        ...(await configDefaultWithToken())
    };

    return await patchApiCall(`${cardProfileStatusUrl}/${id}`, data, config);
};

export const saveToggleSection = async (ids: number[], data: CardProfileToggle) => {
    const config = {
        ...(await configDefaultWithToken())
    };

    const queryParams = new URLSearchParams({ ids: ids.join(',') }).toString();

    return await patchApiCall(`${cardProfileToggleUrl}?${queryParams}`, data, config);
};

export const deleteCardProfileById = async (id: number) => {
    const config = {
        ...(await configDefaultWithToken())
    };

    return await deleteApiCall(`${cardProfileUrl}/${id}`, config);
};

export const deleteCardProfileCoverImgByIds = async (ids: number[]) => {
    const config = {
        ...(await configDefaultWithToken())
    };
    const queryParams = new URLSearchParams({ ids: ids.join(',') }).toString();

    return await deleteApiCall(`${cardProfileCoverImageBulkRemoveUrl}?${queryParams}`, config);
};

export const deleteCardProfileImgByIds = async (ids: number[]) => {
    const config = {
        ...(await configDefaultWithToken())
    };
    const queryParams = new URLSearchParams({ ids: ids.join(',') }).toString();

    return await deleteApiCall(`${cardProfileImageBulkRemoveUrl}?${queryParams}`, config);
};

export const getQrcode = async (uuid: string) => {
    const config = {
        ...(await configDefaultWithToken()),
        responseType: 'arraybuffer'
    };

    return await getApiCall(`${qrCodeUrl}/${uuid}`, config);
};

export const saveProfilCardInfo = async (ids: number[], data: FormData) => {
    const config = {
        ...(await configForImages())
    };
    const queryParams = new URLSearchParams({ ids: ids.join(',') }).toString();

    return await patchApiCall(`${cardProfileBulkUpdateUrl}?${queryParams}`, data, config);
};

export const activateCardProfile = async (uuid: string, data: CardProfileActivateRequest) => {
    const config = {
        ...(await configDefaultWithToken())
    };

    return await postApiCall(`${cardProfileActivateUrl}/${uuid}`, data, config);
};

export const assignCardProfile = async (cardProfileId: number, companyId: number, data: CardProfileAssignRequest) => {
    const config = {
        ...(await configDefaultWithToken())
    };

    return await postApiCall(cardProfilesAssignUrl(cardProfileId, companyId), data, config);
};

export const updateCardProfileSelectAll = async (ids: number[], data: FormData) => {
    const config = {
        ...(await configForImages())
    };
    const queryParams = new URLSearchParams({ idsToNotUpdate: ids.join(',') }).toString();

    return await patchApiCall(`${cardProfileUpdateSelectAllUrl}?${queryParams}`, data, config);
};

export const updateCompanyCardProfileSelectAll = async (ids: number[], companyId: number, data: FormData) => {
    const config = {
        ...(await configForImages())
    };
    const queryParams = new URLSearchParams({ idsToNotUpdate: ids.join(',') }).toString();

    return await patchApiCall(`${cardCompanyProfileUpdateSelectAllUrl(companyId)}?${queryParams}`, data, config);
};