import React, {FC, useMemo, useState,} from 'react';
import styles from './invitation-modal.module.scss';
import i18n from '../../../translations/i18n';
import Divider from '../divider/Divider';
import { ReactComponent as CloseIcon } from '../../../assets/icons/close.svg';
import ModalContainer from '../modalContainer/ModalContainer';
import {Input} from '../input/Input';
import {TEXT} from '../../../types/input';
import {COMPANY_ROLE_ITEMS} from '../../../constants/accountRole';
import {Selector} from '../selector/Selector';
import { ReactComponent as ArrowDownIcon } from '../../../assets/icons/arrow/arrow-down.svg';
import {CompanyRoleType} from '../../../types/company';
import {selectedItemStyle, selectStyle} from './SelectorStyle';
import BtnContainer from '../button/BtnContainer';
import {isEmpty} from '../../../utils/common';
import {ERROR} from '../../../constants/common';
import {sendCompanyInvitation} from '../../../api/company';
import {useAlert} from '../../../context/AlertProvider';
import {CompanyConverter} from '../../../converters/api/CompanyConverter';
import {COMPANY_ID} from '../../../store/utils/userSession';
import {useAppSelector} from '../../../store/appDispatch';
import {selectUser} from '../../../store/slices/userSessionSlice';

interface InvitationModalProps {
    isOpen: boolean;
    onClose: () => void;
}

const defaultRoleValue = COMPANY_ROLE_ITEMS[1];

const InvitationModal: FC<InvitationModalProps> = ({
    isOpen, onClose
}) => {
    const [userEmail, setUserEmail] = useState<string>('');
    const [role, setRole] = useState<CompanyRoleType>(defaultRoleValue as CompanyRoleType);
    const [loading, setLoading] = useState<boolean>(false);
    const {setAlert} = useAlert();
    const user = useAppSelector(selectUser);

    const roleItems = useMemo(() => COMPANY_ROLE_ITEMS.map(fi => ({value: fi, name: fi})), []);
    const saveDisabled = isEmpty(userEmail);
    const companyId = useMemo(() => COMPANY_ID(),[user.activeCompany]);

    if (!isOpen) return <></>;

    const handleRoleSelect = (value: string) => setRole(value as CompanyRoleType);

    const handleSaveChanges = () => {
        if (!companyId) return;

        setLoading(true);
        sendCompanyInvitation(companyId, CompanyConverter.convertToCompanyInvitation(userEmail, role))
            .then(res => {
                if (res.error) {
                    setAlert(ERROR, i18n.t('messages.internalServerError'));
                } else {
                    setRole(defaultRoleValue as CompanyRoleType);
                    setUserEmail('');
                    onClose();
                }
            })
            .finally(() => setLoading(false));
    };

    const onCloseModal = () => {
        setRole(defaultRoleValue as CompanyRoleType);
        setUserEmail('');
        onClose();
    };

    return (
        <ModalContainer>
            <div className={styles.header}>
                <div className={styles.headerTxt}>
                    {i18n.t('common.addMember')}
                </div>
                <div className={styles.icon} onClick={onClose}>
                    <CloseIcon stroke='#808080'/>
                </div>
            </div>
            <Divider/>
            <div className={styles.content}>
                <Input
                    label={i18n.t('common.emailAddress')}
                    className={styles.inputContainer}
                    type={TEXT}
                    onChange={(e) => setUserEmail(e.target.value)}
                    value={userEmail}
                />
                <div className={styles.selectorWrapper}>
                    <div className={styles.label}>
                        {i18n.t('common.inviteAs')}
                    </div>
                    <Selector
                        items={roleItems}
                        defaultValue={defaultRoleValue}
                        onSelect={handleRoleSelect}
                        formStyle={styles.selector}
                        icon={ArrowDownIcon}
                        selectStyle={selectStyle}
                        selectedItemStyle={selectedItemStyle}
                        notConvert
                    />
                </div>
                <BtnContainer
                    onCancel={onCloseModal}
                    onSave={handleSaveChanges}
                    loading={loading}
                    isDisabled={saveDisabled}
                />
            </div>
        </ModalContainer>
    );
};

export default InvitationModal;