import {useEffect} from 'react';
import {useParams} from 'react-router-dom';
import {addContactSaveTrack, addProfileViewTrack} from '../../api/analytics';
import {TIME_BEFORE_PROFILE_VIEW_IS_TRACKED} from '../../constants/common';


const useAnalyticController = (isAssigned: boolean) => {
    const { uuid } = useParams();


    useEffect(() => {
        if (!isAssigned) return;

        const timeoutId = setTimeout(() => {
            uuid && addProfileViewTrack(uuid);
        }, TIME_BEFORE_PROFILE_VIEW_IS_TRACKED);

        return () => {
            clearTimeout(timeoutId);
        };
    }, [isAssigned]);

    const handleAddContactSaveTrack = () => uuid && addContactSaveTrack(uuid);

    return {
        handleAddContactSaveTrack
    };
};

export default useAnalyticController;