import React, { FC, useState } from 'react';
import { useKeycloak } from '@react-keycloak/web';
import styles from './app-header.module.scss';
import { ReactComponent as IcardsLogoIcon } from '../../../assets/icons/logos/icardLogo.svg';
import { ReactComponent as AvatarIcon } from '../../../assets/icons/avatar.svg';
import { ReactComponent as ArrowIcon } from '../../../assets/icons/arrow/arrow.svg';
import { ReactComponent as ArrowDownIcon } from '../../../assets/icons/arrow/arrow-down.svg';
import { ReactComponent as LogoutIcon } from '../../../assets/icons/logout.svg';
import { ReactComponent as SettingsIcon } from '../../../assets/icons/settings.svg';
import { ReactComponent as VisitIcon } from '../../../assets/icons/visit.svg';
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import i18n from '../../../translations/i18n';
import {navigateTo, routes } from '../../../constants/routes';
import { useNavigate } from 'react-router-dom';
import {useAppDispatch, useAppSelector} from '../../../store/appDispatch';
import {selectUser, selectUserPhoto, setActiveCompany} from '../../../store/slices/userSessionSlice';
import {isBlank, openInNewTab} from '../../../utils/common';
import {ERROR, ICARDS_FULL_URL} from '../../../constants/common';
import { getSupportedLanguageFromValue, getSupportedLanguageValues } from '../../settings/yourAccount/yourAccountUtils';
import { useSessionContext } from '../../../context/SessionProvider';
import {NON_SELECTABLE_ITEM, Selector} from '../../common/selector/Selector';
import useAppHeaderController from './useAppHeaderController';
import {MenuItemStyle, selectCompanyStyle, selectStyle} from './AppHeaderSelectorStyle';
import { LanguageType } from '../../../types/language';
import {Company} from '../../../types/company';
import {setUserActiveCompany} from '../../../api/company';
import {useAlert} from '../../../context/AlertProvider';
import CreateCompanyModal from '../../createCompanyModal/CreateCompanyModal';
import MenuItem from '@mui/material/MenuItem';
import {CompanyConverter} from '../../../converters/api/CompanyConverter';
import NotificationContainer from '../../notification/NotificationContainer';


const AppHeader: FC = () => {
    const { keycloak } = useKeycloak();
    const {setAlert} = useAlert();
    const user = useAppSelector(selectUser);
    const userProfilePhoto = useAppSelector(selectUserPhoto);
    const dispatch = useAppDispatch();
    const [openCreateModal, setOpenCreateModal] = useState<boolean>(false);

    const languageItems = getSupportedLanguageValues().map(lang => ({
        value: lang.toUpperCase(), name: lang.toUpperCase()
    }));

    const companyItems = user.companies.map((c: Company) => ({value: c.id, name: c.name}));
    const hasCompany = companyItems?.length > 0;

    const onLogout = () => keycloak.logout();
    const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);

    const [newLang, setNewLang] = useState<LanguageType| undefined>(undefined);
    const { toggleLanguage } = useSessionContext();
    const { saveUserLanguageApi } = useAppHeaderController();

    const navigate = useNavigate();

    const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
        setAnchorEl((prev) => (prev ? null : event.currentTarget));
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    const TypographyStyle = {
        p: 1.5,
        '&:hover': { backgroundColor: 'rgba(0, 0, 0, 0.1)' },
    };

    const navigateToSettingsPage = () => navigate(navigateTo.settings);
    const navigateToWebsite = () => openInNewTab(ICARDS_FULL_URL);

    const updateNewLanguage = (item: string) => {
        const lang = getSupportedLanguageFromValue(item) as LanguageType | undefined;
        if (!lang || isBlank(lang.value)) return;
        setNewLang(lang);
        toggleLanguage(lang.value);
        saveUserLanguageApi(lang.value);
    };

    const updateNewCompany = (item: number) => {
        setUserActiveCompany(item)
            .then(res => {
                if (res.error) {
                    setAlert(ERROR, i18n.t('messages.internalServerError'));
                } else {
                    const company: Company | undefined = CompanyConverter.convertFromCompanyApiToCompany(res.data?.activeCompany);
                    dispatch(setActiveCompany(company));
                }
            });
    };

    return (
        <>
            <nav className={styles.navContainer}>
                <a href={routes.analytics}>
                    <IcardsLogoIcon className={styles.logo}/>
                </a>
                <div className={styles.separator}>/</div>
                {hasCompany
                    ? <Selector
                        items={companyItems}
                        defaultValue={user.activeCompany?.id}
                        onSelect={updateNewCompany}
                        formStyle={styles.selectorCompany}
                        icon={ArrowDownIcon}
                        MenuProps={MenuItemStyle}
                        selectStyle={selectCompanyStyle}
                        notConvert
                        triggerElement={
                            <MenuItem
                                value={NON_SELECTABLE_ITEM}
                                style={{ fontSize: '14px', border: '1px solid #808080',borderRadius: '4px', margin:' 4px'}}
                                onClick={() => setOpenCreateModal(true)}
                            >
                                {i18n.t('labels.createCompany')}
                            </MenuItem>
                        }
                    />
                    : <div className={styles.switcher} onClick={() => setOpenCreateModal(true)}>
                        {i18n.t('header.switchToBusinessAccount')}
                    </div>
                }
                <div className={styles.userInfo}>
                    <NotificationContainer />
                    <div style={{width: '60px'}}>
                        <Selector
                            items={languageItems}
                            defaultValue={newLang ? newLang.value.toUpperCase() : user?.language.toUpperCase()}
                            onSelect={updateNewLanguage}
                            formStyle={styles.selector}
                            icon={ArrowDownIcon}
                            MenuProps={MenuItemStyle}
                            selectStyle={selectStyle}
                            notConvert
                        />
                    </div >
                    <div className={styles.userInfo} aria-haspopup="true" aria-controls={id} onClick={handleClick} role="button" >
                        <div className={styles.avatar}>
                            {userProfilePhoto
                                ? <img className={styles.avatarImage} src={userProfilePhoto} alt="Avatar AndroidPreview" />
                                : <AvatarIcon/>
                            }
                        </div>
                        <div className={styles.username}>
                            {user?.fullname}
                        </div>
                        <div className={styles.arrow}>
                            <ArrowIcon />
                        </div>
                    </div>     
                    <Popover
                        id={id}
                        open={open}
                        anchorEl={anchorEl}
                        onClose={handleClose}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'left',
                        }}
                    >
                        <Typography className={`${styles.settingWrapperSignIn} ${styles.settingWrapper}`} sx={TypographyStyle}>
                            <div className={styles.subtext}>
                                {i18n.t('header.signedInAs')}
                            </div>
                            <div className={styles.text}>
                                {user?.email}
                            </div>
                        </Typography>
                        <Typography className={`${styles.text} ${styles.settingWrapper}`} sx={TypographyStyle} onClick={navigateToSettingsPage}>
                            <SettingsIcon />
                            {i18n.t('header.accountSetting')}
                        </Typography>
                        <Typography className={`${styles.text} ${styles.settingWrapper}`} sx={TypographyStyle} onClick={navigateToWebsite}>
                            <div className={styles.iconWrapper}><VisitIcon /></div>
                            {i18n.t('header.visitWebsite')}
                        </Typography>
                        <div className={styles.logoutButton} onClick={onLogout}>
                            <Typography className={`${styles.text} ${styles.settingWrapper}`} sx={TypographyStyle}>
                                <LogoutIcon />
                                {i18n.t('header.signOut')}
                            </Typography>
                        </div>
                    </Popover>
                </div>
            </nav>
            <CreateCompanyModal
                isOpen={openCreateModal}
                onClose={() => setOpenCreateModal(false)}
            />
        </>
    );
};

export default AppHeader;