import React, { ChangeEvent, FC, ReactNode, useState } from 'react';
import styles from './file-input.module.scss';
import { Input } from '../input/Input';
import { fileValidation } from '../../../utils/validationUtils';


interface FileInputProps {
    id: string;
    label: string;
    className?: string; 
    labelClassName?: string; 
    children?: ReactNode;
    onFileChange?: (file: File) => void;
}

const FileInput: FC<FileInputProps> = ({ id, label, labelClassName, children, className,onFileChange }) => {
    const [error, setError] = useState<string | null>(null);

    const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
        const file = e.target.files?.[0];
        if (!file) return;

        const validationResult = fileValidation(file);
        if (!validationResult.isValid) {
            setError(validationResult.error || '');
            return;
        }
        setError(null);
        onFileChange && onFileChange(file);
    };
  
    return (
        <label className={`${labelClassName} ${styles.label}`} htmlFor={id}>
            {label}
            {children}
            <Input type="file" id={id} onChange={handleFileChange} className={className} />
            {error && <div className={styles.error}>{error}</div>}
        </label>
    );
};

export default FileInput;