export const selectCompanyStyle = {
    backgroundColor: 'black',
    color: 'white',
    '.MuiOutlinedInput-notchedOutline': {
        borderColor: 'black',
    },
    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
        border: 'none'
    },
    '&:hover .MuiOutlinedInput-notchedOutline': {
        border: 'none',
    },
    '.MuiSelect-select': {
        display: 'block',
        textWrap: 'nowrap',
        overflow: 'hidden !important',
        textOverflow: 'ellipsis'
    },
    '.MuiSelect-icon': {
        stroke : '#FFFFFF',
        top: 'auto',
    },
};

export const selectStyle = {
    backgroundColor: 'black',
    color: 'white',     
    '.MuiOutlinedInput-notchedOutline': {
        borderColor: 'black', 
    },
    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
        border: 'none'
    },
    '&:hover .MuiOutlinedInput-notchedOutline': {
        border: 'none',
    },
    '.MuiSelect-icon' : {
        stroke : '#FFFFFF',
        top: 'auto'
    },
};


export const MenuItemStyle = {
    PaperProps: {
        sx: {
            minWidth: '60px !important',
        },
    },
    anchorOrigin: {
        vertical: 'bottom',
        horizontal: 'left',
    },
    transformOrigin: {
        vertical: 'top',
        horizontal: 'left',
    },
};
