import React, {FC, useMemo, useState} from 'react';
import styles from './your-account.module.scss';
import i18n from '../../../translations/i18n';
import {Input} from '../../common/input/Input';
import {PASSWORD, TEXT} from '../../../types/input';
import {Selector} from '../../common/selector/Selector';
import CustomButton from '../../common/button/CustomButton';
import AvatarImageInput from '../../common/fileInput/AvatarImageInput';
import {useAppSelector} from '../../../store/appDispatch';
import {removeUserProfilePhoto, selectUser, selectUserPhoto} from '../../../store/slices/userSessionSlice';
import BtnContainer from '../../common/button/BtnContainer';
import {UserChangePassword} from '../../../types/user';
import useYourAccountController from './userYourAccountController';
import {notBlank} from '../../../utils/common';
import {useDebouncedCallback} from 'use-debounce';
import {API_CALL_LONG_DEBOUNCE_TIME_IN_MS} from '../../../constants/common';
import {useSessionContext} from '../../../context/SessionProvider';
import {
    getDefaultLanguageValue,
    getSupportedLanguageFromItem,
    getSupportedLanguageItems
} from './yourAccountUtils';
import SettingsBox from '../SettingsBox';
import {deleteUserProfileImgById} from '../../../api/user';
import { useDispatch } from 'react-redux';
import { LanguageType } from '../../../types/language';
import {CropperModal} from '../../imageCropper/CropperModal';
import SetupTwoFa from './SetupTwoFa';


export const InitialChangePasswordState = {
    newPassword: '',
    confirmPassword: ''
};


const YourAccount: FC = () => {
    const dispatch = useDispatch();
    const user = useAppSelector(selectUser);
    const userProfilePhoto = useAppSelector(selectUserPhoto);

    const [showChangePwdInputs, setShowChangePwdInputs] = useState<boolean>(false);
    const [userChangePassword, setUserChangePassword] = useState<UserChangePassword>(InitialChangePasswordState);
    const [newProfilePhoto, setNewProfilePhoto] = useState<{blob: Blob, img: string} | undefined>(undefined);
    const [newFullname, setNewFullname] = useState<string>('');
    const [newLang, setNewLang] = useState<LanguageType | undefined>(undefined);
    const [cropperModal, setCropperModal] = useState<{ show: boolean, image: string }>({show: false, image: ''});

    const langItems = useMemo(() => getSupportedLanguageItems().map(li => ({value: li, name: li})), []);

    const { toggleLanguage } = useSessionContext();


    const {
        saveUserSettingsApi, changeUserPasswordApi, loading, responseMsg
    } = useYourAccountController(setShowChangePwdInputs, setUserChangePassword);

    const handleProfilePhotoChange = (newPhoto: File) => {
        setCropperModal({show: true, image: URL.createObjectURL(newPhoto as Blob)});
    };

    const handleCloseCropperModal = () => setCropperModal({show: false, image: ''});

    const handleOnSaveImage = (croppedImg: File) => {
        const blob = new Blob([croppedImg]);
        setNewProfilePhoto({blob, img: URL.createObjectURL(blob)});
        handleCloseCropperModal();
    };

    const openChangePasswordInputs = () => setShowChangePwdInputs(true);

    const handleChangeUserPassword = () => changeUserPasswordApi(userChangePassword);

    const isSaveDisabled = () => (
        !userChangePassword || userChangePassword.newPassword.trim() === '' || userChangePassword.confirmPassword.trim() === ''
    );

    const updateNewPassword = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value;
        setUserChangePassword(prevState => ({ ...prevState, newPassword: value }));
    };

    const updateConfirmPassword = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value;
        setUserChangePassword(prevState => ({ ...prevState, confirmPassword: value }));
    };

    const updateNewLanguage = (item: string) => {
        const lang = getSupportedLanguageFromItem(item);
        setNewLang(lang);
        toggleLanguage(lang.value);
    };

    const updateNewFullname = (event: React.ChangeEvent<HTMLInputElement>) => setNewFullname(event.target.value);

    const getFullname = () => notBlank(newFullname) ? newFullname : user?.fullname;

    const handleSaveChanges = () => saveUserSettingsApi(newProfilePhoto?.blob, newFullname, newLang?.value);

    const handleSaveChangesDebounced = useDebouncedCallback(() => {
        return handleSaveChanges();
    }, API_CALL_LONG_DEBOUNCE_TIME_IN_MS);

    const hasAnyChanges =  newProfilePhoto !== undefined || notBlank(newFullname) || newLang !== undefined;

    const handleCancelChanges = () => {
        setNewProfilePhoto(undefined);
        setNewFullname('');
        setNewLang(undefined);
        toggleLanguage(user?.language);
        setShowChangePwdInputs(false);
        setUserChangePassword(InitialChangePasswordState);
    };

    const handleRemoveProfile = () => {
        if (newProfilePhoto) {
            setNewProfilePhoto(undefined);
        } else {
            deleteUserProfileImgById(parseInt(user.id))
                .then((res) => {
                    if (res.error) return;
                    setNewProfilePhoto(undefined);
                    dispatch(removeUserProfilePhoto());
                });
        }
    };

    return (
        <SettingsBox title={i18n.t('common.yourAccount')}>
            <div className={styles.imageWrapper}>
                <div>
                    <CropperModal
                        isOpen={cropperModal.show}
                        image={cropperModal.image}
                        onClose={handleCloseCropperModal}
                        onSaveImage={handleOnSaveImage}
                        cropShape={'round'}
                        cropHeight={130}
                        cropWidth={130}
                    />
                    <AvatarImageInput
                        id='avatarImage'
                        onPhotoChange={handleProfilePhotoChange}
                        profilePhoto={newProfilePhoto ? newProfilePhoto.img : userProfilePhoto}
                        handleRemove={handleRemoveProfile}
                    />
                </div>
                {(!newProfilePhoto && !userProfilePhoto) &&
                    <div className={styles.noImage}>
                        {i18n.t('common.noFileChosen')}
                    </div>
                }
            </div>
            <Input
                label={i18n.t('labels.fullname')}
                type={TEXT}
                className={styles.input}
                value={getFullname()}
                onChange={updateNewFullname}
            />
            <Input
                label={i18n.t('common.emailAddress')}
                type={TEXT}
                className={styles.grayInput}
                disabled={true}
                value={user?.email}
            />
            <div className={styles.passwordWrapper}>
                {showChangePwdInputs ?
                    <>
                        <Input
                            label={i18n.t('common.newPassword')}
                            type={PASSWORD}
                            className={styles.inputChangePwd}
                            onChange={updateNewPassword}
                            msg={responseMsg}
                            value={userChangePassword.newPassword}
                        />
                        <Input
                            label={i18n.t('common.confirmPassword')}
                            type={PASSWORD}
                            className={styles.inputChangePwd}
                            onChange={updateConfirmPassword}
                            value={userChangePassword.confirmPassword}
                        />
                    </>
                    : <Input
                        label={i18n.t('common.password')}
                        type={TEXT}
                        className={styles.grayInput}
                        disabled={true}
                        value={'••••••••••••••••••••'}
                        msg={responseMsg}
                    />
                }
                {showChangePwdInputs
                    ? <CustomButton
                        text={i18n.t('common.save')}
                        onClick={handleChangeUserPassword}
                        sx={`${styles.savePwdBtn}`}
                        loading={loading}
                        disabled={isSaveDisabled()}
                    />
                    : <CustomButton
                        text={i18n.t('common.changePassword')}
                        onClick={openChangePasswordInputs}
                        sx={styles.changePwdBtn}
                    />
                }
            </div>
            <div className={styles.languageSelector}>
                <label className={styles.languageText}>
                    {i18n.t('common.language')}
                </label>
                <Selector
                    items={langItems}
                    defaultValue={newLang ? newLang.item : getDefaultLanguageValue(user?.language)}
                    onSelect={updateNewLanguage}
                    formStyle={styles.selector}
                />
            </div>
            <SetupTwoFa />
            <BtnContainer
                onCancel={handleCancelChanges}
                onSave={handleSaveChangesDebounced}
                loading={loading}
                isDisabled={!hasAnyChanges}
            />
        </SettingsBox>
    );
};

export default YourAccount;