import React, {FC, useEffect, useMemo, useState} from 'react';
import {CircularProgress} from '@mui/material';
import SettingsBox from '../SettingsBox';
import i18n from '../../../translations/i18n';
import Member from './Member';
import {useCompanyMemberController} from './useCompanyMemberController';
import { ReactComponent as InviteUserIcon } from '../../../assets/icons/user-plus.svg';
import styles from './company-members.module.scss';
import InvitationModal from '../../common/invitationModal/InvitationModal';
import {IS_COMPANY_OWNER} from '../../../store/utils/userSession';
import {useAppSelector} from '../../../store/appDispatch';
import {selectUser} from '../../../store/slices/userSessionSlice';

const CompanyMembers: FC = () => {
    const user = useAppSelector(selectUser);

    const [isLargeSizeScreen, setIsLargeSizeScreen] = useState<boolean>(false);
    const [openInvitationModal, setOpenInvitationModal] = useState<boolean>(false);
    const {loading, companyMembers, companyId, getCompanyMembersApi} = useCompanyMemberController();

    const isCompanyOwner = useMemo(() => IS_COMPANY_OWNER(), [user.activeCompany]);

    const handleToggleInvitationModal = () => setOpenInvitationModal(prevState => !prevState);

    const checkScreenSize = () => {
        setIsLargeSizeScreen(window.innerWidth > 992);
    };

    useEffect(() => {
        window.addEventListener('resize', checkScreenSize);
        checkScreenSize();
        return () => {
            window.removeEventListener('resize', checkScreenSize);
        };
    }, []);

    if (loading) return <CircularProgress />;

    return (
        <SettingsBox
            title={i18n.t('common.companyMembers')}
            actionElement={
                isCompanyOwner
                    ? <div className={styles.inviteUserContainer} onClick={handleToggleInvitationModal}>
                        <InviteUserIcon />
                        <div className={styles.text}>
                            {i18n.t('common.inviteNewMember')}
                        </div>
                    </div>
                    : undefined
            }
        >
            <div className={styles.settings}>
                {isLargeSizeScreen &&
                    <div className={styles.tableHeader}>
                        <div className={styles.member}>{i18n.t('common.member')}</div>
                        <div className={styles.role}>{i18n.t('common.role')}</div>
                        <div className={styles.title}>{i18n.t('common.title')}</div>
                    </div>
                }
                {companyMembers.map(companyMember => (
                    <Member
                        key={companyMember.user.id}
                        companyMember={companyMember}
                        isLargeSizeScreen={isLargeSizeScreen}
                        companyId={companyId}
                        getCompanyMembersApi={getCompanyMembersApi}
                    />
                ))}
            </div>
            <InvitationModal
                isOpen={openInvitationModal}
                onClose={() => setOpenInvitationModal(false)}
            />
        </SettingsBox>
    );
};

export default CompanyMembers;