
export const LINKEDIN = 'Linkedin';
export const X = 'X';
export const INSTAGRAM = 'Instagram';
export const FACEBOOK = 'Facebook';
export const TIKTOK = 'TikTok';
export const YOUTUBE = 'Youtube';
export const SNAPCHAT = 'Snapchat';
export const PINTEREST = 'Pinterest';
export const THREADS = 'Threads';
export const REDDIT = 'Reddit';

export type SocialLinks = typeof LINKEDIN | typeof X | typeof INSTAGRAM | typeof TIKTOK | typeof FACEBOOK | typeof YOUTUBE | typeof SNAPCHAT | typeof PINTEREST | typeof THREADS| typeof REDDIT;