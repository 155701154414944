import React, { FC, useState } from 'react';
import styles from './contact-widget.module.scss';
import { ReactComponent as ContactWidgetIcon } from '../../assets/icons/contactWidget.svg';
import { ReactComponent as ICardsLogoIcon } from '../../assets/icons/logos/icardsLogo-mark.svg';
import { ReactComponent as CloseIcon } from '../../assets/icons/close.svg';
import i18n from '../../translations/i18n';
import {CONTACT_ITEMS, ContactType, EMAIL_CONTACT, PHONE_CONTACT, WHATSAPP_CONTACT} from './contactWidgetUtils';
import {CONTACT_PHONE_NUMBER, ICARDS_SUPPORT_EMAIL, WHATSAPP_URL} from '../../constants/common';
import {openInNewTab} from '../../utils/common';


const ContactWidget: FC = () => {
    const [open, setOpen] = useState<boolean>(false);

    const toggleModal = () => setOpen(prevState => !prevState);

    const getContactAction = (type: ContactType) => {
        if (type === PHONE_CONTACT) window.location.href = `tel:${CONTACT_PHONE_NUMBER}`;
        if (type === EMAIL_CONTACT) window.location.href = `mailto:${ICARDS_SUPPORT_EMAIL}`;
        if (type === WHATSAPP_CONTACT) openInNewTab(WHATSAPP_URL);
    };

    if (!open) {
        return (
            <div className={styles.contactWidget} onClick={toggleModal}>
                <ContactWidgetIcon />
            </div>
        );
    }

    return (
        <>
            <div className={styles.container}>
                <div className={styles.header}>
                    <div className={styles.logo}>
                        <ICardsLogoIcon />
                    </div>
                    <div className={styles.headerText}>
                        {i18n.t('contact.hello')}
                    </div>
                    <div className={styles.x} onClick={toggleModal}>
                        <CloseIcon className={styles.close}/>
                    </div>
                </div>

                <div className={styles.description}>
                    {i18n.t('contact.description1')}<br/>
                    {i18n.t('contact.description2')}
                </div>

                <div className={styles.contactContainer}>
                    {CONTACT_ITEMS.map(item => {
                        const ContactIcon = item.icon;
                        const ContactActionIcon = item.actionIcon;

                        return (
                            <div
                                className={styles.contact}
                                key={item.id}
                                onClick={() => getContactAction(item.type)}
                            >
                                <ContactIcon />
                                <div className={styles.contactTextWrapper}>
                                    <div className={styles.contactTextTitle}>
                                        {i18n.t(item.title)}
                                    </div>
                                    <div className={styles.contactTextDesc}>
                                        {item.descTranslate ? i18n.t(item.desc) : item.desc}
                                    </div>
                                </div>
                                {item.actionIcon &&
                                    <div className={styles.contactActionIcon}>
                                        <ContactActionIcon />
                                    </div>
                                }
                            </div>
                        );
                    })}
                </div>

                <div className={styles.footer}>
                    <div className={styles.xText} onClick={toggleModal}>
                        {i18n.t('common.close')}
                    </div>
                </div>
            </div>
        </>
    );
};

export default ContactWidget;
