import {useMemo, useState} from 'react';
import {
    saveWalletCardBulk, updateCompanyWalletProfileSelectAll, updateWalletProfileSelectAll
} from '../../api/walletCard';
import {Basic} from '../../types/cardProfile';
import {WalletStyle} from '../../types/walletCard';
import {COMPANY_ID} from '../../store/utils/userSession';
import {useAppSelector} from '../../store/appDispatch';
import {selectUser} from '../../store/slices/userSessionSlice';
import {ERROR, SUCCESS} from '../../constants/common';
import i18n from '../../translations/i18n';
import {useAlert} from '../../context/AlertProvider';


const useWalletCardBulkController = (
    ids: number[], selectAll: boolean, isMyCards: boolean, noofCards: number
) => {
    const {setAlert} = useAlert();
    const user = useAppSelector(selectUser);

    const [walletLoading, setWalletLoading] = useState<boolean>(false);
    const companyId = useMemo(() => COMPANY_ID(), [user.activeCompany]);

    const saveWalletCardInfoApi = (formData: FormData) => {
        if (isMyCards && selectAll) return updateWalletProfileSelectAll(ids, formData);
        if (!isMyCards && selectAll && companyId) return updateCompanyWalletProfileSelectAll(ids, companyId, formData);
        return saveWalletCardBulk(ids, formData);
    };

    const saveWalletCardInfo = async (
        wallet?: Basic, walletStyle?: WalletStyle, profileImage?: File, coverImage?: File
    ) => {
        setWalletLoading(true);
        const formData = new FormData();

        const walletData = {
            ...wallet,
            ...walletStyle
        };

        formData.append('wallet', JSON.stringify(walletData));

        if (profileImage) {
            formData.append('profileImage', profileImage);
        }

        if (coverImage) {
            formData.append('coverImage', coverImage);
        }

        saveWalletCardInfoApi(formData)
            .then((res) => {
                if (res.error) {
                    setAlert(ERROR, res.error.response.data.error);
                } else {
                    setAlert(SUCCESS, i18n.t('messages.profilesSuccessfullyUpdated', {noofCards: noofCards}));
                }
            })
            .finally(() => setWalletLoading(false));
    };


    return {
        saveWalletCardInfo,
        walletLoading
    };
};

export default useWalletCardBulkController;
