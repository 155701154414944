import React, { createContext, FC, useContext, useState, ReactNode } from 'react';

interface FormData {
    [key: string]: string;
}

interface FormContextType {
    formData: FormData;
    setFormData: React.Dispatch<React.SetStateAction<FormData>>;
}

interface FormProviderProps {
    children: ReactNode;
}

const FormContext = createContext<FormContextType | undefined>(undefined);

export const FormProvider: FC<FormProviderProps> = ({ children }) => {
    const [formData, setFormData] = useState<FormData>({});

    return (
        <FormContext.Provider value={{ formData, setFormData }}>
            {children}
        </FormContext.Provider>
    );
};

export const useFormContext = () => {
    const context = useContext(FormContext);
    if (!context) {
        throw new Error('useFormContext must be used within a FormProvider');
    }
    return context;
};