import React, {FC, ReactNode} from 'react';
import i18n from '../../translations/i18n';
import styles from './active-your-card-profile.module.scss';
import { ReactComponent as EmptyContentImg } from '../../assets/icons/empty-profile.svg';
import { ReactComponent as IcardsLogoImg } from '../../assets/icons/logos/icardsLogo-mark.svg';

interface ActiveYourCardProfileProps {
    uuid: string;
    footer: ReactNode;
}

const portalUrl = process.env.REACT_APP_PORTAL_URL;

const ActiveYourCardProfile: FC<ActiveYourCardProfileProps> = ({
    uuid, footer
}) => {

    const redirectToLoginPage = () => window.location.href = `${portalUrl}/?activate=${uuid}`;

    return (
        <div className={styles.container}>
            <div className={styles.card}>
                <div className={styles.logo}>
                    <IcardsLogoImg width={60} height={60}/>
                </div>
                <div className={styles.activateContainer}>
                    <div className={styles.title}>
                        {i18n.t('activate.welcomeMessage1')}
                    </div>
                    <div className={styles.text}>
                        {i18n.t('activate.welcomeMessage2')}
                    </div>
                    <div className={styles.activateBtn} onClick={redirectToLoginPage}>
                        {i18n.t('activate.welcomeActivateButton')}
                    </div>
                </div>
                <EmptyContentImg className={styles.emptyImg} />
                <div className={styles.textBottom}>
                    {i18n.t('activate.welcomeMessage3')}
                </div>
                {footer}
            </div>
        </div>
    );
};

export default ActiveYourCardProfile;