import { useState, useEffect } from 'react';
import { getQrcode } from '../../../api/cardProfile';

const useQrcodeController = (uuid: string) => {
    const [qrCode, setQrCode] = useState<any>();

    useEffect(() => {
        getQrcode(uuid)
            .then(res => {
                const blob = new Blob([res.data], { type: 'image/png' });
                const qrCodeUrl = URL.createObjectURL(blob); 
                setQrCode(qrCodeUrl);
            });
    }, [uuid]);

    return {
        qrCode
    };
};

export default useQrcodeController;