import React, {FC, useCallback} from 'react';
import Alert from '@mui/material/Alert';
import {ERROR, SUCCESS} from '../../constants/common';
import styles from './alert-component.module.scss';
import {useAlert} from '../../context/AlertProvider';


export type AlertType = typeof SUCCESS | typeof ERROR;

export interface AlertMessage {
    text?: string,
    type?: AlertType;
    setAlert: (type: AlertType | undefined, text: string | undefined) => void;
}

const AlertComponent: FC = () => {
    const {text, type, setAlert} = useAlert();

    const closeAlert = useCallback(
        () => {
            setAlert(undefined, undefined);
        },
        [],
    );

    if (!text && !type) {
        return <></>;
    }

    return (
        <Alert
            className={styles.alert}
            variant="filled"
            severity={type}
            onClose={closeAlert}
        >
            {text}
        </Alert>
    );
};

export default AlertComponent;
