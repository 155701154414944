import {FC, SyntheticEvent} from 'react';
import * as React from 'react';
import {Autocomplete, Paper, TextField} from '@mui/material';
import {getAutocompleteStyle, getPaperStyle} from './SelectorWithSearch.style';
import {ReactComponent as FilterIcon} from '../../../assets/icons/filter.svg';

interface SelectorWithSearchProps {
    label?: string,
    items: {label: string, id: number}[],
    onSelect: (value: {label: string, id: number} | null) => void,
    defaultValue?: {label: string, id: number},
    className?: string,
    sx?: any,
    onInputChange?: (value: string) => void,
    iconDisabled?: boolean,
    allowCustomInputs?: boolean
}

export const SelectorWithSearch: FC<SelectorWithSearchProps> = ({
    label, items, onSelect, defaultValue, className, sx, onInputChange, iconDisabled, allowCustomInputs
}) => {

    const handleChange = (
        _event: SyntheticEvent,
        value: string | { label: string, id: number } | null,
    ) => {
        if (typeof value === 'string') { // custom value
            value && onSelect({ label: value, id: -1 });
        } else {
            value && onSelect(value);
        }
    };

    const handleOnInputChange = (event: any) => {
        if (!event) return;
        onInputChange && onInputChange(event.target.value ?? '');
    };

    const sxAutoComplete = sx ? sx : getAutocompleteStyle();

    return (
        <Autocomplete
            disablePortal
            id="selector-with-search"
            filterSelectedOptions
            options={items}
            defaultValue={defaultValue}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            sx={sxAutoComplete}
            className={className}
            renderInput={(params) => <TextField {...params} label={label} />}
            onChange={handleChange}
            onInputChange={handleOnInputChange}
            PaperComponent={props => (
                <Paper elevation={8} sx={getPaperStyle()} {...props} />
            )}
            popupIcon={iconDisabled ? undefined : <FilterIcon />}
            freeSolo={allowCustomInputs}
        />
    );
};

