import {FC} from 'react';
import * as React from 'react';
import {StyledCustomSwitch} from './CustomSwitch.style';
import {useDebouncedCallback} from 'use-debounce';
import {SWITCH_CALL_DEBOUNCE_TIME_IN_MS} from '../../../constants/common';

interface CustomSwitchProps {
    checked: boolean,
    handleChange: (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => void;
}

export const CustomSwitch: FC<CustomSwitchProps> = ({
    checked, handleChange
}) => {

    const onChangeDebounced = useDebouncedCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        return handleChange(e, !checked);
    }, SWITCH_CALL_DEBOUNCE_TIME_IN_MS);

    return (
        <StyledCustomSwitch
            checked={checked}
            onChange={onChangeDebounced}
            inputProps={{ 'aria-label': 'controlled' }}
        />
    );
};