import React from 'react';
import styles from './stats-box.module.scss';
import i18n from '../../../translations/i18n';
import { ReactComponent as ArrowLongIcon } from '../../../assets/icons/arrow/arrow-long.svg';

interface StatsBoxProps {
    title: string;
    stats: number;
    increaseStats: number;
}

const StatsBox: React.FC<StatsBoxProps> = ({
    title, stats, increaseStats
}) => {

    return (
        <div className={styles.container}>
            <div className={`${styles.text} ${styles.title}`}>
                {title}
            </div>
            <div className={`${styles.text} ${styles.stats}`}>
                {stats}
            </div>
            <div className={styles.increaseContainer}>
                <div className={styles.increaseWrapper}>
                    <ArrowLongIcon />
                    <div className={`${styles.text} ${styles.increaseNumber}`}>
                        {increaseStats}%
                    </div>
                </div>
                <div className={`${styles.text} ${styles.increaseText}`}>
                    {i18n.t('analytics.previousPeriod')}
                </div>
            </div>
        </div>
    );
};

export default StatsBox;