import {FC} from 'react';
import SaveButton from '../../common/button/SaveButton';
import styles from './sticky-footer.module.scss';

interface StickyFooterProps {
    isDirty: boolean;
    loading: boolean;
    saveInfos: () => void;
}

const StickyFooter: FC<StickyFooterProps> = ({
    isDirty, loading, saveInfos
}) => {

    return (
        <SaveButton
            isDirty={isDirty}
            loading={loading}
            saveInfos={saveInfos}
            className={styles.saveBtnContainer}
            classNameBtn={styles.saveBtn}
        />
    );
};

export default StickyFooter;