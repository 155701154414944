import React, {FC, useState} from 'react';
import styles from './search-input.module.scss';

interface SearchInputProps {
    placeholder: string;
    onSearch: (value: string) => void;
    className?: string;
}

export const SearchInput: FC<SearchInputProps> = ({
    placeholder, onSearch, className
}) => {
    const [value, setValue] = useState<string>('');

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const val = event.target.value;
        setValue(val);
        onSearch(val);
    };

    const containerStyle = className ? `${styles.container} ${className}` : `${styles.container}`;

    return (
        <div className={containerStyle}>
            <input
                className={styles.input}
                placeholder={placeholder}
                onChange={handleChange}
                value={value}
            />
        </div>
    );
};