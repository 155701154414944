import {ChangeEvent, ChangeEventHandler, FC, useState} from 'react';
import styles from './color-input.module.scss';
import { ReactComponent as ArrowDownIcon } from '../../../../assets/icons/arrow/arrow-down.svg';
import { Input } from '../../../common/input/Input';

interface ColorInputProps {
    label: string,
    value?: string,
    onChange?: ChangeEventHandler<HTMLInputElement>
}

const ColorInput: FC<ColorInputProps> = ({label, value, onChange}) => {
    const [selectedColor, setSelectedColor] = useState<string>(value || '#000000');

    const handleColorChange = (e: ChangeEvent<HTMLInputElement>) => {
        const newColor = e.target.value;
        setSelectedColor(newColor);
        if (onChange) {
            onChange(e);
        }
    };

    return (
        <div className={styles.container}>
            <div className={styles.inputsContainer}>
                <Input
                    label={label}
                    value={selectedColor}
                    onChange={handleColorChange}
                />
                <div className={styles.colorBox}>
                    <input
                        type='color'
                        className={styles.colorInput}
                        value={selectedColor}
                        onChange={handleColorChange}
                    />
                    <div>
                        <ArrowDownIcon stroke="#242222"/>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ColorInput;
