import {KeycloakUser} from '../types/user';

export const KeycloakConverter = {
    convertFromKkTokenToKeycloakUser(token: any): KeycloakUser | null {
        if (!token) return null;

        const access = token['realm_access'];

        return {
            id: token['sub'],
            email: token['email'],
            emailVerified: token['email_verified'],
            fullname: token['name'],
            username: token['preferred_username'],
            realmAccess: {
                roles: access ? access['roles'] : []
            }
        };
    }
};