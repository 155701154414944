import {FC} from 'react';


const Divider: FC = () => {


    return (
        <div style={{
            display: 'flex',
            width: '100%',
            border: '0.5px solid #E6E5E5'
        }}>
        </div>
    );
};

export default Divider;