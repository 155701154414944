import React, {FC} from 'react';
import styles from './custom-button.module.scss';

interface CustomButtonWithIconProps {
    text: string,
    onClick: () => void,
    icon: any,
    sx?: any
}

const CustomButtonWithIcon: FC<CustomButtonWithIconProps> = ({
    text, onClick, icon, sx
}) => {

    return (
        <div
            className={`${sx} ${styles.container}`}
            onClick={onClick}
        >
            {icon}
            <div className={styles.btn}>
                {text}
            </div>
        </div>
    );
};

export default CustomButtonWithIcon;