import { FC, useEffect, useState } from 'react';
import Header from '../layout/Header';
import styles from './links.module.scss';
import { ReactComponent as MinusCircleIcon } from '../../../assets/icons/minusCircle.svg';
import { ReactComponent as PlusIcon } from '../../../assets/icons/plus.svg';
import i18n from '../../../translations/i18n';
import {CardProfileContactUpdate, CardProfileSocialLink} from '../../../types/cardProfile';
import { getMenuItemStyles, options } from '../../../utils/links/links';
import { useToggleContext } from '../../../context/toggle/ToggleProvider';
import { FormControl, MenuItem, Select, SelectChangeEvent, TextField } from '@mui/material';
import { LINKEDIN } from '../../../types/socialLinks';
import { MAX_LINKS } from '../../../constants/common';
import { useCardProfileDataContext } from '../../../context/profile/CardProfileDataProvider';

interface LinksProps {
    socialLinks: CardProfileSocialLink[];
    setProfileSocialLinks: React.Dispatch<React.SetStateAction<CardProfileSocialLink[]>>;
    showIcon?: boolean;
    saveToggleData: (data: any) => void;
    bulkView?: boolean;
}

const Links: FC<LinksProps> = ({
    socialLinks, showIcon, saveToggleData, bulkView
}) => {
    const { profileToggle, setProfileToggle } = useToggleContext();

    const [isExtended, setIsExtended] = useState<boolean>(false);
    const {links, setLinks, setIsDirty, emptyFields, setEmptyFields} = useCardProfileDataContext();

    useEffect(() => {
        if (!bulkView && socialLinks.length === 0) {
            setLinks([{ type: LINKEDIN , details: '' }]);
        }
    }, [socialLinks]);
    
    const handleExtend = () => {
        setIsExtended(prevIsExtended => !prevIsExtended);
    };

    const handleToggle = () => {
        const newToggleState = {
            ...profileToggle, socialLinks: !profileToggle?.socialLinks
        };
        saveToggleData(newToggleState);
        setProfileToggle(newToggleState);
    };


    const handleChange = (index: number, event: SelectChangeEvent) => {
        const selectedPlatform = event.target.value;
        const isPlatformSelected = links.some((link, i) => i !== index && link.type === selectedPlatform);
        if (!isPlatformSelected) {
            setLinks((prevLinks: CardProfileContactUpdate[]) => {
                const updatedLinks = [...prevLinks];
                updatedLinks[index].type = selectedPlatform;
                return updatedLinks;
            });
            setEmptyFields((prevEmptyFields: number[]) => prevEmptyFields.filter(fieldIndex => fieldIndex !== index));
        }
        setIsDirty(true);
    };
    
    const handleDetailsChange = (index: number, event: React.ChangeEvent<HTMLInputElement>) => {
        const details = event.target.value;
        setLinks((prevLinks: CardProfileContactUpdate[]) => {
            const updatedLinks = [...prevLinks];
            updatedLinks[index].details = details;
            return updatedLinks;
        });
        setEmptyFields((prevEmptyFields: number[]) => prevEmptyFields.filter(fieldIndex => fieldIndex !== index));
        setIsDirty(true);
    };

    const handleRemoveLink = (index: number) => {
        setLinks((prevLinks: CardProfileContactUpdate[]) => prevLinks.filter((_, i) => i !== index));
        setIsDirty(true);
    };

    const addNewLink = () => {
        setLinks([...links, { type: '', details: '' }]);
    };

    return (
        <div className={`${styles.container} ${isExtended ? styles.extended : styles.collapsed}`}>
            <Header showIcon={showIcon} title={i18n.t('common.links')} onExtend={handleExtend} isExtended={isExtended} isToggled={profileToggle?.socialLinks} onToggle={handleToggle} />
            {isExtended && (
                <>
                    <form className={styles.form}>
                        <div className={styles.inputsContainer}>
                            {links.map((link, index) => (
                                <div key={index} className={styles.inputContainer}>
                                    <FormControl sx={{ m: 1, minWidth: 140 }} size="small">
                                        <Select
                                            value={link.type}
                                            onChange={(e) => handleChange(index, e)}
                                            displayEmpty
                                            inputProps={{ 'aria-label': 'Without label' }}
                                            error={emptyFields.includes(index)}
                                        >
                                            <MenuItem value="" disabled>{i18n.t('common.selectPlatform')}</MenuItem>
                                            {options.map(option => (
                                                <MenuItem key={option} value={option} style={getMenuItemStyles(option,links)}

                                                >{option}</MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                    <FormControl fullWidth error={emptyFields.includes(index)}>
                                        <TextField
                                            required
                                            value={link.details}
                                            onChange={(e) => handleDetailsChange(index, e as React.ChangeEvent<HTMLInputElement>)}
                                            size="small"
                                            error={emptyFields.includes(index)}
                                            placeholder= "https://www.example.com"
                                            inputProps={{ maxLength: 64 }}
                                        />
                                    </FormControl>
                                    <MinusCircleIcon className={styles.minus} onClick={() => handleRemoveLink(index)} />
                                </div>
                            ))}
                        </div>
                        {links.length < MAX_LINKS &&
                        <div className={styles.addLink} onClick={addNewLink}>
                            <PlusIcon /><div className={styles.addText}>{i18n.t('profile.links.addLink')}</div>
                        </div>}
                    </form>
                </>
            )}
        </div>
    );
};

export default Links;

