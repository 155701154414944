import { configDefaultWithToken, configForImages, deleteApiCall, getApiCall, patchApiCall } from './base';

const walletCardUrl = '/api/wallet/card-profile';
const walletUrl = 'api/wallet';
const walletProfileUpdateSelectAllUrl = `${walletUrl}/update/select-all`;
const walletGooglePassByIdUrl = (id: number) => `${walletUrl}/google-pass/${id}`;
const walletApplePassByIdUrl = (id: number) => `${walletUrl}/apple-pass/${id}`;

export const walletCardBulkUpdateURL = `${walletUrl}/bulk-update`;
export const walletCardProfileBulkRemoveURL = `${walletUrl}/profile-image/bulk-remove`;
export const walletCardCoverBulkRemoveURL = `${walletUrl}/cover-image/bulk-remove`;
const walletCompanyProfileUpdateSelectAllUrl = (companyId: number) => `${walletUrl}/company/${companyId}/select-all`;

export const getWalletCardById = async (id: string) => {
    const config = {
        ...(await configDefaultWithToken())
    };

    return await getApiCall(`${walletCardUrl}/${id}`, config);
};

export const saveWalletCard = async (id: number, data: FormData) => {
    const config = {
        ...(await configForImages())
    };

    return await patchApiCall(`${walletCardUrl}/${id}`, data, config);
};

export const saveWalletCardBulk = async (ids: number[], data: FormData) => {
    const config = {
        ...(await configForImages())
    };
    const queryParams = new URLSearchParams({ ids: ids.join(',') }).toString();

    return await patchApiCall(`${walletCardBulkUpdateURL}?${queryParams}`, data, config);
};

export const deleteWalletCoverImgByIds = async (ids: number[]) => {
    const config = {
        ...(await configDefaultWithToken())
    };
    const queryParams = new URLSearchParams({ ids: ids.join(',') }).toString();

    return await deleteApiCall(`${walletCardCoverBulkRemoveURL}/${queryParams}`, config);
};

export const deleteWalletProfileImgByIds = async (ids: number[]) => {
    const config = {
        ...(await configDefaultWithToken())
    };
    const queryParams = new URLSearchParams({ ids: ids.join(',') }).toString();

    return await deleteApiCall(`${walletCardProfileBulkRemoveURL}/${queryParams}`, config);
};

export const downloadGoogleWalletPass = async (walletId: number) => {
    const config = {
        ...(await configDefaultWithToken())
    };

    return await getApiCall(walletGooglePassByIdUrl(walletId), config);
};

export const downloadAppleWalletPass = async (walletId: number) => {
    const config = {
        ...(await configDefaultWithToken()),
        responseType: 'blob'
    };

    return await getApiCall(walletApplePassByIdUrl(walletId), config);
};

export const updateWalletProfileSelectAll = async (ids: number[], data: FormData) => {
    const config = {
        ...(await configForImages())
    };
    const queryParams = new URLSearchParams({ idsToNotUpdate: ids.join(',') }).toString();

    return await patchApiCall(`${walletProfileUpdateSelectAllUrl}?${queryParams}`, data, config);
};

export const updateCompanyWalletProfileSelectAll = async (ids: number[], companyId: number, data: FormData) => {
    const config = {
        ...(await configForImages())
    };
    const queryParams = new URLSearchParams({ idsToNotUpdate: ids.join(',') }).toString();

    return await patchApiCall(`${walletCompanyProfileUpdateSelectAllUrl(companyId)}?${queryParams}`, data, config);
};