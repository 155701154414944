import {useCallback, useEffect, useState} from 'react';
import {NotificationDto} from '../../types/notification';
import {NotificationConverter} from '../../converters/api/NotificationConverter';
import {
    getNotifications,
    getUnreadNotificationsCounter,
    markAllNotificationAsRead,
    markNotificationAsRead
} from '../../api/notification';

export const INITIAL_NOTIFICATIONS_LOAD = 3;

const useNotificationController = (userId: number) => {
    const [loading, setLoading] = useState<boolean>(false);
    const [notifications, setNotifications] = useState<NotificationDto[]>([]);
    const [unreadCounter , setUnreadCounter] = useState<number>(0);
    const [allLoaded, setAllLoaded] = useState<boolean>(false);

    const countUnreadNotifications = useCallback(() => {
        getUnreadNotificationsCounter(userId)
            .then((res) => setUnreadCounter(res.data));
    }, []);

    const getNotificationsApi = (noof?: number) => {
        setLoading(true);
        noof && setAllLoaded(false);
        getNotifications(userId, noof)
            .then(res => {
                if (res.error) return;

                const notifications = NotificationConverter.convertFromNotificationApiToNotifications(res.data);
                setNotifications(notifications);
                countUnreadNotifications();
                !noof && setAllLoaded(true);
            })
            .finally(() => setLoading(false));
    };

    const markNotificationAsReadApi = (notificationId: number) => {
        markNotificationAsRead(userId, notificationId).then(res => {
            if (res.error) return;

            const notification: NotificationDto | undefined = NotificationConverter.convertFromNotificationApiToNotificationDto(res.data);

            if (notification) {
                const updatedNotifications = notifications.map((n) => n.id === notification.id ? notification : n);
                setNotifications(updatedNotifications);
                countUnreadNotifications();
            }
        });
    };

    const markAllNotificationAsReadApi = () => {
        markAllNotificationAsRead(userId).then(res => {
            if (res.error) return;

            const notifications = NotificationConverter.convertFromNotificationApiToNotifications(res.data);
            setNotifications(notifications);
            countUnreadNotifications();
            setAllLoaded(true);
        });
    };

    useEffect(() => {
        countUnreadNotifications();
    }, [userId]);

    return {
        loading,
        allLoaded,
        notifications,
        setNotifications,
        unreadCounter,
        getNotificationsApi,
        markNotificationAsReadApi,
        markAllNotificationAsReadApi
    };
};

export default useNotificationController;