import React, {FC, useMemo, useState} from 'react';
import styles from './card-assign.module.scss';
import i18n from '../../../translations/i18n';
import CustomButton from '../../common/button/CustomButton';
import {CardProfile} from '../../../types/cardProfile';
import {notBlank} from '../../../utils/common';
import useCardAssignController from './useCardAssignController';
import {SelectorWithSearch} from '../../common/selector/SelectorWithSearch';
import {useAppSelector} from '../../../store/appDispatch';
import {selectUser} from '../../../store/slices/userSessionSlice';
import {getAssignSelectorStyle} from './CardAssignSelector.style';

interface CardAssignProps {
    cardProfile: CardProfile;
    assignCardProfileApi: (userEmail: string) => void;
    loading: boolean;
}

const CardAssign: FC<CardAssignProps> = ({
    cardProfile, assignCardProfileApi, loading
}) => {
    const user = useAppSelector(selectUser);
    const userPending = cardProfile.assignToUserPending;
    const userEmail = notBlank(userPending) ? userPending : cardProfile.user.userEmail;
    const { userAssignEmails } = useCardAssignController(user.id);
    const [newUserEmail, setNewUserEmail] = useState<string>(userEmail || '');

    const items = useMemo(() => userAssignEmails.map((uae, index) => ({label: uae, id: index+1})), [userAssignEmails]);

    const isEmailChanged = newUserEmail !== userEmail;
    const showPendingBlock = notBlank(userPending) && !isEmailChanged;

    const handleAssignCardProfile = () => assignCardProfileApi(newUserEmail);

    const onSelectUserEmail = (value: {label: string, id: number}) => {
        const email = value.label;
        setNewUserEmail(email);
    };

    const handleOnInputChange = (value: string) => setNewUserEmail(value);

    return (
        <div className={styles.container}>
            <div>
                <div className={styles.title}>
                    {i18n.t('common.cardAssignedTo')}
                </div>
                {showPendingBlock ?
                    <span className={styles.pending}>{i18n.t('labels.pendingUserHasNotAcceptYet')}</span>
                    : undefined
                }
            </div>
            <div className={styles.box}>
                <SelectorWithSearch
                    items={items}
                    defaultValue={{label: userEmail, id: 0}}
                    onSelect={onSelectUserEmail as any}
                    sx={getAssignSelectorStyle()}
                    onInputChange={handleOnInputChange}
                    iconDisabled
                    allowCustomInputs
                />
                {isEmailChanged &&
                    <CustomButton
                        text={i18n.t('common.assign')}
                        onClick={handleAssignCardProfile}
                        sx={styles.btn}
                        loading={loading}
                    />
                }
            </div>
        </div>
    );
};

export default CardAssign;