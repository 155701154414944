import {useState} from 'react';
import {
    getUserInfoByKeycloakUserId,
    saveUserSettings
} from '../../../api/user';
import {selectKkUser, selectUser, storeUser} from '../../../store/slices/userSessionSlice';
import {useAppDispatch, useAppSelector} from '../../../store/appDispatch';
import {UserConverter} from '../../../converters/api/UserConverter';

const useAppHeaderController = () => {
    const [loading, setLoading] = useState<boolean>(false);
    const dispatch = useAppDispatch();
    const user = useAppSelector(selectUser);
    const kkUser = useAppSelector(selectKkUser);

    const saveUserLanguageApi = (language: string) => {
        setLoading(true);
        const formData = new FormData();
        language && formData.append('language', language);

        saveUserSettings(user.id, formData)
            .then((res)=>{
                if (res.error) {
                    alert(res.error.response.data.error);
                } else {
                    getUserInfoByKeycloakUserId(kkUser.id)
                        .then(res => {
                            if (res.error) return;
                            dispatch(storeUser(UserConverter.convertFromUserApiToUser(res.data)));
                        });
                }
            })
            .finally(() => setLoading(false));
    };

    return {
        saveUserLanguageApi,
        loading,
    };
};

export default useAppHeaderController;