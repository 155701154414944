import { FC, ReactNode, createContext, useContext } from 'react';

interface SessionContextType {
    toggleLanguage: (language: string) => void;
}

interface SessionProviderProps {
    children: ReactNode;
    toggleLanguage: (language: string) => void;
}

const SessionContext = createContext<SessionContextType>(null!);

export const SessionProvider: FC<SessionProviderProps> = ({ children , toggleLanguage }) => {
    return (
        <SessionContext.Provider value={{ toggleLanguage }}>
            {children}
        </SessionContext.Provider>
    );
};

export const useSessionContext = () => {
    return useContext(SessionContext);
};
