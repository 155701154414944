import { FC, useState } from 'react';
import styles from './lost-card-modal.module.scss';
import { CardProfile } from '../../../types/cardProfile';
import { useParams } from 'react-router-dom';
import { LostCardModal } from './LostCardModal';
import {ACTIVE, CardProfileStatus} from '../../../types/userCardProfile';
import i18n from '../../../translations/i18n';
import {updateCardProfileStatus} from '../../../api/cardProfile';


interface PreviewProps {
    cardProfile?: CardProfile
}

const LostCard: FC<PreviewProps> = ({
    cardProfile
}) => {

    const { id } = useParams();
    if (!id) return <></>;

    const [open, setOpen] = useState<boolean>(false);

    const isProfileActive = cardProfile?.status === ACTIVE;

    const handleUpdateCardProfileStatus = (status: CardProfileStatus) => updateCardProfileStatus(parseInt(id), status);

    const handleLostCardModal = () => setOpen(prevState => !prevState);

    const onClose = () => setOpen(false);

    return (
        <>
            <div className={styles.lostCardText} onClick={handleLostCardModal}>
                {i18n.t('modal.lostPhysicalCard')}
            </div>
            <LostCardModal
                isOpen={open}
                onClose={onClose}
                isActive={isProfileActive}
                handleSwitchStatus={handleUpdateCardProfileStatus}
            />
        </>
    );
};

export default LostCard;