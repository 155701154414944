import { useEffect, useState } from 'react';
import { getWalletCardById } from '../../api/walletCard';
import { getMultipleApiImageCall } from '../../api/base';
import store from '../../store/store';
import {isArrayBufferEmpty, notEmpty} from '../../utils/common';
import { WalletCard } from '../../types/walletCard';
import { WalletCardConverter } from '../../converters/api/WalletCardConverter';
import {cardProfileImageByIdUrl} from '../../api/cardProfile';
import {createPhotoUrl} from '../../utils/image';

const imageBaseUrl = process.env.REACT_APP_IMAGE_BASE_URL;

const useWalletCardController = (id: string) => {
    const [initialLoad, setInitialLoad] = useState<boolean>(true);
    const [loading, setLoading] = useState<boolean>(false);
    const [walletCardData, setWalletCardData] = useState<WalletCard | undefined>();
    const [profilePhoto, setProfilePhoto] = useState<string>('');
    const [coverPhoto, setCoverPhoto] = useState<string>('');
    const [cardProfilePhoto, setCardProfilePhoto] = useState<string>('');

    const kkUser = store.getState().userSession.kkUser;

    useEffect(() => {
        if (loading) return; 
        if (!kkUser) return;

        setLoading(true);

        getWalletCardById(id)
            .then(res => {
                const data = res.error ? undefined :  WalletCardConverter.convertFromWallteCardApiToWalletCard(res.data);
                setWalletCardData(data);
                const profilePhotoPath = data?.profilePhotoPath;
                const profilePhotoUrl = (imageBaseUrl && profilePhotoPath) ? (imageBaseUrl + profilePhotoPath) : '';

                const profileCoverPath = data?.coverPhotoPath;
                const profileCoverUrl = (imageBaseUrl && profileCoverPath) ? (imageBaseUrl + profileCoverPath) : '';

                setProfilePhoto(profilePhotoUrl);
                setCoverPhoto(profileCoverUrl);

                getMultipleApiImageCall([`${cardProfileImageByIdUrl}/${id}`], true)
                    .then(responses => {                        
                        const data = responses?.data;

                        const cardProfileResponse = data[`${cardProfileImageByIdUrl}/${id}`];

                        if (notEmpty(cardProfileResponse)  && !isArrayBufferEmpty(cardProfileResponse.data)) {
                            const cardProfileUrl = createPhotoUrl(cardProfileResponse.data);
                            setCardProfilePhoto(cardProfileUrl);
                        } else {
                            setCardProfilePhoto('');
                        }
                    });
            }).finally(() => {
                setLoading(false);
                initialLoad && setInitialLoad(false);
            });
    }, [kkUser]);

    return {
        loading: loading || initialLoad,
        coverPhoto,
        profilePhoto,
        cardProfilePhoto,
        walletBasicInfo: walletCardData?.walletBasic,
        walletStyleInfo: walletCardData?.walletStyle, 
        uuid: walletCardData?.uuid,    
        walletCardData,
        setProfilePhoto,
        setCoverPhoto
    };
};

export default useWalletCardController;