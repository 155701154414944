import {createContext, FC, ReactNode, useContext, useState} from 'react';
import AlertComponent, {AlertMessage, AlertType} from '../components/alert/AlertComponent';
import {ALERT_TIMEOUT} from '../constants/common';

const AlertContext = createContext<AlertMessage>({
    text: undefined,
    type: undefined,
    setAlert: () => undefined
});

interface AlertProviderProps {
    children: ReactNode;
}

export const useAlert = () => useContext(AlertContext);

export const AlertProvider: FC<AlertProviderProps> = ({children}) => {
    const [text, setText] = useState<string | undefined>(undefined);
    const [type, setType] = useState<AlertType | undefined>(undefined);

    const setAlert = (type: AlertType | undefined, text: string | undefined) => {
        setType(type);
        setText(text);

        const timer = setTimeout(() => {
            setType(undefined);
            setText(undefined);
        }, ALERT_TIMEOUT);

        return () => clearTimeout(timer);
    };

    return (
        <AlertContext.Provider
            value={
                {
                    text,
                    type,
                    setAlert
                }
            }
        >
            <AlertComponent />
            { children }
        </AlertContext.Provider>
    );
};

export default AlertContext;