import {
    ACTIVE, ALL,
    CardProfileApiFilter,
    CardProfileApiFilterStatus,
    CardProfileFilterStatus, DEACTIVATED, INACTIVE,
    UserCardProfileFilter
} from '../../types/userCardProfile';

export const FilerConverter = {
    convertUserCardProfileFilterToCardProfileApiFilter(data: UserCardProfileFilter, defaultFilter: UserCardProfileFilter): CardProfileApiFilter {
        const d = data == null ? defaultFilter : data;

        return {
            searchByName: d.searchByName,
            status: FilerConverter.convertFromCardProfileFilterStatusApiToCardProfileApiFilterStatus(d.status),
            page: d.page
        };
    },
    convertFromCardProfileFilterStatusApiToCardProfileApiFilterStatus(data: CardProfileFilterStatus): CardProfileApiFilterStatus {
        if (!data) return ACTIVE;
        if (data === DEACTIVATED) return INACTIVE;
        if (data === ALL) return ALL;
        return ACTIVE;
    }
};