
export const getAutocompleteStyle = () => ({
    width: 240,
    backgroundColor: '#FFFFFF',
    '& .MuiInputBase-root': {
        height: '48px',
        alignContent: 'center'
    },
    '& .MuiInputBase-input': {
        width: 'fit-content !important'
    },
    '& .MuiOutlinedInput-root': {
        paddingRight: '45px !important'
    },
});

export const getPaperStyle = () => ({
    width: '100%',
    textAlign: 'left'
});
