import React, {FC} from 'react';
import styles from './cropper-modal.module.scss';
import ImageCropper, {CropShape} from './ImageCropper';

interface CropperModalProps {
    isOpen: boolean;
    image: string;
    onClose: () => void;
    onSaveImage: (img: File) => void;
    cropShape: CropShape;
    cropHeight: number;
    cropWidth: number;
}

export const CropperModal: FC<CropperModalProps> = ({
    isOpen, image, onClose, onSaveImage, cropShape, cropHeight, cropWidth
}) => {
    if (!isOpen) return <></>;

    return (
        <div className={styles.container}>
            <div className={styles.modal}>
                <ImageCropper
                    image={image}
                    onClose={onClose}
                    onSaveImage={onSaveImage}
                    cropShape={cropShape}
                    cropHeight={cropHeight}
                    cropWidth={cropWidth}
                />
            </div>
        </div>
    );
};