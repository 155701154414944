import { SendEmail } from '../types/user';
import { configDefaultWithToken, postApiCall } from './base';

const supportEmailUrl = '/api/email/send-support';

export const sendSupportEmailById = async (id: number, data: SendEmail) => {
    const config = {
        ...(await configDefaultWithToken())
    };

    return await postApiCall(`${supportEmailUrl}/${id}`,data, config);
};