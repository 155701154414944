export const selectedItemStyle = {
    fontSize: '14px',
    fontFamily: 'Lato',
    fontWeight: 500
};

export const selectStyle = {
    width: '100px',
    backgroundColor: '#FFFFFF',
    color: '#242222',
    fontSize: '14px',
    fontFamily: 'Lato',
    padding: '12px 21px 12px 13px',
    height: '48px',
    border: '1px solid rgb(230, 230, 230)',
    '.MuiOutlinedInput-notchedOutline': {
        borderColor: '#FFFFFF'
    },
    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
        border: 'none'
    },
    '&:hover .MuiOutlinedInput-notchedOutline': {
        border: 'none',
    },
    '.MuiSelect-icon' : {
        stroke : 'black',
        top: 'auto',
        marginRight: '8px'
    }
};