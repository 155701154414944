export const selectedItemStyle = {
    fontSize: '14px',
    fontFamily: 'Lato',
    fontWeight: 500
};

export const selectStyle = {
    width: 'fit-content',
    backgroundColor: '#FFFFFF',
    color: '#242222',
    fontSize: '14px',
    fontFamily: 'Lato',
    padding: '0 13px 0 4px',
    height: 'auto',
    '.MuiOutlinedInput-notchedOutline': {
        borderColor: '#FFFFFF'
    },
    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
        border: 'none'
    },
    '&:hover .MuiOutlinedInput-notchedOutline': {
        border: 'none',
    },
    '.MuiSelect-icon' : {
        stroke : 'black',
        top: 'auto',
        marginRight: '-4px'
    },
};
