import {useCallback, useEffect, useState} from 'react';
import {getCompanyMembers} from '../../../api/company';
import {CompanyMember} from '../../../types/company';
import {CompanyConverter} from '../../../converters/api/CompanyConverter';
import {useAlert} from '../../../context/AlertProvider';
import i18n from '../../../translations/i18n';
import {ERROR} from '../../../constants/common';
import {COMPANY_ID, IS_COMPANY_ADMIN} from '../../../store/utils/userSession';


export const useCompanyMemberController = () => {
    const [loading, setLoading] = useState<boolean>(false);
    const [companyMembers, setCompanyMembers] = useState<CompanyMember[]>([]);
    const {setAlert} = useAlert();

    const companyId = COMPANY_ID() as number;
    const isCompanyAdmin = IS_COMPANY_ADMIN();

    const getCompanyMembersApi = useCallback(() => {
        setLoading(true);
        getCompanyMembers(companyId)
            .then(res => {
                if (res?.error) {
                    setAlert(ERROR, i18n.t('messages.internalServerError'));
                    return;
                }

                const companyMemberData = CompanyConverter.convertFromCompanyMembersApiToCompanyMembers(res.data);
                companyMemberData && setCompanyMembers(companyMemberData);
            })
            .finally(() => setLoading(false));
    }, [companyId]);

    useEffect(() => {
        if (!companyId || loading || !isCompanyAdmin) return;

        getCompanyMembersApi();
    }, [companyId]);


    return {
        loading,
        companyMembers,
        companyId,
        getCompanyMembersApi
    };
};