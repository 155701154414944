import { FC, ReactNode, createContext, useContext, useState } from 'react';
import {
    CardProfileAbout,
    CardProfileAddress,
    CardProfileBasic,
    CardProfileContact,
    CardProfileSocialLink,
    CardProfileStyle,
} from '../../types/cardProfile';

interface CardProfileDataContextState {
    aboutData?: CardProfileAbout;
    setAboutData: React.Dispatch<React.SetStateAction<any>>;
    basic?: CardProfileBasic;
    setBasicData: React.Dispatch<React.SetStateAction<any>>;
    newProfilePhoto?: File ;
    setNewProfilePhoto: React.Dispatch<React.SetStateAction<any>>;
    newCoverPhoto?: File;
    setNewCoverPhoto: React.Dispatch<React.SetStateAction<any>>;
    links: CardProfileSocialLink[];
    setLinks: React.Dispatch<React.SetStateAction<any>>;
    contactInfos: CardProfileContact[];
    setContact: React.Dispatch<React.SetStateAction<any>>;
    addressInfos?: CardProfileAddress;
    setAddress: React.Dispatch<React.SetStateAction<any>>;
    isCustom: boolean;
    setIsCustom: React.Dispatch<React.SetStateAction<any>>;
    selectedColors?: CardProfileStyle
    setSelectedColors: React.Dispatch<React.SetStateAction<any>>;
    isDirty: boolean
    setIsDirty: React.Dispatch<React.SetStateAction<any>>;
    emptyFields: number[],
    setEmptyFields: React.Dispatch<React.SetStateAction<any>>;
}

interface CardProfileDataProviderProps {
    children: ReactNode;
}

const CardProfileDataContext = createContext<CardProfileDataContextState | undefined>(
    undefined
);

export const CardProfileDataProvider: FC<CardProfileDataProviderProps> = ({ children }) => {
    const [aboutData, setAboutData] = useState<CardProfileAbout | undefined>(
        undefined
    );
    const [basic, setBasicData] = useState<CardProfileBasic>();
    const [newProfilePhoto, setNewProfilePhoto] = useState<File | undefined>();
    const [newCoverPhoto, setNewCoverPhoto] = useState<File | undefined>();
    const [links, setLinks] = useState<CardProfileSocialLink[]>([]);
    const [contactInfos, setContact] = useState<CardProfileContact[]>([]);
    const [addressInfos, setAddress] = useState<CardProfileAddress>();
    const [isCustom, setIsCustom] = useState<boolean>(false);
    const [selectedColors, setSelectedColors] = useState<CardProfileStyle | undefined>();
    const [isDirty, setIsDirty] = useState<boolean>(false);
    const [emptyFields, setEmptyFields] = useState<number[]>([]);

    return (
        <CardProfileDataContext.Provider
            value={{
                aboutData,
                setAboutData,
                basic,
                setBasicData,
                newProfilePhoto,
                newCoverPhoto,
                setNewProfilePhoto,
                setNewCoverPhoto,
                links,
                setLinks,
                contactInfos,
                setContact,
                addressInfos,
                setAddress,
                isCustom,
                setIsCustom,
                selectedColors,
                setSelectedColors,
                isDirty,
                setIsDirty,
                emptyFields,
                setEmptyFields
            }}
        >
            {children}
        </CardProfileDataContext.Provider>
    );
};

export const useCardProfileDataContext = () => {
    const context = useContext(CardProfileDataContext);
    if (!context) {
        throw new Error(
            'useCardProfileDataContext must be used within a CardProfileDataProvider'
        );
    }
    return context;
};
