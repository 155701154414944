import React, {FC, useState} from 'react';
import styles from './share-modal.module.scss';
import { ReactComponent as ShareIcon } from '../../../assets/icons/share-link.svg';
import { ReactComponent as CloseIcon } from '../../../assets/icons/close.svg';
import useQrcodeController from './useQrcodeController';
import i18n from '../../../translations/i18n';
import { ICARDS_FULL_URL } from '../../../constants/common';

interface ShareModalProps {
    isOpen: boolean;
    uuid: string
    onClose: () => void;
    fullName?: string
}

export const ShareModal: FC<ShareModalProps> = ({
    isOpen, uuid, onClose, fullName
}) => {

    const [copied, setCopied] = useState(false);
    const {qrCode} = useQrcodeController(uuid);

    const handleCopy = () => {
        navigator.clipboard.writeText(`${ICARDS_FULL_URL}/${uuid}`);
        setCopied(true);
    };

    const handleDownload = () => {
        if (!qrCode) return;

        const downloadLink = document.createElement('a');
        downloadLink.href = qrCode;
        downloadLink.download = `${fullName}.png`;
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
    };

    if (!isOpen) return <></>;

    return (
        <div className={styles.container}>
            <div className={styles.modal}>
                <div className={styles.header}>
                    <div className={styles.headerTxt}>
                        {i18n.t('common.shareCard')}
                    </div>
                    <div className={styles.closeIcon} onClick={onClose}>
                        <CloseIcon stroke='#808080'/>
                    </div>
                </div>
                <div className={styles.boxLinkWrapper}>
                    <div className={styles.left}>
                        <div className={styles.icon}>
                            <ShareIcon/>
                        </div>
                        <div className={styles.link}>
                            {ICARDS_FULL_URL}/{uuid}
                        </div>
                    </div>
                    <div className={styles.rightText} onClick={handleCopy}>
                        {copied ? i18n.t('common.copied') : i18n.t('common.copy')}
                    </div>
                </div>
                <div className={styles.boxWrapper}>
                    {qrCode &&
                        <img src={qrCode} alt="QR code" className={styles.qrCodeImg} />
                    }
                    <div className={styles.qrCodeTxt}>
                        <div className={styles.qrCodeTitleTxt}>{i18n.t('common.yourBusinessCard')}</div>
                        <div>{i18n.t('common.qrCode')}</div>
                    </div>
                    <div onClick={handleDownload} className={`${styles.rightText}, ${styles.downloadText}`}>
                        {i18n.t('common.download')}
                    </div>
                </div>
            </div>
        </div>
    );
};