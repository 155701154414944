export const getEncodedAndResizedProfilePhoto = async (
    profilePhoto: string, maxWidth: number, maxHeight: number
): Promise<string> => {

    return new Promise((resolve, reject) => {
        const img = new Image();
        img.crossOrigin = 'Anonymous';
        img.onload = () => {
            const canvas = document.createElement('canvas');
            let width = img.width;
            let height = img.height;

            // Calculate new dimensions while preserving aspect ratio
            if (width > height) {
                if (width > maxWidth) {
                    height *= maxWidth / width;
                    width = maxWidth;
                }
            } else {
                if (height > maxHeight) {
                    width *= maxHeight / height;
                    height = maxHeight;
                }
            }

            canvas.width = width;
            canvas.height = height;

            const ctx = canvas.getContext('2d');
            ctx?.drawImage(img, 0, 0, width, height);

            // Get the Base64-encoded image data from the canvas
            const base64data = canvas.toDataURL('image/png');
            resolve(base64data.split(',')[1]);
        };
        img.onerror = reject;
        img.src = profilePhoto;
    });
};

export const createPhotoUrl = (data: any) => (
    URL.createObjectURL(new Blob([data], { type:  'application/octet-stream' }))
);

export const createPhotoFileFromUrl = (url: string) => new File([url], '');