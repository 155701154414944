export const createImage = (url: string): Promise<HTMLImageElement> =>
    new Promise((resolve, reject) => {
        const image = new Image();
        image.addEventListener('load', () => resolve(image));
        image.addEventListener('error', (error) => reject(error));
        image.setAttribute('crossOrigin', 'anonymous');
        image.src = url;
    });

export function getRadianAngle(degreeValue: number) {
    return (degreeValue * Math.PI) / 180;
}

export const rotateSize = (width: number, height: number, rotation: number) => {
    const rotRad = getRadianAngle(rotation);

    return {
        width:
            Math.abs(Math.cos(rotRad) * width) + Math.abs(Math.sin(rotRad) * height),
        height:
            Math.abs(Math.sin(rotRad) * width) + Math.abs(Math.cos(rotRad) * height),
    };
};

const isImageBiggerThan3MB = (imageSize: number) => imageSize > 3 * 1024 * 1024;

const isImageBiggerThan1MB = (imageSize: number) => imageSize > 1024 * 1024;

const getReduceCompressionQuality = (zoom: number, imageSize: number | undefined) => {
    const isBiggerThan1mb = imageSize ? isImageBiggerThan1MB(imageSize) : true;
    const isBiggerThan3mb = imageSize ? isImageBiggerThan3MB(imageSize) : true;

    const fact1 = isBiggerThan3mb ? 1.5 : isBiggerThan1mb ? 1.3 : 1.1;
    const fact2 = isBiggerThan3mb ? 1.8 : isBiggerThan1mb ? 1.6 : 1.3;
    const fact3 = isBiggerThan3mb ? 2.0 : isBiggerThan1mb ? 1.8 : 1.5;

    if (zoom >= 1) return 1;
    if (zoom >= 0.8 && zoom < 1) return fact1;
    if (zoom >= 0.6 && zoom < 0.8) return fact2;
    return fact3;
};


export const getCroppedImg = async (
    imageSrc: any,
    pixelCrop: any,
    zoom: number,
    rotation = 0,
    flip = { horizontal: false, vertical: false }
) => {
    const image = await createImage(imageSrc);
    const canvas = document.createElement('canvas');
    canvas.width = pixelCrop.width;
    canvas.height = pixelCrop.height;
    const ctx = canvas.getContext('2d');
    if (!ctx) {
        return null;
    }

    const rotRad = getRadianAngle(rotation);

    const { width: bBoxWidth, height: bBoxHeight } = rotateSize(
        image.width,
        image.height,
        rotation
    );

    // set canvas size to match the bounding box
    canvas.width = bBoxWidth;
    canvas.height = bBoxHeight;

    // translate canvas context to a central location to allow rotating and flipping around the center
    ctx.translate(bBoxWidth / 2, bBoxHeight / 2);
    ctx.rotate(rotRad);
    ctx.scale(flip.horizontal ? -1 : 1, flip.vertical ? -1 : 1);
    ctx.translate(-image.width / 2, -image.height / 2);

    // draw rotated image
    ctx.drawImage(image, 0, 0);

    const croppedCanvas = document.createElement('canvas');

    const croppedCtx = croppedCanvas.getContext('2d');

    if (!croppedCtx) {
        return null;
    }

    croppedCanvas.width = pixelCrop.width;
    croppedCanvas.height = pixelCrop.height;

    croppedCtx.drawImage(
        canvas,
        pixelCrop.x,
        pixelCrop.y,
        pixelCrop.width,
        pixelCrop.height,
        0,
        0,
        pixelCrop.width,
        pixelCrop.height
    );

    const originalBlob = await new Promise<Blob | null>((resolve) => {
        croppedCanvas.toBlob((blob) => resolve(blob), 'image/png');
    });

    // Adjust canvas resolution based on zoom level
    if (zoom < 1) {
        const imageSize = originalBlob?.size;
        const reduceQualityMultiplier = getReduceCompressionQuality(zoom, imageSize);
        const reducedCanvas = document.createElement('canvas');
        const reducedCtx = reducedCanvas.getContext('2d');

        if (!reducedCtx) {
            return null;
        }

        reducedCanvas.width = croppedCanvas.width / reduceQualityMultiplier;
        reducedCanvas.height = croppedCanvas.height / reduceQualityMultiplier;

        reducedCtx.drawImage(
            croppedCanvas,
            0,
            0,
            croppedCanvas.width,
            croppedCanvas.height,
            0,
            0,
            reducedCanvas.width,
            reducedCanvas.height
        );

        return new Promise<File | null>((resolve) => {
            reducedCanvas.toBlob((blob) => {
                if (blob) {
                    const file = new File([blob], 'cropped.png', { type: 'image/png' });
                    resolve(file);
                } else {
                    resolve(null);
                }
            }, 'image/png', 0.7);
        });
    }

    return new Promise<File | null>((resolve) => {
        croppedCanvas.toBlob((blob) => {
            if (blob) {
                const file = new File([blob], 'cropped.png', { type: 'image/png' });
                resolve(file);
            } else {
                resolve(null);
            }
        }, 'image/png');
    });
};
