import React, {FC, ReactNode} from 'react';
import styles from './modal-container.module.scss';

interface ModalContainerProps {
    children: ReactNode;
}

const ModalContainer: FC<ModalContainerProps> = ({
    children
}) => {
    return (
        <div className={styles.container}>
            <div className={styles.modal}>
                {children}
            </div>
        </div>
    );
};

export default ModalContainer;