import i18n, {translations} from './i18n';

export const toggleLanguage = (userLanguage: string) => {
    const language = userLanguage?.toLowerCase();
    const isSupported = language === 'en' || language === 'fr' || language === 'de';

    if (isSupported) {
        i18n.translations = {
            [language]: translations[language]
        };
        i18n.locale = language;
    }
};
