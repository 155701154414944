import { configureStore } from '@reduxjs/toolkit';
import userSessionSlice from './slices/userSessionSlice';

const store = configureStore({
    reducer: {
        userSession: userSessionSlice
    },
    middleware: (getDefaultMiddleware) => getDefaultMiddleware(),
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export default store;
