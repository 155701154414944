import React, { FC, useState } from 'react';
import FileInput from './FileInput';
import styles from '../fileInput/file-input.module.scss';
import i18n from '../../../translations/i18n';
import { ReactComponent as AvatarIcon } from '../../../assets/icons/general/avatar.svg';
import { ReactComponent as AvatarRectIcon } from '../../../assets/icons/general/avatar-rect.svg';
import {CropShape} from '../../imageCropper/ImageCropper';

interface AvatarImageInputProps {
    id: string;
    onPhotoChange: (newPhoto: File) => void;
    profilePhoto?: string;
    handleRemove?: () => void;
    shape?: CropShape;
    error?: boolean;
}

const AvatarImageInput: FC<AvatarImageInputProps> = ({
    id, onPhotoChange, profilePhoto, handleRemove, shape, error
}) => {
    const [key, setKey] = useState<number>(0);

    const cropStyle = shape === 'rect' ? styles.avatarImageRect : styles.avatarImage;

    const handleFileChange = (file: File) => {
        onPhotoChange(file);
        setKey(prevKey => prevKey + 1);
    };

    const avatarStyle = error ? {border: '2px solid #ED1B24'} : undefined;
    const getAvatar = () => shape === 'rect' ? <AvatarRectIcon style={avatarStyle}/> : <AvatarIcon style={avatarStyle}/>;
    
    return (
        <div className={styles.UploadHeader} key={key}>
            <div className={styles.avatarContainer}>
                {profilePhoto ? (
                    <img
                        className={cropStyle}
                        src={profilePhoto}
                        alt=""
                    />
                ) : (
                    getAvatar()
                )}
            </div>
            <div className={styles.profileBtnContainer}>
                <FileInput
                    id={id}
                    label={profilePhoto ? i18n.t('profile.general.changePic') : i18n.t('profile.general.uploadPic')}
                    labelClassName={styles.customLabel}
                    onFileChange={handleFileChange}
                />
                {profilePhoto &&
                    <div onClick={handleRemove} className={styles.deleteBtn}>
                        {i18n.t('common.remove')}
                    </div>
                }
            </div>
        </div>
    );
};

export default AvatarImageInput;
