import {useEffect} from 'react';
import {useKeycloak} from '@react-keycloak/web';
import {useAppDispatch, useAppSelector} from '../store/appDispatch';
import {getUserInfoByKeycloakUserId} from '../api/user';
import {UserConverter} from '../converters/api/UserConverter';
import {User} from '../types/user';
import {toggleLanguage} from '../translations/i18nUtils';
import {
    selectKkUser,
    selectUser,
    storeKkUser,
    storeUser,
    storeUserPhoto
} from '../store/slices/userSessionSlice';

const imageBaseUrl = process.env.REACT_APP_IMAGE_BASE_URL;

const useLoginController = () => {
    const { initialized, keycloak } = useKeycloak();
    const dispatch = useAppDispatch();
    const user = useAppSelector(selectUser);
    const kkUser = useAppSelector(selectKkUser);

    useEffect(() => {
        if (!initialized) return;

        const token = keycloak.token;
        token && dispatch(storeKkUser(token));
    }, [keycloak, initialized]);

    useEffect(() => {
        if (!user && kkUser) {
            getUserInfoByKeycloakUserId(kkUser.id)
                .then(res => {
                    if (res.error) return;

                    const user: User | null = UserConverter.convertFromUserApiToUser(res.data);
                    dispatch(storeUser(user));
                    user && toggleLanguage(user.language);

                    const profilePhotoPath = user?.profilePhotoPath;
                    const profilePhotoUrl = (imageBaseUrl && profilePhotoPath) ? (imageBaseUrl + profilePhotoPath) : '';
                    dispatch(storeUserPhoto(profilePhotoUrl));
                });
        }
    }, [kkUser]);

    return {
        initialized
    };
};

export default useLoginController;