import React, { FC } from 'react';
import { ReactComponent as DotsIcon } from '../../../assets/icons/dots.svg';
import { ReactComponent as CloseIcon } from '../../../assets/icons/close.svg';
import { ReactComponent as EditIcon } from '../../../assets/icons/edit.svg';
import { ReactComponent as ToggleActiveIcon } from '../../../assets/icons/general/toggleActive.svg';
import { ReactComponent as ToggleIcon } from '../../../assets/icons/general/toggle.svg';
import styles from './header.module.scss';
import i18n from '../../../translations/i18n';

interface HeaderProps {
    title: string;
    onExtend: () => void;
    onToggle?: () => void;
    isExtended: boolean;
    isToggled?: boolean;
    unsavedTitle?: string;
    showIcon?: boolean;
}

const Header: FC<HeaderProps> = ({ title, onExtend, isExtended, onToggle, isToggled, unsavedTitle, showIcon }) => {
    
    return (
        <div className={styles.container}>
            <div className={styles.sides}>
                <div><DotsIcon/></div>
                <div className={styles.title}>
                    {title}
                </div>
            </div>
            <div className={styles.sides}>
                <div className={styles.unsavedTitle}>{unsavedTitle}</div>
                <div className={styles.icons} onClick={onExtend}>{isExtended ? <CloseIcon stroke="#141414"/> : <div className={styles.editIcon}><EditIcon/> {i18n.t('common.edit')}</div>}</div>
                {showIcon && <div className={styles.toggle} onClick={onToggle} >{isToggled ? <ToggleActiveIcon/> : <ToggleIcon/>}</div>}
            </div>
            
            
        </div>
    );
};

export default Header;