import {COMPANY_ID, IS_BUSINESS_ACCOUNT, IS_COMPANY_ADMIN} from '../store/utils/userSession';
import {getAnalyticsByIds, getCompanyAnalyticsById} from './analytics';
import {AnalyticsFilter} from '../types/analytics';
import {
    getCardProfilesBusinessByUserId,
    getCardProfilesByUserId, getUserCardProfiles
} from './cardProfile';
import {getBusinessMyCardProfiles, getCompanyCardProfiles, getCompanyCardProfilesForCompanyMember} from './company';
import {CardProfileApiFilter} from '../types/userCardProfile';


export const getAnalyticsControllerApi = (apiFilter: AnalyticsFilter) => {
    return IS_BUSINESS_ACCOUNT()
        ? getCompanyAnalyticsById(COMPANY_ID() as number, apiFilter)
        : getAnalyticsByIds(apiFilter);
};

export const getCardProfilesControllerApi = (userId: number) => {
    return IS_BUSINESS_ACCOUNT()
        ? getCardProfilesBusinessByUserId(userId, COMPANY_ID() as number)
        : getCardProfilesByUserId(userId);
};


export const getMyCardCardProfilesControllerApi = (userId: number, apiFilter: CardProfileApiFilter) => {
    return IS_BUSINESS_ACCOUNT()
        ? getBusinessMyCardProfiles(userId, COMPANY_ID() as number, apiFilter)
        : getUserCardProfiles(userId, apiFilter);
};


export const getCompanyCardProfilesControllerApi = (userId: number, companyId: number, apiFilter: CardProfileApiFilter) => {
    return IS_COMPANY_ADMIN()
        ? getCompanyCardProfiles(userId, companyId, apiFilter)
        : getCompanyCardProfilesForCompanyMember(userId, companyId, apiFilter);
};