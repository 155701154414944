import { useCallback, useEffect } from 'react';
import {useBlocker } from 'react-router-dom';
import { useConfirm } from '../context/confirm/confirmProvider';
import { ConfirmOptions } from '../types/confirm';

export const usePrompt = ({
    isDirty = false,
    onConfirm,
    onCancel,
}: ConfirmOptions & { isDirty?: boolean }) => {
    const blocker = useBlocker(isDirty);
    const { show } = useConfirm();
    const confirm = useCallback(() => {
        if (!isDirty) return Promise.resolve(true);
        return new Promise<boolean>((resolve) => {
            show({
                onConfirm: () => {
                    resolve(true);
                    onConfirm?.();
                },
                onCancel: () => {
                    resolve(false);
                    onCancel?.();
                },
            });
        });
    }, [
        isDirty,
        onCancel,
        onConfirm,
        show,
    ]);
    useEffect(() => {
        if (blocker.state === 'blocked') {
            confirm().then((result) => {
                if (result) blocker.proceed();
                else blocker.reset();
            });
        }
    }, [blocker, confirm]);
    useEffect(() => {
        if (isDirty) {
            window.onbeforeunload = () => true;
        }
        return () => {
            window.onbeforeunload = null;
        };
    }, [isDirty]);
    return {
        confirm,
    };
};