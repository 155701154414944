import React, {FC, useEffect, useState} from 'react';
import {initOptions, keycloakClient} from './keycloak/Keycloak';
import {ReactKeycloakProvider} from '@react-keycloak/web';
import PageRouter from './routes/PageRouter';
import './assets/css/globals.scss';
import { Suspense } from 'react';
import {Provider} from 'react-redux';
import store from './store/store';
import { ConfirmProvider } from './context/confirm/confirmProvider';
import {SessionProvider} from './context/SessionProvider';
import {toggleLanguage} from './translations/i18nUtils';
import i18n from './translations/i18n';
import {AlertProvider} from './context/AlertProvider';
import {routes} from './constants/routes';
import {matchPath} from 'react-router-dom';
import PublicPageRouter from './routes/PublicPageRouter';

function App() {
    const [language, setLanguage] = useState(i18n.locale);

    const handleToggleLanguage = (lang: string) => {
        const langLowerCase = lang.toLowerCase();
        i18n.locale = langLowerCase;
        setLanguage(langLowerCase);
        toggleLanguage(langLowerCase);
    };

    useEffect(() => {
        const currentLanguage = i18n.locale;

        if (currentLanguage !== language) {
            toggleLanguage(language);
        }
    }, [language]);

    const publicRoutes = [routes.previewCardProfile];
    const publicRoutePattern = /^\/[A-Za-z0-9]{7}$/;
    const isPublicRoute = publicRoutes.some(route => matchPath(route, location.pathname)) && publicRoutePattern.test(location.pathname);

    return (
        <Provider store={store}>
            {isPublicRoute ? (
                <CommonProviders toggleLanguage={handleToggleLanguage}>
                    <PublicPageRouter />
                </CommonProviders>
            ) : (
                <ReactKeycloakProvider
                    authClient={keycloakClient}
                    initOptions={initOptions}
                >
                    <CommonProviders toggleLanguage={handleToggleLanguage}>
                        <PageRouter />
                    </CommonProviders>
                </ReactKeycloakProvider>
            )}
        </Provider>
    );
}

interface CommonProvidersProps {
    toggleLanguage: (lang: string) => void;
    children: React.ReactNode;
}

const CommonProviders: FC<CommonProvidersProps> = ({ toggleLanguage, children }) => {
    return (
        <SessionProvider toggleLanguage={toggleLanguage}>
            <AlertProvider>
                <ConfirmProvider>
                    <Suspense fallback={<div></div>}>
                        {children}
                    </Suspense>
                </ConfirmProvider>
            </AlertProvider>
        </SessionProvider>
    );
};

export default App;
