import {configDefaultWithToken, postApiCall} from './base';


const cardUrl = '/api/card';

const cardOwnerByUuid = (uuid: string) => `${cardUrl}/owner/${uuid}`;

export const changeCardOwner = async (email: string, uuid: string) => {
    const config = {
        ...(await configDefaultWithToken()),
        params: {
            userEmail: email
        }
    };

    return await postApiCall(cardOwnerByUuid(uuid), {}, config);
};