import React, {FC} from 'react';
import styles from './order-new-card-button.module.scss';
import i18n from '../../../translations/i18n';
import {openInNewTab} from '../../../utils/common';
import {ICARDS_FULL_URL} from '../../../constants/common';


const OrderNewCardButton: FC = () => {

    return (
        <div
            className={`${styles.btn} ${styles.container}`}
            onClick={() => openInNewTab(ICARDS_FULL_URL)}
        >
            <div className={styles.plus}>
                +
            </div>
            <div className={styles.btn}>
                {i18n.t('myCards.newCard')}
            </div>
        </div>
    );
};

export default OrderNewCardButton;