import { FC } from 'react';
import ColorInput from '../colorInput/ColorInput';
import styles from './custom-colors.module.scss';
import i18n from '../../../../translations/i18n';
import { CardProfileStyle } from '../../../../types/cardProfile';
import { WalletStyle } from '../../../../types/walletCard';
import WalletCustomColors from './WalletCustomColors';

interface CustomColorsProps {
    profileStyle: CardProfileStyle | undefined,
    setProfileStyle: (styleUpdater: (prevState: CardProfileStyle | WalletStyle | undefined) => CardProfileStyle | undefined) => void
    showColor?: boolean
    isWallet: boolean
}

const CustomColors: FC<CustomColorsProps> = ({
    profileStyle, setProfileStyle, showColor, isWallet
}) => {

    const handleColorChange = (colorKey: keyof CardProfileStyle, color: string) => {
        setProfileStyle((prevState) => ({
            ...prevState,
            [colorKey]: color
        }));
    };

    return (
        <div className={styles.container}>
            <WalletCustomColors
                profileStyle={profileStyle}
                setProfileStyle={setProfileStyle}
                isWallet={isWallet}
            />
            {showColor &&
                <>
                    <div className={styles.inputsContainer}>
                        <ColorInput
                            label={i18n.t('labels.buttonBackgroundColor')}
                            value={profileStyle?.btnBgColor}
                            onChange={(e) => handleColorChange('btnBgColor', e.target.value)}
                        />
                        <ColorInput
                            label={i18n.t('labels.buttonTextColor')}
                            value={profileStyle?.btnTextColor}
                            onChange={(e) => handleColorChange('btnTextColor', e.target.value)}
                        />
                    </div>
                    <div className={styles.inputsContainer}>
                        <ColorInput
                            label={i18n.t('labels.iconBackgroundColor')}
                            value={profileStyle?.iconsBgColor}
                            onChange={(e) => handleColorChange('iconsBgColor', e.target.value)}
                        />
                        <ColorInput
                            label={i18n.t('labels.iconsColor')}
                            value={profileStyle?.iconsColor}
                            onChange={(e) => handleColorChange('iconsColor', e.target.value)}
                        />
                    </div>
                    <div className={styles.inputsContainer}>
                        <ColorInput
                            label={i18n.t('labels.companyBgColor')}
                            value={profileStyle?.companyBgColor}
                            onChange={(e) => handleColorChange('companyBgColor', e.target.value)}
                        />
                        <ColorInput
                            label={i18n.t('labels.companyTextColor')}
                            value={profileStyle?.companyTextColor}
                            onChange={(e) => handleColorChange('companyTextColor', e.target.value)}
                        />
                    </div>
                    <ColorInput
                        label={i18n.t('labels.coverBackgroundColor')}
                        value={profileStyle?.coverBgColor}
                        onChange={(e) => handleColorChange('coverBgColor', e.target.value)}
                    />
                </>
            }
        </div>
    );
};

export default CustomColors;
