import React, {FC, ReactNode} from 'react';
import styles from './settings.module.scss';
import Divider from '../common/divider/Divider';

interface SettingsBoxProps {
    title: string;
    actionElement?: ReactNode,
    children: ReactNode;
}

const SettingsBox: FC<SettingsBoxProps> = ({
    title, actionElement, children
}) => {

    return (
        <div className={styles.boxContainer}>
            <div className={styles.titleContainer}>
                <div className={styles.title}>
                    {title}
                </div>
                {actionElement}
            </div>
            <Divider />
            <>{children}</>
        </div>
    );
};

export default SettingsBox;