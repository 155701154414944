import { FC, ReactNode, createContext, useContext, useEffect, useState } from 'react';
import { CardProfileToggle } from '../../types/cardProfile';

interface ToggleContextType {
    profileToggle: CardProfileToggle | undefined;
    setProfileToggle: React.Dispatch<React.SetStateAction<CardProfileToggle | undefined>>;
}

interface ToggleProviderProps {
    children: ReactNode;
    toggle?: CardProfileToggle
}

const ToggleContext = createContext<ToggleContextType>(null!);

export const ToggleProvider: FC<ToggleProviderProps> = ({ children , toggle}) => {
    const [profileToggle, setProfileToggle] = useState<CardProfileToggle | undefined>();

    useEffect(()=>{
        setProfileToggle(toggle);
    },[toggle]);

    return (
        <ToggleContext.Provider value={{ profileToggle, setProfileToggle }}>
            {children}
        </ToggleContext.Provider>
    );
};

export const useToggleContext = () => {
    return useContext(ToggleContext);
};
