import { FC } from 'react';
import Chart from 'react-google-charts';
import style from './chart.module.scss';
import Divider from '../../common/divider/Divider';
import { getName } from 'country-list';
import { Box, LinearProgress } from '@mui/material';
import i18n from '../../../translations/i18n';
import { AnalyticsCountriesData } from '../../../types/analytics';
import {sortByDesc} from '../../../utils/common';

interface CountriesChartProps {
    title: string,
    data : AnalyticsCountriesData[]
}

const CountriesChart :FC<CountriesChartProps> = ({title, data}) => {
    const countryDataMap: { [key: string]: number } = {};

    data.forEach((item: AnalyticsCountriesData) => {
        const countryCode = item.country.substring(0, 2);
        countryDataMap[countryCode] = (countryDataMap[countryCode] || 0) + Number(item.count);
    });

    const getCountryCodeName = (code: string) => code === 'UN' ? i18n.t('common.others') : getName(code);

    const convertedData: any[][] = Object.entries(countryDataMap).map(([countryCode, count]) => [{v: countryCode, f: getCountryCodeName(countryCode)}, count]);
    sortByDesc(convertedData);
    convertedData.unshift(['Country', 'Count']);

    return(
        <>
            <div className={style.chartWrapper}>
                <div className={style.title}>
                    {title}
                </div>
                <Divider/>
                <div className={style.countriesChart}>
                    <div className={style.leftChart}>
                        <Chart
                            chartEvents={[
                                {
                                    eventName: 'select',
                                    callback: ({ chartWrapper }) => {
                                        const chart = chartWrapper.getChart();
                                        const selection = chart.getSelection();
                                        if (selection.length === 0 || selection[0].row === undefined) return;
                                    },
                                },
                            ]}
                            chartType="GeoChart"
                            width="100%"
                            height="400px"
                            className={style.map}
                            data={convertedData}
                            options={{
                                colorAxis: { colors: ['#D2DAF6', '#05439C'] },
                                datalessRegionColor: '#eeeeee',
                                defaultColor: '#f5f5f5',
                                legend: 'none'
                            }}
                        />
                    </div> 
                    <div className={style.boxInfo}>
                        <div className={style.boxHeader}>
                            <div>{i18n.t('analytics.country').toUpperCase()}</div>
                            <div>{i18n.t('analytics.users').toUpperCase()}</div>
                        </div>
                        <Box sx={{ mt: 2 , width:'100%'}} >
                            {convertedData.slice(1).map((item, index) => (
                                <div key={index} className={style.countryInfos}>
                                    <div className={style.countryInfo}>
                                        <div>{item[0].f}</div>
                                        <div>{item[1]}</div>
                                    </div>
                                    <LinearProgress variant="determinate" value={item[1] as number} />
                                </div>
                            ))}
                        </Box>
                    </div>
                </div>
            </div>
        </>
    );
};

export default CountriesChart;
