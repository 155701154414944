import { FC } from 'react';
import styles from './order-card-box.module.scss';
import i18n from '../../../translations/i18n';
import {ICARDS_WEBSHOP_URL} from '../../../constants/common';

const OrderCardBox: FC = () => {
    return (
        <div className={styles.order} onClick={() => window.open(ICARDS_WEBSHOP_URL, '_blank')}>
            <div className={styles.btn}>
                <div className={styles.plusVertical}></div>
                <div className={styles.plusHorizontal}></div>
            </div>
            <div className={styles.text}>
                {i18n.t('myCards.orderNewCard')}
            </div>
        </div>
    );
};

export default OrderCardBox;