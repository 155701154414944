import {configDefault, configDefaultWithToken, getApiCall, postApiCall} from './base';
import {AnalyticsDataTrack, AnalyticsFilter} from '../types/analytics';
import {getUserDevice} from '../utils/device';

const analyticsUrl = '/api/analytics';

const analyticsProfileViewUrl = (uuid: string) => `${analyticsUrl}/profile-view/${uuid}`;
const analyticsContactSaveUrl = (uuid: string) => `${analyticsUrl}/contact-save/${uuid}`;
const businessAnalyticsUrl = (companyId: number) => `${analyticsUrl}/company/${companyId}`;

export const addProfileViewTrack = async (uuid: string) => {
    const config = {
        ...(await configDefault())
    };

    const data: AnalyticsDataTrack = {device : getUserDevice()};

    return await postApiCall(analyticsProfileViewUrl(uuid), data, config);
};

export const addContactSaveTrack = async (uuid: string) => {
    const config = {
        ...(await configDefault())
    };

    const data: AnalyticsDataTrack = {device : getUserDevice()};

    return await postApiCall(analyticsContactSaveUrl(uuid), data, config);
};

export const getAnalyticsByIds = async (filter: AnalyticsFilter) => {

    const config = {
        ...(await configDefaultWithToken()),
        params: {
            lastDays: filter.lastDays,
            ids: filter.cardProfileId
        }
    };

    return await getApiCall(analyticsUrl, config);
};

export const getCompanyAnalyticsById = async (companyId: number, filter: AnalyticsFilter) => {
    const config = {
        ...(await configDefaultWithToken()),
        params: {
            lastDays: filter.lastDays,
            cardProfileIds: filter.cardProfileId,
            searchFor: filter.searchFor
        }
    };

    return await getApiCall(businessAnalyticsUrl(companyId), config);
};
