export const getAssignSelectorStyle = () => ({
    width: '100% !important',
    backgroundColor: '#FFFFFF',

    '& .MuiInputBase-root': {
        padding: '2px 12px 5px 12px',
        height: '48px',
        fontFamily: 'Lato, sans-serif',
        fontSize: '16px',
        fontWeight: 500,
        lineHeight: '19.2px',
        color: '#1F2937',
        boxSizing: 'border-box',
    },
    '& .MuiInputBase-input': {
        width: 'auto',
        flexGrow: 1,
        flexShrink: 1,
        minWidth: 0,
    }
});