import { FC, ReactNode, createContext, useContext } from 'react';
import {SelectAllObject} from '../pages/cards/CardsListView';

interface CardListContextType {
    selectAll: { selected: boolean, unselected: number[] };
    addSelected: (selected: number) => void;
    removeSelected: (selected: number) => void;
}

interface CardListContextProviderProps {
    children: ReactNode;
    selectAll: SelectAllObject;
    addSelected: (selected: number) => void;
    removeSelected: (selected: number) => void;
}

const CardListContext = createContext<CardListContextType>(null!);

export const CardListProvider: FC<CardListContextProviderProps> = ({ children , selectAll, addSelected, removeSelected }) => {
    return (
        <CardListContext.Provider value={{ selectAll, addSelected, removeSelected }}>
            {children}
        </CardListContext.Provider>
    );
};

export const useCardListContext = () => {
    return useContext(CardListContext);
};
