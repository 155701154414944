import { FC, ReactNode, createContext, useContext, useState } from 'react';

interface ThemeContextType {
    themeColor: string;
    setThemeColor: React.Dispatch<React.SetStateAction<string>>;
}

interface ThemeProviderProps {
    children: ReactNode;
}

const ThemeContext = createContext<ThemeContextType >(null!);

export const ThemeProvider: FC<ThemeProviderProps> = ({ children }) => {
    const [themeColor, setThemeColor] = useState<string>('#242222');

    return (
        <ThemeContext.Provider value={{ themeColor, setThemeColor }}>
            {children}
        </ThemeContext.Provider>
    );
};

export const useThemeContext = () => {
    return useContext(ThemeContext);
};