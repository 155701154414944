import React, {FC, ReactElement} from 'react';
import styles from './settings.module.scss';

interface SettingsCardProps {
    label: string;
    text?: string;
    actionElement?: ReactElement;
    onClick?: () => void;
}

const SettingsCard: FC<SettingsCardProps> = ({
    label, text, actionElement, onClick
}) => {

    const classNameContainer = onClick ? `${styles.cardContainer} ${styles.pointer}` : `${styles.cardContainer}`;

    return (
        <div className={classNameContainer} onClick={onClick}>
            <div className={styles.cardTextWrapper}>
                <div className={styles.cardLabel}>
                    {label}
                </div>
                <div className={styles.cardText}>
                    {text}
                </div>
            </div>
            <div className={styles.cardElement}>
                {actionElement}
            </div>
        </div>
    );
};

export default SettingsCard;