import {configDefaultWithToken, getApiCall, postApiCall} from './base';

const notificationUrl = '/api/notification';

const userNotificationUrl = (userId: number) => `${notificationUrl}/${userId}/user`;
const countUnreadUserNotificationUrl = (userId: number) => `${notificationUrl}/${userId}/user/unread-count`;
const userNotificationReadByIdUrl = (userId: number, notificationId: number) => `${userNotificationUrl(userId)}/${notificationId}/read`;
const userNotificationReadAllByIdUrl = (userId: number) => `${userNotificationUrl(userId)}/read-all`;

export const getNotifications = async (userId: number, noof?: number) => {
    const config = {
        ...(await configDefaultWithToken()),
        params: {
            noof: noof
        }
    };

    return await getApiCall(userNotificationUrl(userId), config);
};

export const getUnreadNotificationsCounter = async (userId: number) => {
    const config = {
        ...(await configDefaultWithToken()),
    };

    return await getApiCall(countUnreadUserNotificationUrl(userId), config);
};

export const markNotificationAsRead = async (userId: number, notificationId: number) => {
    const config = {
        ...(await configDefaultWithToken()),
    };

    return await postApiCall(userNotificationReadByIdUrl(userId, notificationId), {}, config);
};

export const markAllNotificationAsRead = async (userId: number) => {
    const config = {
        ...(await configDefaultWithToken()),
    };

    return await postApiCall(userNotificationReadAllByIdUrl(userId), {}, config);
};