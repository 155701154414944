import {FC, useEffect, useState} from 'react';
import ProfileHeader from '../../components/layout/ProfileHeader/ProfileHeader';
import General from '../../components/profile/general/General';
import Preview from '../../components/profile/preview/Preview';
import Links from '../../components/profile/links/Links';
import styles from './profile.module.scss';
import Contact from '../../components/profile/contact/Contact';
import About from '../../components/profile/About/About';
import Appearance from '../../components/profile/appearance/Appearance';
import { ThemeProvider } from '../../context/theme/ThemeProvider';
import useCardProfileController from './useCardProfileController';
import { useParams } from 'react-router-dom';
import { ToggleProvider } from '../../context/toggle/ToggleProvider';
import { FormProvider } from '../../context/profile/FormProvider';
import { usePrompt } from '../../hooks/usePrompt';
import { useCardProfileDataContext } from '../../context/profile/CardProfileDataProvider';
import useSaveController from '../../context/profile/useSaveController';
import { CardProfileConverter } from '../../converters/api/CardProfileConverter';
import LostCard from '../../components/common/lostCard/LostCard';
import StickyFooter from '../../components/layout/stickyFooter/StickyFooter';
import CardAssign from '../../components/profile/cardAssign/CardAssign';
import {IS_BUSINESS_ACCOUNT, IS_COMPANY_ADMIN} from '../../store/utils/userSession';
import {User} from '../../types/user';
import {useAppSelector} from '../../store/appDispatch';
import {selectUser} from '../../store/slices/userSessionSlice';
import {saveToggleSection} from '../../api/cardProfile';

const Profile: FC = () => {
    const { id } = useParams();
    if (!id) return <></>;

    const [removeProfilePhoto, setRemoveProfilePhoto] = useState<boolean>(false);
    const [removeCoverPhoto, setRemoveCoverPhoto] = useState<boolean>(false);

    const user: User = useAppSelector(selectUser);

    const { loading, profileBasic, profileSocialLinks, profileContacts,
        profileAddress, profileAbout, profileStyle,
        coverPhoto, profilePhoto, cardProfile, toggle,
        setProfilePhoto, setCoverPhoto, assignCardProfileApi, loadingAssign
    } = useCardProfileController(parseInt(id));

    const { saveProfileInfo, cardProfileLoading } = useSaveController([parseInt(id)]);

    const {
        newProfilePhoto, newCoverPhoto, isDirty, setIsDirty,
        aboutData, setAboutData, basic, setBasicData,
        links, setLinks, contactInfos, setContact,
        addressInfos, setAddress, selectedColors, setSelectedColors,
        setNewProfilePhoto, setNewCoverPhoto, isCustom, setIsCustom
    } = useCardProfileDataContext();

    const [isLargeScreen, setIsLargeScreen] = useState(false);
    const [isSticky, setSticky] = useState(false);

    usePrompt({
        isDirty,
    });

    useEffect(() => {
        setAboutData(profileAbout);
        setBasicData(profileBasic);
        setLinks(profileSocialLinks);
        setContact(profileContacts);
        setAddress(profileAddress);
        setSelectedColors(profileStyle);
    }, [profileBasic, profileStyle]);

    const fullname = profileBasic?.fullname;

    const handleSaveInfos = () => {
        const convertedLinks = CardProfileConverter.convertToSocialLinksUpdate(links);
        const convertedContact = CardProfileConverter.convertToContactUpdate(contactInfos);
        const covnertedAddress = CardProfileConverter.convertToAddressUpdate(addressInfos);
        const convertedContactAddress = CardProfileConverter.convertToContactSectionData(convertedContact, covnertedAddress);

        saveProfileInfo(
            profilePhoto,
            coverPhoto,
            removeProfilePhoto,
            removeCoverPhoto,
            aboutData,
            CardProfileConverter.convertToBasic(basic),
            newProfilePhoto as File,
            newCoverPhoto as File,
            convertedLinks,
            convertedContactAddress,
            CardProfileConverter.convertToAppearanceUpdate(selectedColors)
        );

        setProfilePhoto( newProfilePhoto ? URL.createObjectURL(newProfilePhoto as Blob) : profilePhoto );
        setCoverPhoto( newCoverPhoto ? URL.createObjectURL(newCoverPhoto as Blob) : coverPhoto );

        setIsDirty(false);
    };

    const canSeeCardAssignOption = () => {
        if (!cardProfile) return false;

        return cardProfile.companyCard ? IS_COMPANY_ADMIN() : (IS_BUSINESS_ACCOUNT() && cardProfile.cardOwnerId === user.id);
    };

    const handleRemoveCover = (e: React.MouseEvent) => {
        e.preventDefault();
        setIsDirty(true);
        setRemoveCoverPhoto(true);
        setCoverPhoto('');
        setNewCoverPhoto(undefined);
    };

    const handleRemoveProfile = () => {
        setIsDirty(true);
        setRemoveProfilePhoto(true);
        setProfilePhoto('');
        setNewProfilePhoto(undefined);
    };

    const handleSaveToggle = (data: any) => saveToggleSection([Number(id)], data);

    useEffect(() => {
        const handleResize = () => {
            setIsLargeScreen(window.innerWidth <= 992);
        };
        handleResize();
        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const checkScrollTop = () => {
        setSticky(window.scrollY >= 70);
    };

    useEffect(() => {
        window.addEventListener('scroll', checkScrollTop);
        return () => {
            window.removeEventListener('scroll', checkScrollTop);
        };
    }, []);

    if (loading) return <></>;

    return (
        <ThemeProvider>
            <ProfileHeader
                uuid={cardProfile?.uuid as string}
                status={cardProfile?.status}
                cardProfileId={parseInt(id)}
                fullName={fullname}
                loading={cardProfileLoading}
                saveInfos={handleSaveInfos}
                isDirty={isDirty}
                setIsDirty={setIsDirty}
                isProfile
                profilePhoto={profilePhoto}
                isSticky={isSticky}
            />
            <FormProvider>
                <ToggleProvider toggle={toggle}>
                    <div className={`${styles.container} ${isSticky ? styles.sticky : ''}`}>
                        {!isLargeScreen &&
                            <div className={styles.leftContainer}>
                                <Preview
                                    profileBasic={basic}
                                    profileSocialLinks={links}
                                    profileContacts={contactInfos}
                                    profileAddress={addressInfos}
                                    profileAbout={aboutData}
                                    profileStyle={selectedColors}
                                    coverPhoto={coverPhoto}
                                    profilePhoto={profilePhoto}
                                    loading={loading}
                                    cardProfile={cardProfile ?? undefined}
                                />
                            </div>
                        }
                        <div className={styles.rightContainer}>
                            {cardProfile && canSeeCardAssignOption() &&
                                <CardAssign
                                    cardProfile={cardProfile}
                                    assignCardProfileApi={assignCardProfileApi}
                                    loading={loadingAssign}
                                />
                            }
                            <General
                                profileBasic={basic}
                                profilePhoto={profilePhoto}
                                coverPhoto={coverPhoto}
                                showGeneralHeader
                                showIcon
                                setNewProfilePhoto={setNewProfilePhoto}
                                setNewCoverPhoto={setNewCoverPhoto}
                                setIsDirty={setIsDirty}
                                setBasicData={setBasicData} 
                                handleRemoveProfile={handleRemoveProfile}
                                handleDeleteCoverImage={handleRemoveCover}
                                newProfilePhoto={newProfilePhoto}
                                newCoverPhoto={newCoverPhoto}
                            />
                            <Links
                                saveToggleData={handleSaveToggle}
                                socialLinks={links}
                                setProfileSocialLinks={setLinks}
                                showIcon
                            />
                            <Contact
                                saveToggleData={handleSaveToggle}
                                contacts={contactInfos}
                                address={addressInfos}
                                showIcon
                            />
                            <About
                                saveToggleData={handleSaveToggle}
                                showIcon
                            />
                            <Appearance
                                showColor
                                showIcon
                                setIsDirty={setIsDirty}
                                profileStyle={profileStyle}
                                setProfileStyle={setSelectedColors}
                                setSelectedColors={setSelectedColors}
                                selectedColors={selectedColors}
                                isCustom={isCustom}
                                setIsCustom={setIsCustom}
                            />

                            {isLargeScreen &&
                                <LostCard cardProfile={cardProfile ?? undefined} />
                            }
                        </div>
                    </div>
                </ToggleProvider>
                <StickyFooter
                    isDirty={isDirty}
                    loading={cardProfileLoading}
                    saveInfos={handleSaveInfos}
                />
            </FormProvider>
        </ThemeProvider>
    );
};

export default Profile;