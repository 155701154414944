import React, {FC, useEffect, useMemo, useState} from 'react';
import HomeHeader from '../../components/layout/HomeHeader/HomeHeader';
import i18n from '../../translations/i18n';
import styles from './settings.module.scss';
import {NavigationTabs, Tab} from '../../components/common/navigationTabs/NavigationTabs';
import YourAccount from '../../components/settings/yourAccount/YourAccount';
import { FormProvider } from '../../context/profile/FormProvider';
import DataAndPrivacy from '../../components/settings/dataAndPrivacy/DataAndPrivacy';
import {Selector} from '../../components/common/selector/Selector';
import SupportTab from '../../components/settings/supportTab/SupportTab';
import CompanyMembers from '../../components/settings/companyMembers/CompanyMembers';
import {IS_COMPANY_ADMIN} from '../../store/utils/userSession';
import {useAppSelector} from '../../store/appDispatch';
import {selectUser} from '../../store/slices/userSessionSlice';

const YOUR_ACCOUNT = 'yourAccount';
const DATA_AND_PRIVACY = 'dataAndPrivacy';
const SUPPORT = 'supportTab';
const COMPANY_MEMBERS = 'companyMembers';

type TabID = typeof YOUR_ACCOUNT | typeof DATA_AND_PRIVACY | typeof SUPPORT | typeof COMPANY_MEMBERS;

const Settings: FC = () => {
    const [activeTab, setActiveTab] = useState<TabID>(YOUR_ACCOUNT);
    const [isLargeSizeScreen, setIsLargeSizeScreen] = useState(false);
    const user = useAppSelector(selectUser);

    const yourAccountLabel = i18n.t('common.yourAccount');
    const dataAndPrivacyLabel = i18n.t('common.dataAndPrivacy');
    const companyMembersLabel = i18n.t('common.companyMembers');
    const supportTabLabel = i18n.t('common.support');

    const tabs = useMemo(() => {
        const tabList: Tab[] = [
            {key: 1, value: yourAccountLabel, handleAction: () => setActiveTab(YOUR_ACCOUNT)},
            {key: 3, value: dataAndPrivacyLabel, handleAction: () => setActiveTab(DATA_AND_PRIVACY)},
            {key: 4, value: supportTabLabel, handleAction: () => setActiveTab(SUPPORT)}
        ];

        const companyMemberTab: Tab = {key: 2, value: companyMembersLabel, handleAction: () => setActiveTab(COMPANY_MEMBERS)};
        IS_COMPANY_ADMIN() && tabList.splice(1, 0, companyMemberTab);

        return tabList;
    }, [user.activeCompany]);

    const settingItems = useMemo(() => {
        const items: { value: string, name: string }[] = [
            {value: yourAccountLabel, name: yourAccountLabel},
            {value: dataAndPrivacyLabel, name: dataAndPrivacyLabel},
            {value: supportTabLabel, name: supportTabLabel}
        ];

        const companyMemberItem = {value: companyMembersLabel, name: companyMembersLabel};
        IS_COMPANY_ADMIN() && items.splice(2, 0, companyMemberItem);

        return items;
    }, [user.activeCompany]);

    const onSelect = (item: string) => {
        if (item == yourAccountLabel) setActiveTab(YOUR_ACCOUNT);
        else if (item == companyMembersLabel) setActiveTab(COMPANY_MEMBERS);
        else if (item == dataAndPrivacyLabel) setActiveTab(DATA_AND_PRIVACY);
        else if (item === supportTabLabel) setActiveTab(SUPPORT);
        else setActiveTab(YOUR_ACCOUNT);
    };

    const checkScreenSize = () => {
        setIsLargeSizeScreen(window.innerWidth < 992);
    };

    useEffect(() => {
        window.addEventListener('resize', checkScreenSize);
        checkScreenSize();
        return () => {
            window.removeEventListener('resize', checkScreenSize);
        };
    }, []);

    useEffect(() => {
        if (activeTab === COMPANY_MEMBERS && !IS_COMPANY_ADMIN()) {
            setActiveTab(YOUR_ACCOUNT);
        }
    }, [user.activeCompany]);

    return (
        <FormProvider>
            <HomeHeader/>
            <div className={styles.container}>
                <div className={`${styles.title} ${styles.text}`}>
                    {i18n.t('header.accountSettings')}
                </div>
                <div className={styles.screen}>
                    <div className={styles.leftScreen}>
                        {isLargeSizeScreen
                            ? <Selector
                                items={settingItems}
                                defaultValue={settingItems[0].name}
                                onSelect={onSelect}
                                formStyle={styles.selector}
                            />
                            : <NavigationTabs
                                tabs={tabs}
                            />
                        }
                    </div>
                    <div className={styles.rightScreen}>
                        {activeTab === YOUR_ACCOUNT && <YourAccount/>}
                        {activeTab === DATA_AND_PRIVACY && <DataAndPrivacy/>}
                        {activeTab === SUPPORT && <SupportTab/>}
                        {activeTab === COMPANY_MEMBERS && <CompanyMembers/>}
                    </div>
                </div>
            </div>
        </FormProvider>
    );
};

export default Settings;