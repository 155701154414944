import {Analytics, AnalyticsCountriesData, AnalyticsData, AnalyticsTimestampData} from '../../types/analytics';
import {isEmpty} from '../../utils/common';

export const AnalyticsConverter = {
    convertFromAnalyticsApiToAnalytics(data: any, initial: Analytics): Analytics {
        if (!data) return initial;

        const cardViews = data.cardViews;

        return {
            cardViews: AnalyticsConverter.convertFromAnalyticsApiToAnalyticsData(cardViews),
            contactSaves: AnalyticsConverter.convertFromAnalyticsApiToAnalyticsData(data.contactSaves),
            uniqueUsers: AnalyticsConverter.convertFromAnalyticsApiToAnalyticsData(data.uniqueUsers),
            daysSinceProfileIsCreated: cardViews && cardViews.length > 0 && data.cardViews[0].daysSinceProfileIsCreated
        };
    },
    convertFromAnalyticsApiToAnalyticsData(data: any[]): AnalyticsData[] {
        if (isEmpty(data)) return [];

        const analyticsData: AnalyticsData[] = [];

        data.forEach(d => {
            if (d) {
                analyticsData.push({
                    cardProfileId: d.cardProfileId,
                    count: d.count,
                    increase: d.increase,
                    timestampData: AnalyticsConverter.convertFromAnalyticsApiToAnalyticsTimestampData(d.timestampData),
                    countriesData: AnalyticsConverter.convertFromAnalyticsApiToAnalyticsCountriesData(d.countriesData),
                });
            }
        });

        return analyticsData;
    },
    convertFromAnalyticsApiToAnalyticsTimestampData(data: any[]): AnalyticsTimestampData[] {
        if (isEmpty(data)) return [];

        const analyticsData: AnalyticsTimestampData[] = [];

        data.forEach(d => {
            if (d) {
                analyticsData.push({
                    count: d.count,
                    date: d.date
                });
            }
        });

        return analyticsData;
    },
    convertFromAnalyticsApiToAnalyticsCountriesData(data: any[]): AnalyticsCountriesData[] {
        if (isEmpty(data)) return [];

        const analyticsData: AnalyticsCountriesData[] = [];

        data.forEach(d => {
            if (d) {
                analyticsData.push({
                    count: d.count,
                    country: d.country
                });
            }
        });

        return analyticsData;
    }
};