import {FC, useEffect, useMemo, useState} from 'react';
import styles from './home-header.module.scss';
import {  NavLink } from 'react-router-dom';
import i18n from '../../../translations/i18n';
import { routes } from '../../../constants/routes';
import {IS_BUSINESS_ACCOUNT} from '../../../store/utils/userSession';
import {useAppSelector} from '../../../store/appDispatch';
import {selectUser} from '../../../store/slices/userSessionSlice';

const HomeHeader: FC = () => {
    const user = useAppSelector(selectUser);
    const isBusiness = useMemo(() => IS_BUSINESS_ACCOUNT(), [user.activeCompany]);
    const [isSmallScreen, setIsSmallScreen] = useState(false);

    useEffect(() => {
        const handleResize = () => {
            setIsSmallScreen(window.innerWidth <= 576);
        };
        handleResize();
        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
        <>
            <nav className={styles.navContainer}>
                <div className={styles.navLinks}>
                    <NavLink to={routes.analytics} className={({isActive})=> isActive ? styles.activeLink : styles.link}>
                        {i18n.t('header.analytics')}
                    </NavLink>
                    <NavLink to={routes.myCards} className={({isActive})=> isActive ? styles.activeLink : styles.link}>
                        {i18n.t('header.myCards')}
                    </NavLink>
                    {isBusiness &&
                        <NavLink to={routes.companyCards} className={({isActive}) => isActive ? styles.activeLink : styles.link}>
                            {i18n.t('header.companyCards')}
                        </NavLink>
                    }
                    {!isSmallScreen &&
                        <NavLink to={routes.settings} className={({isActive}) => isActive ? styles.activeLink : styles.link}>
                            {i18n.t('header.settings')}
                        </NavLink>
                    }
                </div>
            </nav>
        </>
    );
};

export default HomeHeader;