import { FC } from 'react';
import styles from './app-footer.module.scss';
import i18n from '../../../translations/i18n';

interface AppFooterProps {
    hasStickyFooter?: boolean
}

const privacyPolicyURL = process.env.REACT_APP_PRIVACY_POLICY_URL;

const AppFooter: FC<AppFooterProps> = ({
    hasStickyFooter
}) => {

    const classNames = hasStickyFooter ? `${styles.navContainer} ${styles.stickyFooter}` : styles.navContainer;

    return (
        <div className={classNames}>
            <div>© iCards AG 2024 </div>
            <div className={styles.privacyPolicy}>
                <span className={styles.firstDot}>•</span>
                <a href={privacyPolicyURL} className={styles.link} target="_blank" rel="noopener noreferrer">
                    {i18n.t('settings.termsAndConditions')}
                </a>
                <span>•</span>
                <a href={privacyPolicyURL} className={styles.link} target="_blank" rel="noopener noreferrer">
                    {i18n.t('settings.privacyPolicy')}
                </a>
            </div>
        </div>
    );
};

export default AppFooter;