import {useCallback, useEffect, useState} from 'react';
import {Analytics, AnalyticsFilter} from '../../types/analytics';
import {useAppSelector} from '../../store/appDispatch';
import {selectUser} from '../../store/slices/userSessionSlice';
import {AnalyticsConverter} from '../../converters/api/AnalyticsConverter';
import {CardProfileFullname} from '../../types/cardProfile';
import {CardProfileConverter} from '../../converters/api/CardProfileConverter';
import {getAnalyticsControllerApi, getCardProfilesControllerApi} from '../../api/apiController';

const initialAnalyticsFilter: AnalyticsFilter = {
    lastDays: '7',
    cardProfileId: undefined
};

const initialAnalytics: Analytics = {
    cardViews: [],
    contactSaves: [],
    uniqueUsers: [],
    daysSinceProfileIsCreated: 7
};

const useAnalyticsController = () => {
    const [loading, setLoading] = useState<boolean>(false);
    const [apiFilter, setApiFilter] = useState<AnalyticsFilter>(initialAnalyticsFilter);
    const [analyticsData, setAnalyticsData] = useState<Analytics>(initialAnalytics);
    const [profiles, setProfiles] = useState<CardProfileFullname[]>([]);

    const user = useAppSelector(selectUser);

    const getAnalyticsApi = useCallback(() => {
        setLoading(true);
        getAnalyticsControllerApi(apiFilter)
            .then(res => {
                if (res?.error) {
                    return;
                }

                setAnalyticsData(
                    AnalyticsConverter.convertFromAnalyticsApiToAnalytics(res?.data, initialAnalytics)
                );
            })
            .finally(() => setLoading(false));
    }, [apiFilter, user.activeCompany]);

    const getCardProfilesApi = useCallback(() => {
        getCardProfilesControllerApi(user.id)
            .then(res => {
                if (res.error) {
                    return;
                }

                setProfiles(CardProfileConverter.convertFromCardProfilesApiToCardProfileFullname(res.data));
            });
    }, [user, user.activeCompany]);

    useEffect(() => {
        if (!user || loading) return;
        getAnalyticsApi();
    }, [user, apiFilter, user.activeCompany]);

    useEffect(() => {
        if (!user) return;
        getCardProfilesApi();
    }, [user, user.activeCompany]);

    return {
        loading: loading || !user,
        analyticsData,
        setApiFilter,
        apiFilter,
        profiles
    };
};

export default useAnalyticsController;