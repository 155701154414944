import { CardProfileSocialLinksUpdate } from '../../types/cardProfile';
import { FACEBOOK, INSTAGRAM, LINKEDIN, PINTEREST, REDDIT, SNAPCHAT, THREADS, TIKTOK, X , YOUTUBE} from '../../types/socialLinks';

export const options = [FACEBOOK, LINKEDIN, INSTAGRAM, TIKTOK, X, YOUTUBE, SNAPCHAT, THREADS, PINTEREST, REDDIT];

export const getMenuItemStyles = (option: string, links:CardProfileSocialLinksUpdate[]) => {
    const isSelected = links.some(link => link.type === option);
    const cursor = isSelected ? 'default' : '';
    
    return {
        fontWeight: isSelected ? 'bold' : 'normal',
        cursor: cursor
    };
};