import {Analytics, AnalyticsTimestampData, CardProfileAnalytics} from '../../types/analytics';
import dayjs from 'dayjs';
import i18n from '../../translations/i18n';
import {COMPANY_CARDS_ANALYTICS_FILTER, MY_CARDS_ANALYTICS_FILTER} from '../../constants/common';

export const getCardProfileAnalytics = (analyticsData: Analytics): CardProfileAnalytics => {
    const cardViewsData = analyticsData.cardViews;
    const contactSavesData = analyticsData.contactSaves;
    const uniqueUsersData = analyticsData.uniqueUsers;

    return {
        cardViews: cardViewsData && cardViewsData.length > 0 ? cardViewsData[0] : undefined,
        contactSaves: contactSavesData && contactSavesData.length > 0 ? contactSavesData[0] : undefined,
        uniqueUsers: uniqueUsersData && uniqueUsersData.length > 0 ? uniqueUsersData[0] : undefined
    };
};

const times = [
    '12 AM', '1 AM', '2 AM', '3 AM', '4 AM', '5 AM', '6 AM', '7 AM',
    '8 AM', '9 AM', '10 AM', '11 AM', '12 PM', '1 PM', '2 PM', '3 PM',
    '4 PM', '5 PM', '6 PM', '7 PM', '8 PM', '9 PM', '10 PM', '11 PM'
];

const getTodayTimeList = () => {
    const today = new Date();
    const todayYear = today.getFullYear();
    const todayMonth = today.getMonth();
    const todayDate = today.getDate();

    const dates = [];
    for (let hour = 0; hour <= 23; hour++) {
        const date = new Date(todayYear, todayMonth, todayDate, hour, 0, 0, 0);
        dates.push(date);
    }

    return dates;
};

export const getChartXAxis = (noofDays: number): Date[] => {
    if (noofDays === 1) return getTodayTimeList();

    const today = new Date();
    const xAxisDates = [];

    for (let i = noofDays - 1; i >= 0; i--) {
        const date = new Date(today);
        date.setDate(today.getDate() - i);
        xAxisDates.push(date);
    }

    return xAxisDates;
};
export const getChartXAxisFormatted = (noofDays: number) => {
    const today = dayjs();
    const xAxisDates = [];

    for (let i = 0; i < noofDays; i++) {
        const date = today.subtract(i, 'day').format('YYYY-MM-DD');
        xAxisDates.push(date);
    }

    return xAxisDates;
};


export const getChartData = (data: AnalyticsTimestampData[], noofDays: number): number[] => {
    const isToday = noofDays === 1;
    const dates = isToday ? times : getChartXAxisFormatted(noofDays);

    const chartData = dates.map(date => {
        const dataFound = data.find(d => d.date.toString() === date);
        return dataFound?.count ?? 0;
    });

    return isToday ? chartData : chartData.reverse();
};

export const LAST_DAYS_SELECTOR_ITEMS: string[] = [
    i18n.t('common.today'),
    i18n.t('selector.last7days'),
    i18n.t('selector.last14days'),
    i18n.t('selector.last30days'),
    i18n.t('selector.totalViews'),
];

export const getLastDaysValue = (lastDayItem: string) => {
    if (lastDayItem === LAST_DAYS_SELECTOR_ITEMS[0]) return '1';
    if (lastDayItem === LAST_DAYS_SELECTOR_ITEMS[1]) return '7';
    if (lastDayItem === LAST_DAYS_SELECTOR_ITEMS[2]) return '14';
    if (lastDayItem === LAST_DAYS_SELECTOR_ITEMS[3]) return '30';
    return undefined;
};

export const convertLastDaysToNoofDays = (lastDays: string | undefined, daysSinceProfileIsCreated: number) => {
    return lastDays ? parseInt(lastDays) : daysSinceProfileIsCreated;
};

export const getAnalyticsSearchForFilter = (id: number) => {
    if (id === -1) return MY_CARDS_ANALYTICS_FILTER;
    if (id === -2) return COMPANY_CARDS_ANALYTICS_FILTER;
    return undefined;
};