import React, {FC, useEffect, useMemo, useState} from 'react';
import Appearance from '../../components/profile/appearance/Appearance';
import General from '../../components/profile/general/General';
import styles from '../walletCard/wallet-card.module.scss';
import { FormProvider } from '../../context/profile/FormProvider';
import { ThemeProvider } from '../../context/theme/ThemeProvider';
import { useWalletCardDataContext } from '../../context/walletCard/WalletCardDataProvider';
import { usePrompt } from '../../hooks/usePrompt';
import { CardProfileConverter } from '../../converters/api/CardProfileConverter';
import StickyFooter from '../../components/layout/stickyFooter/StickyFooter';
import IOSPreview from '../../components/walletCard/preview/IOSPreview';
import {isAppleDevice} from '../../utils/device';
import AndroidPreview from '../../components/walletCard/preview/AndroidPreview';
import useWalletCardBulkController from './useWalletCardBulkController';
import BulkProfileHeader from '../../components/layout/ProfileHeader/BulkProfileHeader';
import i18n from '../../translations/i18n';
import profileStyles from '../profile/profile.module.scss';
import {getBulkUrlDecodedToken} from '../../utils/jwt';

const EditWalletProfiles: FC = () => {
    const searchParams = new URLSearchParams(location.search);
    const token = searchParams.get('token');
    const selectAll = searchParams.get('selectAll') === 'true';
    const isMyCards = searchParams.get('isMyCards') === 'true';
    const noofCards = searchParams.get('len') ? Number(searchParams.get('len')) : 0;
    const cardProfileIds: number[] = getBulkUrlDecodedToken(token);

    const [profilePhoto, setProfilePhoto] = useState<string>('');
    const [coverPhoto, setCoverPhoto] = useState<string>('');
    const [isSticky, setSticky] = useState(false);

    const {
        walletBasic, newWalletCoverPhoto, newWalletProfilePhoto, setWalletBasic,
        walletStyle, setWalletStyle, setNewWalletProfilePhoto, setNewWalletCoverPhoto,
        isWalletDirty, setIsWalletDirty, isCustom, setIsCustom
    } = useWalletCardDataContext();

    const { saveWalletCardInfo, walletLoading} = useWalletCardBulkController(cardProfileIds, selectAll, isMyCards, noofCards);

    const cropShape = useMemo(() => isAppleDevice() ? 'rect' : 'round', []);

    usePrompt({ isDirty: isWalletDirty });

    const handleSaveInfos = () => {
        saveWalletCardInfo(
            CardProfileConverter.convertToBasic(walletBasic),
            walletStyle,
            newWalletProfilePhoto,
            newWalletCoverPhoto
        );
        setProfilePhoto(newWalletProfilePhoto ? URL.createObjectURL(newWalletProfilePhoto as Blob) : profilePhoto);
        setCoverPhoto(newWalletCoverPhoto ? URL.createObjectURL(newWalletCoverPhoto as Blob) : coverPhoto);
        setIsWalletDirty(false);
    };

    const handleRemoveProfile = () => {
        setIsWalletDirty(true);
        setProfilePhoto('');
        setNewWalletProfilePhoto(undefined);
    };

    const handleRemoveCover = (e: React.MouseEvent) => {
        e.preventDefault();
        setIsWalletDirty(true);
        setCoverPhoto('');
        setNewWalletCoverPhoto(undefined);
    };

    const checkScrollTop = () => {
        setSticky(window.scrollY >= 70);
    };

    useEffect(() => {
        window.addEventListener('scroll', checkScrollTop);
        return () => {
            window.removeEventListener('scroll', checkScrollTop);
        };
    }, []);

    return (
        <ThemeProvider>
            <BulkProfileHeader
                isDirty={isWalletDirty}
                saveInfos={handleSaveInfos}
                loading={walletLoading}
                selectAll={selectAll}
                isMyCards={isMyCards}
                noofCards={noofCards}
                isSticky={isSticky}
                idsToken={token ?? ''}
            />

            <FormProvider>
                <div className={profileStyles.editingHeader}>
                    {i18n.t('header.youAreEditing', {noofCards: noofCards})}
                </div>
                <div className={`${styles.container} ${isSticky ? styles.sticky : ''}`}>
                    <div className={styles.leftContainer}>
                        {isAppleDevice()
                            ? <IOSPreview
                                walletBasic={walletBasic}
                                coverPhoto={coverPhoto}
                                profilePhoto={profilePhoto}
                                walletStyle={walletStyle}
                                uuid={'demo'}
                            />
                            : <AndroidPreview
                                walletBasic={walletBasic}
                                coverPhoto={coverPhoto}
                                profilePhoto={profilePhoto}
                                walletStyle={walletStyle}
                                uuid={'demo'}
                            />
                        }
                    </div>
                    <div className={styles.rightContainer}>
                        <General
                            profileBasic={walletBasic}
                            coverPhoto={coverPhoto}
                            profilePhoto={profilePhoto}
                            setNewProfilePhoto={setNewWalletProfilePhoto}
                            setNewCoverPhoto={setNewWalletCoverPhoto}
                            setIsDirty={setIsWalletDirty}
                            setBasicData={setWalletBasic}
                            handleRemoveProfile={handleRemoveProfile}
                            handleDeleteCoverImage={handleRemoveCover}
                            newProfilePhoto={newWalletProfilePhoto}
                            newCoverPhoto={newWalletCoverPhoto}
                            isWalletCard
                            crop={cropShape}
                            bulkView={true}
                        />
                        <Appearance
                            setIsDirty={setIsWalletDirty}
                            profileStyle={walletStyle}
                            setProfileStyle={setWalletStyle}
                            setSelectedColors={setWalletStyle}
                            selectedColors={walletStyle}
                            isCustom={isCustom}
                            setIsCustom={setIsCustom}
                            isWallet={true}
                        />
                    </div>
                </div>
                <StickyFooter
                    isDirty={isWalletDirty}
                    loading={walletLoading}
                    saveInfos={handleSaveInfos}
                />
            </FormProvider>
        </ThemeProvider>
    );
};

export default EditWalletProfiles;