import React, {FC} from 'react';
import styles from './my-cards.module.scss';
import CardBoxView from '../../components/cards/cardBoxView/CardBoxView';
import {UserCardProfile} from '../../types/userCardProfile';
import {GRID} from '../../constants/common';
import OrderCardBox from '../../components/cards/orderCardBox/OrderCardBox';

interface CardsGridViewProps {
    cardProfiles: UserCardProfile[],
    onEdit: (cardProfileId: number) => void,
    onDelete: (cardProfileId: number) => void,
    onPreview: (uuid: string) => void,
    removeFromCompanyCards: (uuid: string) => Promise<void>,
    moveToCompanyCards: (cardProfileId: number) => Promise<void>,
    searchText: string,
    isMyCards?: boolean,
    reloadCardsApi?: () => void
}

const CardsGridView: FC<CardsGridViewProps> = ({
    cardProfiles, onEdit, onDelete, onPreview, removeFromCompanyCards,
    moveToCompanyCards, searchText, isMyCards, reloadCardsApi
}) => {

    return (
        <div className={styles.cardBox}>
            <OrderCardBox/>
            {cardProfiles.map(profile => (
                <CardBoxView
                    key={profile.cardProfileId}
                    profile={profile}
                    onEdit={onEdit}
                    onDelete={onDelete}
                    onPreview={onPreview}
                    searchText={searchText}
                    removeFromCompanyCards={removeFromCompanyCards}
                    moveToCompanyCards={moveToCompanyCards}
                    isMyCards={isMyCards}
                    reloadCardsApi={reloadCardsApi}
                    viewMode={GRID}
                />)
            )}
        </div>
    );
};

export default CardsGridView;