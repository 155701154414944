export const getAssignSelectorStyle = () => ({
    width: '100% !important',
    backgroundColor: '#FFFFFF',

    '& .MuiInputBase-root': {
        height: '48px'

    },
    '& .MuiInputBase-input': {
        width: 'auto',
        flexGrow: 1,
        flexShrink: 1,
        minWidth: 0,
        padding: '4px 12px 7px 12px !important',
        fontFamily: 'Lato, sans-serif',
        fontSize: '16px',
        fontWeight: 500,
        lineHeight: '19.2px',
        color: '#1F2937',
        boxSizing: 'border-box',
        borderRadius: '48px',
        backgroundColor: '#F0F0F0',
        height: '28px'
    }
});

