import React, { FC } from 'react';
import styles from './preview.module.scss';
import {
    CardProfile,
    CardProfileAbout,
    CardProfileAddress,
    CardProfileBasic,
    CardProfileContact,
    CardProfileSocialLink,
    CardProfileStyle,
} from '../../../types/cardProfile';
import CardProfileContent from '../../../pages/previewCardProfile/CardProfileContent';
import { saveContact } from '../../../pages/previewCardProfile/previewCardProfieUtils';
import LostCard from '../../common/lostCard/LostCard';
import {useToggleContext} from '../../../context/toggle/ToggleProvider';


interface PreviewProps {
    profileBasic: CardProfileBasic | undefined,
    profileSocialLinks: CardProfileSocialLink[],
    profileContacts: CardProfileContact[],
    profileAddress?: CardProfileAddress,
    profileAbout?: CardProfileAbout,
    profileStyle?: CardProfileStyle,
    coverPhoto: string,
    profilePhoto: string,
    loading: boolean,
    cardProfile?: CardProfile
}

const Preview: FC<PreviewProps> = ({
    profileBasic, profileSocialLinks, profileContacts, profileAddress, profileAbout,
    profileStyle, coverPhoto, profilePhoto, loading, cardProfile
}) => {
    const { profileToggle } = useToggleContext();

    const handleSaveContact = () => saveContact(
        profileContacts, profileSocialLinks, profileBasic, profileAddress, profileAbout, profilePhoto, profileToggle
    );

    if (loading) {
        return <></>;
    }

    return (
        <>
            <div className={styles.container} style={{backgroundColor:profileStyle?.cardBgColor}}>
                <div className={styles.imageWrapper}>
                    {coverPhoto
                        ? <div className={styles.coverPhoto}>
                            <img className={styles.cover} src={coverPhoto} alt="cover AndroidPreview" />
                        </div>
                        : <div className={styles.blankCover} style={{backgroundColor: profileStyle?.coverBgColor}}></div>
                    }
                    {profilePhoto ?
                        <div className={styles.profilePhotoWrapper}>
                            <img className={styles.profileImage} src={profilePhoto} alt="Avatar AndroidPreview" />
                        </div>
                        : <div className={styles.noProfilePhotoWrapper}></div>
                    }
                </div>
                <CardProfileContent
                    profileBasic={profileBasic}
                    profileContacts={profileContacts}
                    profileSocialLinks={profileSocialLinks}
                    profileAddresses={profileAddress}
                    profileAbout={profileAbout}
                    profileStyle={profileStyle}
                    onSave={handleSaveContact}
                    cardProfile={cardProfile}
                />
            </div>
            <LostCard cardProfile={cardProfile} />
        </>
    );
};

export default Preview;