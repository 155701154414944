export const PERSONAL = 'PERSONAL';
export const BUSINESS = 'BUSINESS';

export const COMPANY_ROLE_OWNER = 'OWNER';
export const COMPANY_ROLE_ADMIN = 'ADMIN';
export const COMPANY_ROLE_USER = 'USER';

export const COMPANY_ROLE_ITEMS = [
    COMPANY_ROLE_OWNER,
    COMPANY_ROLE_ADMIN,
    COMPANY_ROLE_USER
];
