import {FC, useMemo} from 'react';
import styles from './analytics-header.module.scss';
import i18n from '../../../translations/i18n';
import {Selector} from '../../common/selector/Selector';
import {LAST_DAYS_SELECTOR_ITEMS} from '../../../pages/analytics/analyticsUtil';
import {CardProfileFullname} from '../../../types/cardProfile';
import {getProfilesSelector} from './analyticsHeaderUtils';
import {SelectorWithSearch} from '../../common/selector/SelectorWithSearch';
import {ReactComponent as FilterIcon} from '../../../assets/icons/filter.svg';
import {IS_COMPANY_ADMIN} from '../../../store/utils/userSession';
import {useAppSelector} from '../../../store/appDispatch';
import {selectUser} from '../../../store/slices/userSessionSlice';


interface AnalyticsHeaderProps {
    onSelectLastDays: (lastDayItem: string) => void;
    onSelectCard: (value: {label: string, id: number}) => void;
    profiles: CardProfileFullname[]
}


const defaultValue = { label: i18n.t('common.allCards'), id: 0 };
const businessItems = [
    { label: i18n.t('common.allCards'), id: 0 },
    { label: i18n.t('common.myCards'), id: -1 },
    { label: i18n.t('common.companyCards'), id: -2 }
];

const AnalyticsHeader: FC<AnalyticsHeaderProps> = ({
    onSelectLastDays, onSelectCard, profiles
}) => {
    const user = useAppSelector(selectUser);

    const defaultItems = useMemo(() => IS_COMPANY_ADMIN() ? businessItems : [defaultValue] , [user]);
    const items = useMemo(() => [...defaultItems, ...getProfilesSelector(profiles)], [profiles, user]);
    const filterItems = useMemo(() => LAST_DAYS_SELECTOR_ITEMS.map(fi => ({value: fi, name: fi})), []);

    return (
        <div className={styles.container}>
            <div className={styles.text}>
                {i18n.t('header.analytics')}
            </div>
            <div className={styles.filter}>
                <SelectorWithSearch
                    label={i18n.t('labels.filterBy')}
                    items={items}
                    defaultValue={defaultValue}
                    onSelect={onSelectCard as any}
                    className={styles.selector}
                />
                <Selector
                    label={i18n.t('labels.filterBy')}
                    items={filterItems}
                    defaultValue={LAST_DAYS_SELECTOR_ITEMS[1]}
                    onSelect={onSelectLastDays as any}
                    icon={FilterIcon}
                    formStyle={styles.selector}
                />
            </div>
        </div>
    );
};

export default AnalyticsHeader;