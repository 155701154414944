import {FC, useState} from 'react';
import Cropper from 'react-easy-crop';
import style from './image-cropper.module.scss';
import {Slider} from '@mui/joy';
import Typography from '@mui/material/Typography';
import CustomButton from '../common/button/CustomButton';
import i18n from '../../translations/i18n';
import {getCroppedImg} from './cropImageUtils';

export type CropShape = 'rect' | 'round';

interface ImageCropperProps {
    image: string;
    onClose: () => void;
    onSaveImage: (img: File) => void;
    cropShape: CropShape;
    cropHeight: number;
    cropWidth: number;
}

const ImageCropper: FC<ImageCropperProps> = ({
    image, onClose, onSaveImage, cropShape, cropHeight, cropWidth
}) => {
    const [crop, setCrop] = useState({ x: 0, y: 0 });
    const [zoom, setZoom] = useState(1);
    const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
    const [rotation, setRotation] = useState(0);

    const onCropChange = (crop: any) => {
        setCrop(crop);
    };

    const onCropComplete = (croppedArea: any, croppedAreaPixels: any) => {
        setCroppedAreaPixels(croppedAreaPixels);
    };

    const onZoomChange = (zoom: any) => {
        setZoom(zoom);
    };

    const showCroppedImage = async () => {
        const croppedImage: File | null = await getCroppedImg(
            image,
            croppedAreaPixels,
            zoom,
            rotation
        );

        croppedImage && onSaveImage(croppedImage);
    };

    return (
        <div id='crop' className={style.app}>
            <div id='crop-container' className={style.cropContainer}>
                <Cropper
                    image={image}
                    crop={crop}
                    zoom={zoom}
                    aspect={1}
                    rotation={rotation}
                    cropShape={cropShape}
                    restrictPosition={false}
                    showGrid={false}
                    onCropChange={onCropChange}
                    onCropComplete={onCropComplete}
                    onZoomChange={onZoomChange}
                    cropSize={{height: cropHeight, width: cropWidth}}
                    objectFit={'cover'}
                />
            </div>
            <div className={style.controls}>
                <div className={style.sliderContainer}>
                    <div className={style.sliderWrapper}>
                        <Typography
                            variant="overline"
                            classes={{root: style.sliderLabel}}
                        >
                            Zoom
                        </Typography>
                        <Slider
                            value={zoom}
                            min={0.4}
                            max={3}
                            step={0.1}
                            aria-labelledby="Zoom"
                            classes={{root: style.slider}}
                            onChange={(e, zoom) => setZoom(zoom as number)}
                        />
                    </div>
                    <CustomButton
                        text={i18n.t('common.save')}
                        onClick={showCroppedImage}
                        sx={style.btn}
                    />
                </div>
                <div className={style.sliderContainer}>
                    <div className={style.sliderWrapper}>
                        <Typography
                            variant="overline"
                            classes={{root: style.sliderLabel}}
                        >
                            Rotation
                        </Typography>
                        <Slider
                            value={rotation}
                            min={0}
                            max={360}
                            step={1}
                            aria-valuetext={'Rotate'}
                            aria-labelledby="Rotate"
                            classes={{root: style.slider}}
                            onChange={(e, rotation) => setRotation(rotation as number)}
                        />
                    </div>
                    <CustomButton
                        text={i18n.t('common.cancel')}
                        onClick={onClose}
                        sx={style.btn}
                    />
                </div>
            </div>
        </div>
    );
};

export default ImageCropper;