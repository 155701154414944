import React, {FC, ReactElement} from 'react';
import styles from './warning-modal.module.scss';

interface WarningModalProps {
    isOpen: boolean;
    icon: ReactElement;
    title: string;
    text: string;
    confirmBtnText: string;
    cancelBtnText: string;
    onConfirm: () => void;
    onCancel: () => void;
    classNameBtn?: string;
}

export const WarningModal: FC<WarningModalProps> = ({
    isOpen, icon, title, text, confirmBtnText, cancelBtnText, onConfirm, onCancel, classNameBtn
}) => {
    if (!isOpen) return <></>;

    return (
        <div className={styles.container}>
            <div className={styles.modal}>
                <div className={styles.textWrapper}>
                    <div className={styles.icon}>
                        {icon}
                    </div>
                    <div className={`${styles.text} ${styles.title}`}>
                        {title}
                    </div>
                    <div className={`${styles.text} ${styles.subtext}`}>
                        {text}
                    </div>
                </div>
                <div className={`${styles.btnWrapper} ${classNameBtn}`}>
                    <div className={styles.cancelBtn} onClick={onCancel}>
                        {cancelBtnText}
                    </div>
                    <div className={styles.confirmBtn} onClick={onConfirm}>
                        {confirmBtnText}
                    </div>
                </div>
            </div>
        </div>
    );
};