import {FC} from 'react';
import styles from './text-highlighter.module.scss';

interface TextHighlighterProps {
    text: string,
    textToHighlight: string
}

export const TextHighlighter: FC<TextHighlighterProps> = ({
    text, textToHighlight
}) => {
    if (!textToHighlight || textToHighlight.trim() === '') {
        return <span>{text}</span>;
    }

    if (!text) return <></>;

    const escapedTextToHighlight = textToHighlight.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
    const regexReplace = new RegExp(escapedTextToHighlight, 'gi');
    const getTextToDisplay = text.replace(regexReplace, (match) =>
        `<span class=${styles.highlighter}>${match}</span>`
    );

    return (
        <span
            dangerouslySetInnerHTML={{__html: getTextToDisplay}}
        />
    );

};