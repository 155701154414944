import {FC, ReactElement} from 'react';
import { useKeycloak } from '@react-keycloak/web';
import {Navigate} from 'react-router-dom';
import MainShell from '../components/layout/MainShell/MainShell';
import {useAppSelector} from '../store/appDispatch';
import {selectUser} from '../store/slices/userSessionSlice';
import ContactWidget from '../components/widget/ContactWidget';
import {IS_BUSINESS_ACCOUNT} from '../store/utils/userSession';

interface ProtectedProps {
    redirectTo?: string,
    component: ReactElement<any>,
    hasStickyMobileFooter?: boolean,
    onlyForBusinessAccount?: boolean
}

export const Protected: FC<ProtectedProps> = ({
    redirectTo = '/',
    component,
    hasStickyMobileFooter,
    onlyForBusinessAccount
}) => {
    const { keycloak } = useKeycloak();
    const user = useAppSelector(selectUser);

    const loggedIn = keycloak.authenticated;

    if (onlyForBusinessAccount && !IS_BUSINESS_ACCOUNT()) {
        return <Navigate to={redirectTo} replace />;
    }

    if (!loggedIn) {
        keycloak.login();
        return <></>;
    }

    if (user == null) {
        return <></>;
    }

    if (!component) {
        return <Navigate to={redirectTo} replace />;
    }

    return (
        <MainShell hasStickyFooter={hasStickyMobileFooter}>
            {component}
            <ContactWidget />
        </MainShell>
    );
};