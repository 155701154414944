import {FC} from 'react';
import {LineChart} from '@mui/x-charts';
import dayjs from 'dayjs';
import style from './chart.module.scss';
import Divider from '../../common/divider/Divider';

interface ChartProps {
    xAxis: Date[] | string[],
    data: number[],
    title: string,
    isToday: boolean
}

const Chart: FC<ChartProps> = ({
    xAxis, data, title, isToday
}) => {
    return (
        <div className={style.chartWrapper}>
            <div className={style.title}>
                {title}
            </div>
            <Divider />
            <div className={style.chart}>
                <LineChart
                    xAxis={[
                        {
                            data: xAxis,
                            tickInterval: xAxis,
                            scaleType: 'utc',
                            valueFormatter: (date) => isToday ? dayjs(date).format('h A') : dayjs(date).format('MMM D, YYYY'),
                            classes: {
                                tick: style.tick, line: style.line, tickLabel: style.thickLabel
                            }
                        },
                    ]}
                    yAxis={[{
                        classes: {
                            tick: style.tick, line: style.line, tickLabel: style.thickLabel
                        }
                    }]}
                    series={[
                        { data: data, showMark: false, curve: 'linear' }
                    ]}
                />
            </div>
        </div>

    );
};

export default Chart;