import {isEmpty} from '../../utils/common';
import {UserCardProfile, UserCardProfilesPagination} from '../../types/userCardProfile';
import {CardProfileConverter} from './CardProfileConverter';

export const UserCardProfileConverter = {
    convertFromUserCardProfileApiToUserCardProfilesPagination(data: any): UserCardProfilesPagination | null {
        if (!data) return null;
        const content = data.content;

        return {
            profiles: UserCardProfileConverter.convertFromUserCardProfileApiToUserCardProfiles(content),
            noofElements: data.numberOfElements,
            size: data.size,
            totalElements: data.totalElements ?? 0,
            totalPages: data.totalPages
        };
    },
    convertFromUserCardProfileApiToUserCardProfiles(data: any[]): UserCardProfile[] {
        if (isEmpty(data)) return [];

        const userCardProfiles: UserCardProfile[] = [];

        data.forEach(d => {
            const basic = CardProfileConverter.convertFromCardProfileApiToCardProfileBasic(d.profileBasic);
            if (!basic) return;

            userCardProfiles.push({
                cardProfileId: d.profileBasic?.cardProfileId,
                basic,
                status: d.profileStatus,
                uuid: d.uuid,
                owner: {
                    id: d.owner.id,
                    email: d.owner.email
                }
            });
        });

        return userCardProfiles;
    },
};