import React, { FC, useState } from 'react';
import SettingsBox from '../SettingsBox';
import styles from './support-tab.module.scss';
import { Input } from '../../common/input/Input';
import { TextArea } from '../../common/textArea/TextArea';
import CustomButton from '../../common/button/CustomButton';
import i18n from '../../../translations/i18n';
import { ICARDS_SUPPORT_EMAIL } from '../../../constants/common';
import { useAppSelector } from '../../../store/appDispatch';
import { selectUser } from '../../../store/slices/userSessionSlice';
import { isBlank } from '../../../utils/common';
import { SendEmail } from '../../../types/user';
import {sendSupportEmailById} from '../../../api/email';
import {
    NOT_ALLOWED_MIN_SPECIAL_CHARS_REGEX
} from '../../../utils/validationUtils';

const SupportTab: FC = () => {
    const user = useAppSelector(selectUser);
    const [loading, setLoading] = useState<boolean>(false);
    const [emailSent, setEmailSent] = useState<boolean>(false);
    
    const [emailData, setEmailData] = useState<SendEmail>({
        to: ICARDS_SUPPORT_EMAIL,
        subject: '',
        body: ''
    });

    const isDisabled = isBlank(emailData.subject) || isBlank(emailData.body);

    const handleSendEmail = () => {
        setLoading(true);
        sendSupportEmailById(user.id, emailData)
            .then(res => !res.error && setEmailSent(true))
            .finally(() => setLoading(false));
    };
    
    return (
        <SettingsBox title={i18n.t('common.support')}>
            <div className={styles.container}>
                <Input
                    notAllowedSpecialChars={NOT_ALLOWED_MIN_SPECIAL_CHARS_REGEX}
                    className={styles.grayInput}
                    label={i18n.t('common.to')}
                    value={ICARDS_SUPPORT_EMAIL}
                    disabled
                />
                <Input
                    notAllowedSpecialChars={NOT_ALLOWED_MIN_SPECIAL_CHARS_REGEX}
                    className={styles.input}
                    label={i18n.t('common.subject')}
                    placeholder={i18n.t('common.subjectLine')}
                    value={emailData.subject}
                    onChange={(e) => setEmailData({...emailData, subject: e.target.value})}
                    required
                />
                <TextArea
                    notAllowedSpecialChars={NOT_ALLOWED_MIN_SPECIAL_CHARS_REGEX}
                    label={i18n.t('common.message')}
                    placeholder={`${i18n.t('common.message')}...`}
                    value={emailData.body}
                    onChange={(e) => setEmailData({...emailData, body: e.target.value})}
                    rows={10}
                    required
                />
                {emailSent ?
                    <CustomButton
                        text={i18n.t('common.sent')}
                        sx={styles.btnSent}
                        onClick={() => {}}
                    />
                    : <CustomButton
                        text={i18n.t('common.send')}
                        disabled={isDisabled}
                        sx={styles.btn}
                        onClick={handleSendEmail}
                        loading={loading}
                    />
                }
            </div>
        </SettingsBox>
    );
};

export default SupportTab;
