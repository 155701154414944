const ENGLISH = 'English';
const FRENCH = 'French';
const GERMAN = 'Deutsch';

export const ENGLISH_VALUE = 'EN';
export const FRENCH_VALUE = 'FR';
export const GERMAN_VALUE = 'DE';

export type Language = typeof ENGLISH | typeof FRENCH | typeof GERMAN;

export const SUPPORTED_LANGUAGES = [
    { item: ENGLISH, value: ENGLISH_VALUE },
    { item: FRENCH, value: FRENCH_VALUE },
    { item: GERMAN, value: GERMAN_VALUE }
];

export interface LanguageType {
    item: string;
    value: string;
}