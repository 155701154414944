import React, {FC, useRef} from 'react';
import styles from './card-box.module.scss';
import i18n from '../../../translations/i18n';
import {ReactComponent as EditIcon} from '../../../assets/icons/edit.svg';
import {ReactComponent as ThreeDotsIcon} from '../../../assets/icons/three-dots.svg';
import {ReactComponent as StatusIcon} from '../../../assets/icons/contactless.svg';
import {ReactComponent as DefaultProfileIcon} from '../../../assets/icons/preview-avatar.svg';
import {
    ACTIVE,
    INACTIVE,
    UserCardProfile
} from '../../../types/userCardProfile';
import {TextHighlighter} from '../../common/textHighlighter/TextHighlighter';
import {useAppSelector} from '../../../store/appDispatch';
import {selectUser} from '../../../store/slices/userSessionSlice';
import CustomTooltip from '../../common/customTooltip/CustomTooltip';


const imageBaseUrl = process.env.REACT_APP_IMAGE_BASE_URL;

interface CardGridViewProps {
    profile: UserCardProfile,
    onEdit: (cardProfileId: number) => void,
    searchText: string,
    showMenu: boolean,
    handleModalView: () => void,
    menu: any,
    managedByOtherUserFlag: boolean,
    managedByCompanyFlag: boolean
}

const CardGridView: FC<CardGridViewProps> = ({
    profile, onEdit, searchText, showMenu, handleModalView, menu,
    managedByOtherUserFlag, managedByCompanyFlag
}) => {
    const user = useAppSelector(selectUser);

    const profilePhotoPath = profile.basic.profilePhotoPath;
    const profilePhotoUrl = (imageBaseUrl && profilePhotoPath) ? (imageBaseUrl + profilePhotoPath) : undefined;


    const menuIconRef = useRef<HTMLDivElement>(null);

    const handleOnEdit = () => onEdit(profile.cardProfileId);

    const isProfileActive = profile.status === ACTIVE;
    const isInactive = profile.status === INACTIVE;

    const profileIndicatorStyle = isProfileActive ? styles.active : isInactive ? styles.inactive : '';
    const tooltipMsg = isProfileActive ? i18n.t('common.activated') : isInactive ? i18n.t('common.inactive') : i18n.t('common.notActivated');

    return (
        <div className={styles.container}>
            <div className={styles.border}>
                {managedByOtherUserFlag &&
                    <div className={styles.owner}>
                        Managed by {profile.owner.email}
                    </div>
                }
                {managedByCompanyFlag &&
                    <div className={styles.owner}>
                        Managed by {user.activeCompany?.name}
                    </div>
                }
                <CustomTooltip
                    title={
                        <div style={{whiteSpace: 'pre-line', textAlign: 'center', fontSize: '14px'}}>
                            {tooltipMsg}
                        </div>
                    }
                    placement='bottom'
                >
                    <div className={`${styles.statusWrapper} ${profileIndicatorStyle}`}>
                        <StatusIcon className={styles.dotsIcon}/>
                    </div>
                </CustomTooltip>
                <div className={styles.imageWrapper}>
                    <div className={styles.imageBox}>
                        {profilePhotoUrl
                            ? <img src={profilePhotoUrl} alt='profile-photo' className={styles.image}/>
                            : <DefaultProfileIcon className={styles.image}/>
                        }
                    </div>
                </div>
                <div className={styles.textBox}>
                    <div className={`${styles.text} ${styles.idText}`}>
                        #{profile.cardProfileId}
                    </div>
                    <div className={`${styles.text} ${styles.usernameText}`}>
                        <TextHighlighter
                            text={profile.basic?.fullname ?? ''}
                            textToHighlight={searchText}
                        />
                    </div>
                </div>
            </div>
            <div className={styles.footer}>
                <div
                    className={styles.footerSectionLeft}
                    onClick={handleOnEdit}
                >
                    <EditIcon className={styles.editIcon}/>
                    <div className={`${styles.text} ${styles.btnText}`}>
                        {i18n.t('common.edit')}
                    </div>
                </div>
                <div
                    className={`${styles.footerSectionRight} ${showMenu && styles.menuActive}`}
                    onClick={handleModalView}
                    ref={menuIconRef}
                >
                    <ThreeDotsIcon className={styles.moreIcon}/>
                    <div className={`${styles.text} ${styles.btnText} `}>
                        {i18n.t('common.more')}
                    </div>
                </div>
            </div>
            {showMenu && menu}
        </div>
    );
};

export default CardGridView;