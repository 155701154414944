import Keycloak, {KeycloakResponseType} from 'keycloak-js';

const keycloakClient = new Keycloak({
    url: process.env.REACT_APP_KEYCLOAK_AUTH_SERVER_URL,
    realm: process.env.REACT_APP_KEYCLOAK_AUTH_REALM ?? '',
    clientId: process.env.REACT_APP_KEYCLOAK_AUTH_CLIENT_ID ?? ''
});

const initOptions = {
    KeycloakResponseType: 'code' as KeycloakResponseType,
    //silentCheckSsoRedirectUri: window.location.origin + "/silent-check-sso.html",
    checkLoginIframe: false,
    pkceMethod: 'S256'
};

export {
    keycloakClient,
    initOptions
};