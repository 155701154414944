import { I18n } from 'i18n-js';
import { ENGLISH } from './en';
import {FRENCH} from './fr';
import {GERMAN} from './de';

export const translations = {
    en: ENGLISH,
    fr: FRENCH,
    de: GERMAN
};

const i18n = new I18n(translations);
i18n.enableFallback = true;

export default i18n;