import React, {FC, ReactNode, useEffect, useMemo} from 'react';
import {useLocation} from 'react-router-dom';
import {notBlank} from '../../utils/common';
import {acceptCompanyInvitation} from '../../api/company';
import {UserConverter} from '../../converters/api/UserConverter';
import {selectUser, storeUser} from '../../store/slices/userSessionSlice';
import {useAppDispatch, useAppSelector} from '../../store/appDispatch';

const JOIN_QUERY_PARAM = 'join';

interface JoinCompanyWrapperProps {
    children: ReactNode;
}

const JoinCompanyWrapper: FC<JoinCompanyWrapperProps> = ({children}) => {
    const { search } = useLocation();
    const user = useAppSelector(selectUser);
    const queryParams = useMemo(() => new URLSearchParams(search), [search]);
    const dispatch = useAppDispatch();

    const joinCompanyCode = queryParams?.get(JOIN_QUERY_PARAM);
    const joinCompanyCodeExists = !!(joinCompanyCode && notBlank(joinCompanyCode));

    useEffect(() => {
        user && joinCompanyCodeExists && acceptCompanyInvitation(joinCompanyCode)
            .then(res => {
                if (res.error) return;

                const user = UserConverter.convertFromUserApiToUser(res.data);
                user && dispatch(storeUser(user));
            });
    }, [joinCompanyCodeExists, user]);

    return (
        <>
            {children}
        </>
    );
};

export default JoinCompanyWrapper;