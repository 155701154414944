import React from 'react';
import styles from './no-new-notifications.module.scss';
import i18n from '../../translations/i18n';
import {ReactComponent as AlarmIcon} from '../../assets/icons/alarm.svg';

const NoNewNotifications = React.memo( () => {

    return (
        <div className={styles.container}>
            <AlarmIcon />
            <div className={styles.text}>
                {i18n.t('notification.noNewNotifications')}
            </div>
        </div>
    );
});

export default NoNewNotifications;