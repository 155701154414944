import React, {FC, useMemo} from 'react';
import {CircularProgress} from '@mui/material';
import {useAppSelector} from '../../../store/appDispatch';
import {selectUser} from '../../../store/slices/userSessionSlice';
import {FRENCH_VALUE, GERMAN_VALUE, SUPPORTED_LANGUAGES} from '../../../types/language';
import {ReactComponent as AppleWalletDEIcon} from '../../../assets/icons/wallet/DE_Add_to_Apple_Wallet_RGB_101421.svg';
import {ReactComponent as AppleWalletFRIcon} from '../../../assets/icons/wallet/FR_Add_to_Apple_Wallet_RGB_102921.svg';
import {ReactComponent as AppleWalletENIcon} from '../../../assets/icons/wallet/US-UK_Add_to_Apple_Wallet_RGB_101421.svg';
import {ReactComponent as GoogleWalletDEIcon} from '../../../assets/icons/wallet/de_add_to_google_wallet_add-wallet-badge.svg';
import {ReactComponent as GoogleWalletFRIcon} from '../../../assets/icons/wallet/frFR_add_to_google_wallet_add-wallet-badge.svg';
import {ReactComponent as GoogleWalletENIcon} from '../../../assets/icons/wallet/enUS_add_to_google_wallet_add-wallet-badge.svg';
import {isIOS, notMobile} from '../../../utils/device';
import {useDebouncedCallback} from 'use-debounce';
import {API_CALL_WALLET_DOWNLOAD_DEBOUNCE_TIME_IN_MS} from '../../../constants/common';

interface AddToWalletButtonProps {
    loading: boolean;
    onAddWallet: () => void;
    sx: any;
}

const AddToWalletButton: FC<AddToWalletButtonProps> = ({
    loading, onAddWallet, sx
}) => {

    if (notMobile()) return <></>;

    const user = useAppSelector(selectUser);
    const lang = user?.language?.toLowerCase()?.trim();

    const supportedLang = useMemo(() => SUPPORTED_LANGUAGES.map(l => l.value.toLowerCase()), []);

    let IconComponent;
    if (supportedLang.includes(lang)) {
        if (lang === GERMAN_VALUE.toLowerCase()) {
            IconComponent = isIOS() ? AppleWalletDEIcon : GoogleWalletDEIcon;
        }
        if (lang === FRENCH_VALUE.toLowerCase()) {
            IconComponent = isIOS() ? AppleWalletFRIcon : GoogleWalletFRIcon;
        }
    }

    if (!IconComponent) {
        IconComponent = isIOS() ? AppleWalletENIcon : GoogleWalletENIcon;
    }

    const handleOnClickDebounced = useDebouncedCallback(() => {
        return onAddWallet();
    }, API_CALL_WALLET_DOWNLOAD_DEBOUNCE_TIME_IN_MS);

    return (
        <div className={sx}>
            {loading
                ? <CircularProgress />
                : <IconComponent
                    style={{width: '180px', height: '50px'}}
                    onClick={handleOnClickDebounced}
                />
            }
        </div>
    );
};

export default AddToWalletButton;