import React from 'react';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';

import PreviewCardProfile from '../pages/previewCardProfile/PreviewCardProfile';
import { routes } from '../constants/routes';
import { getUrl } from '../utils/config';


const PublicPageRouter = () => {
    const router = createBrowserRouter([
        {
            path: getUrl(routes.previewCardProfile),
            element: <PreviewCardProfile />,
        }
    ]);

    return <RouterProvider router={router} />;
};

export default PublicPageRouter;
