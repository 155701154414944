import { FC } from 'react';
import ColorInput from '../colorInput/ColorInput';
import styles from './custom-colors.module.scss';
import i18n from '../../../../translations/i18n';
import { CardProfileStyle } from '../../../../types/cardProfile';
import { WalletStyle } from '../../../../types/walletCard';
import {isAndroid, isAppleDevice} from '../../../../utils/device';
import {getAndroidWalletTextColor} from '../../../../utils/appearance/appearanceUtils';

interface WalletCustomColorsProps {
    profileStyle: CardProfileStyle | undefined;
    setProfileStyle: (styleUpdater: (prevState: CardProfileStyle | WalletStyle | undefined) => CardProfileStyle | undefined) => void;
    isWallet: boolean;
}

const WalletCustomColors: FC<WalletCustomColorsProps> = ({
    profileStyle, setProfileStyle, isWallet
}) => {

    const handleColorChange = (colorKey: keyof CardProfileStyle, color: string) => {
        setProfileStyle((prevState) => ({
            ...prevState,
            [colorKey]: color
        }));
    };

    const handleBgColorChange = (colorKey: keyof CardProfileStyle, color: string) => {
        setProfileStyle((prevState) => ({
            ...prevState,
            [colorKey]: color,
            cardTextColor: isWallet && isAndroid() ? getAndroidWalletTextColor(color) : color
        }));
    };

    return (
        <div className={styles.inputsContainer}>
            <ColorInput
                label={i18n.t('labels.cardBackgroundColor')}
                value={profileStyle?.cardBgColor}
                onChange={(e) => handleBgColorChange('cardBgColor', e.target.value)}
            />
            {isAppleDevice() &&
                <ColorInput
                    label={i18n.t('labels.cardTextColor')}
                    value={profileStyle?.cardTextColor}
                    onChange={(e) => handleColorChange('cardTextColor', e.target.value)}
                />
            }
        </div>
    );
};

export default WalletCustomColors;
