import React, {FC, useEffect, useState} from 'react';
import HomeHeader from '../../components/layout/HomeHeader/HomeHeader';
import { ThemeProvider } from '../../context/theme/ThemeProvider';
import styles from './analytics.module.scss';
import useAnalyticsController from './useAnalyticsController';
import AnalyticsHeader from '../../components/analytics/analyticsHeader/AnalyticsHeader';
import StatsBox from '../../components/analytics/statsBox/StatsBox';
import i18n from '../../translations/i18n';
import {CardProfileAnalytics} from '../../types/analytics';
import Chart from '../../components/analytics/chart/Chart';
import {
    convertLastDaysToNoofDays, getAnalyticsSearchForFilter,
    getCardProfileAnalytics,
    getChartData,
    getChartXAxis,
    getLastDaysValue
} from './analyticsUtil';
import CountriesChart from '../../components/analytics/chart/CountriesChart';


const Analytics: FC = () => {
    const { loading, analyticsData, setApiFilter, apiFilter, profiles} = useAnalyticsController();
    const [cardProfileAnalytics, setCardProfileAnalytics] = useState<CardProfileAnalytics | undefined>(undefined);

    useEffect(() => {
        setCardProfileAnalytics(getCardProfileAnalytics(analyticsData));
    }, [loading]);

    const viewsTimestampData = cardProfileAnalytics?.cardViews?.timestampData ?? [];
    const savesTimestampData = cardProfileAnalytics?.contactSaves?.timestampData ?? [];
    const uniqueTimestampData = cardProfileAnalytics?.uniqueUsers?.timestampData ?? [];
    const countriesData = cardProfileAnalytics?.cardViews?.countriesData ?? [];

    const noofDays: number = convertLastDaysToNoofDays(apiFilter.lastDays, analyticsData.daysSinceProfileIsCreated);

    const onSelectLastDays = (lastDayItem: string) => {
        setApiFilter(prevState => ({
            ...prevState,
            lastDays: getLastDaysValue(lastDayItem)
        }));
    };

    const onSelectCard = (value: {label: string, id: number}) => {
        setApiFilter(prevState => ({
            ...prevState,
            cardProfileId: value.id > 0 ? value.id : undefined,
            searchFor: value.id < 0 ? getAnalyticsSearchForFilter(value.id) : undefined
        }));
    };

    //if (loading) return <></>;

    return (
        <>
            <HomeHeader/>
            <ThemeProvider>
                <div className={styles.container}>
                    <AnalyticsHeader
                        onSelectLastDays={onSelectLastDays}
                        onSelectCard={onSelectCard}
                        profiles={profiles}
                    />
                    <div className={styles.statsBox}>
                        <StatsBox
                            title={i18n.t('common.cardViews')}
                            stats={cardProfileAnalytics?.cardViews?.count ?? 0}
                            increaseStats={cardProfileAnalytics?.cardViews?.increase ?? 0}
                        />
                        <StatsBox
                            title={i18n.t('common.contactSaved')}
                            stats={cardProfileAnalytics?.contactSaves?.count ?? 0}
                            increaseStats={cardProfileAnalytics?.contactSaves?.increase ?? 0}
                        />
                        <StatsBox
                            title={i18n.t('common.uniqueUsers')}
                            stats={cardProfileAnalytics?.uniqueUsers?.count ?? 0}
                            increaseStats={cardProfileAnalytics?.uniqueUsers?.increase ?? 0}
                        />
                    </div>
                    <Chart
                        xAxis={getChartXAxis(noofDays)}
                        data={getChartData(viewsTimestampData, noofDays)}
                        title={i18n.t('common.cardViews')}
                        isToday={noofDays === 1}
                    />
                    <Chart
                        xAxis={getChartXAxis(noofDays)}
                        data={getChartData(savesTimestampData, noofDays)}
                        title={i18n.t('common.contactSaved')}
                        isToday={noofDays === 1}
                    />
                    <Chart
                        xAxis={getChartXAxis(noofDays)}
                        data={getChartData(uniqueTimestampData, noofDays)}
                        title={i18n.t('common.uniqueUsers')}
                        isToday={noofDays === 1}
                    />
                    <CountriesChart
                        title={i18n.t('common.usersByCountries')}
                        data={countriesData}
                    />
                </div>
            </ThemeProvider>
        </>
    );
};

export default Analytics;