import {FC} from 'react';
import { TooltipProps, Tooltip } from '@mui/material';

const CustomTooltip: FC<TooltipProps> = (props) => {
    return (
        <Tooltip
            {...props}
            arrow={true}
            componentsProps={{
                tooltip: {
                    sx: {
                        bgcolor: '#212121',
                        '& .MuiTooltip-arrow': {
                            color: '#212121',
                        },
                    },
                },
            }}
            PopperProps={{
                popperOptions: {
                    strategy: 'fixed',
                    modifiers: [
                        {
                            name: 'preventOverflow',
                            options: {
                                enabled: true,
                            }
                        },
                    ]
                }
            }}
            disableFocusListener
        >
            {props.children}
        </Tooltip>
    );
};

export default CustomTooltip;