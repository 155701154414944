import { FC, ReactNode, createContext, useContext, useState } from 'react';
import { WalletCardBasic, WalletStyle } from '../../types/walletCard';

interface WalletCardDataContextState {
    walletBasic?: WalletCardBasic,
    setWalletBasic: React.Dispatch<React.SetStateAction<any>>,
    walletStyle?: WalletStyle,
    setWalletStyle: React.Dispatch<React.SetStateAction<any>>,
    newWalletProfilePhoto?: File ;
    setNewWalletProfilePhoto: React.Dispatch<React.SetStateAction<any>>;
    newWalletCoverPhoto?: File;
    setNewWalletCoverPhoto: React.Dispatch<React.SetStateAction<any>>;
    isWalletDirty: boolean
    setIsWalletDirty: React.Dispatch<React.SetStateAction<any>>;
    emptyFields: number[],
    setEmptyFields: React.Dispatch<React.SetStateAction<any>>;
    isCustom: boolean;
    setIsCustom: React.Dispatch<React.SetStateAction<any>>;
}

interface WalletCardDataProviderProps {
    children: ReactNode;
}

const WalletCardDataContext = createContext<WalletCardDataContextState | undefined>(
    undefined
);

export const WalletCardDataProvider: FC<WalletCardDataProviderProps> = ({ children }) => {

    const [walletBasic, setWalletBasic] = useState<WalletCardBasic>();
    const [walletStyle, setWalletStyle] = useState<WalletStyle>();
    const [newWalletProfilePhoto, setNewWalletProfilePhoto] = useState<File | undefined>();
    const [newWalletCoverPhoto, setNewWalletCoverPhoto] = useState<File | undefined>();
    const [isWalletDirty, setIsWalletDirty] = useState<boolean>(false);
    const [emptyFields, setEmptyFields] = useState<number[]>([]);
    const [isCustom, setIsCustom] = useState<boolean>(false);

    return (
        <WalletCardDataContext.Provider
            value={{
                isCustom,
                setIsCustom,
                walletBasic,
                setWalletBasic,
                walletStyle,
                setWalletStyle,
                newWalletProfilePhoto,
                newWalletCoverPhoto,
                setNewWalletProfilePhoto,
                setNewWalletCoverPhoto,
                isWalletDirty,
                setIsWalletDirty,
                emptyFields,
                setEmptyFields
            }}
        >
            {children}
        </WalletCardDataContext.Provider>
    );
};

export const useWalletCardDataContext = () => {
    const context = useContext(WalletCardDataContext);
    if (!context) {
        throw new Error(
            'useWalletCardDataContext must be used within a WalletCardDataProvider'
        );
    }
    return context;
};
