export const FRENCH = {
    analytics: {
        country: 'Pays',
        previousPeriod: 'par rapport à la période précédente',
        users: 'Utiliateurs'
    },
    header: {
        accountSetting: 'Paramètre du compte',
        accountSettings: 'Paramètres du compte',
        analytics: 'Analytiques',
        companyCards: 'Cartes d\'entreprise',
        myCards: 'Mes Cartes',
        profilePage: 'Profil',
        walletCard: 'Carte de portefeuille',
        settings: 'Paramètres',
        signedInAs: 'Connecté en tant que',
        signOut: 'Se déconnecter',
        switchToBusinessAccount: 'Passer au compte professionnel',
        visitWebsite: 'Visitez Web',
        youAreEditing: 'Vous modifiez des cartes {{noofCards}}'
    },
    common: {
        about: 'À propos',
        actions: 'Actes',
        activated: 'Activé',
        addMember: 'Ajouter un membre',
        appearance: 'Apparence',
        allCards: 'Toutes les cartes',
        assign: 'Attribuer',
        cancel: 'Annuler',
        assignCard: 'Attribuer une carte',
        assignCardTo: 'Attribuer une carte à',
        cardAssignedTo: 'Carte attribuée à',
        cardsSelected: 'Cartes sélectionnées',
        cardViews: 'Vues de cartes',
        changeCardOwner: 'Changer le propriétaire de la carte',
        changePassword: 'Changer le mot de passe',
        changesSaved: 'Enregistrées',
        close: 'Fermer',
        companyCards: 'Cartes d\'entreprise',
        companyLogo: 'logo d\'entreprise',
        companyMembers: 'Membres de l\'entreprise',
        confirmPassword: 'Confirmer le mot de passe',
        contact: 'Contact',
        contacts: 'Contacts',
        contactSaved: 'Contact enregistré',
        copy: 'Copie',
        copied: 'Copié!',
        currentPassword: 'Mot de passe actuel',
        dataAndPrivacy: 'Données et confidentialité',
        deleteCard: 'Supprimer la carte',
        deletePermanently: 'Supprimer définitivement',
        dismiss: 'Rejeter',
        download: 'Télécharger',
        edit: 'Modifier',
        editCard: 'Modifier la carte',
        email: 'Email',
        emailDash: 'E-mail',
        emailAddress: 'Adresse e-mail',
        enabled: 'Activé',
        facebook: 'Facebook',
        general: 'Généralités',
        grid: 'Grille',
        inactive: 'Désactiver',
        instagram: 'Instagram',
        inviteAs: 'Inviter comme',
        inviteNewMember: 'Inviter un nouveau membre',
        language: 'Langue',
        leave: 'Partir',
        links: 'Liens',
        linkedin: 'Linkedin',
        list: 'Liste',
        managedBy: 'Géré par',
        markAllAsRead: 'Marquer tout comme lu',
        member: 'Membre',
        message: 'Message',
        more: 'Plus',
        moveToCompany: 'Déplacer vers cartes d\'entreprise',
        moveToMyCards: 'Déplacer vers Mes Cartes',
        myCards: 'Mes cartes',
        name: 'Nom',
        newPassword: 'Nouveau mot de passe',
        noFileChosen: 'Aucun fichier choisi',
        notActivated: 'Non activé',
        notifications: 'Notifications',
        others: 'Autres',
        password: 'Mot de passe',
        pending: 'En attente',
        phone: 'Téléphone',
        previewCard: 'Aperçu de la carte',
        proceed: 'Procéder',
        pleaseSelectOption: 'Veuillez sélectionner une option',
        qrCode: 'QR code',
        refreshPage: 'Veuillez rafraîchir la page pour continuer',
        reload: 'Recharger',
        remove: 'Supprimer',
        removeMember: 'Supprimer le membre',
        role: 'Rôle',
        save: 'Enregistrer',
        saveChanges: 'Enregistrer les modifications',
        saveContact: 'Enregistrer le contact',
        search: 'Rechercher',
        selectOption: 'Sélectionner une option',
        selectPlatform: 'Sélectionner une plateforme',
        send: 'Envoyer',
        sent: 'Envoyée',
        sessionExpired: 'Session expirée',
        setInactive: 'Définir comme inactif',
        share: 'Partager',
        shareCard: 'Partager la carte',
        stay: 'Rester',
        status: 'Statut',
        subject: 'Objet',
        subjectLine: 'Ligne d\'objet',
        support: 'Soutien',
        today: 'Aujourd\'hui',
        tiktok: 'Tiktok',
        title: 'Titre',
        to: 'À',
        uniqueUsers: 'Utilisateurs uniques',
        unnamed: 'Anonyme',
        unsavedChanges: 'Modifications non enregistrées',
        unsavedChangesText: 'On dirait que tu as changé quelque chose. Si vous quittez avant d\'enregistrer, vos modifications seront perdues.',
        usersByCountries: 'Utilisateurs par Pays',
        viewAll: 'Voir tout',
        viewMode: 'Mode d\'affichage',
        yourAccount: 'Votre compte',
        website: 'Site web',
        whatsapp: 'WhatsApp',
        x: 'X',
        yes: 'Oui',
        yourBusinessCard: 'Votre carte de visite'
    },
    contact: {
        description1: 'Avez-vous des questions ou besoin de conseils?',
        description2: 'Nous serions ravis de vous aider!',
        hello: 'Bonjour!',
        liveChat: 'Chat en direct'
    },
    modal: {
        addUserRoleText: 'Etes-vous sûr de vouloir attribuer le rôle {{role}} à {{username}}?',
        areYouSure: 'Êtes-vous sûr ?',
        changeCardOwnerDesc: 'En cas de changement de propriétaire, vous perdrez l\'accès à la gestion et à la consultation de ce profil',
        deleteAccountConfirm: 'Oui, supprimer mon compte',
        deleteAccountText: 'Êtes-vous certain de vouloir supprimer définitivement votre compte iCards ? Veuillez noter qu\'une fois supprimé, il n\'y a aucun moyen de restaurer le compte ou ses données.',
        deleteText: 'Cette action est irréversible. Si vous supprimez cette carte, elle sera perdue pour toujours. Tout lien ou QR code associé à cette carte ne fonctionnera plus.',
        disable2faTitle: 'Désactiver l’authentification à deux facteurs',
        disable2faDesc: 'Voulez-vous désactiver l’authentification à deux facteurs?',
        enterCompanyName: 'Entrez le nom de l\'entreprise',
        hiThere: 'Salut 👋',
        howCanIhelpYou: 'Comment puis-je t\'aider',
        iCardsTeam : 'L\'équipe iCards',
        iCardsTeamSubTitle : 'Répond généralement dans la journée',
        lostPhysicalCard: 'Avez vous perdu votre carte physique ?',
        lostPhysicalCardText: 'Si votre carte a été perdue ou volée, pour la protection de vos données, vous avez la possibilité de désactiver le profil utilisé pour cette carte. Si votre carte a été endommagée, vous pouvez commander une carte de remplacement avec le lien vers le même profil, et toutes vos informations resteront inchangées.',
        orderNewCardText: 'Commandez une carte de remplacement avec le lien vers le même profil et toutes vos informations resteront inchangées.',
        removeCompanyMemberTitle: 'Supprimer le membre de l\'entreprise',
        removeCompanyMemberDesc: '{{name}} ne peut être supprimé que si aucune carte d\'entreprise ne lui est attribuée. Toutes iCards que {{name}} possède seront également retirées de l\'entreprise.',
        setInactiveText : 'Cette option désactivera le lien vers votre profil et vous pourrez commander une carte de remplacement avec le nouveau profil.',
        startChat : 'Démarrer la discussion',
        setup2faTitle: "Configurer l'authentification à deux facteurs",
        setup2faDesc: "Souhaitez-vous procéder à la configuration de l'authentification à deux facteurs? Vous devrez terminer la configuration avant de pouvoir la désactiver à nouveau."
    },
    myCards: {
        clickBtnToCreateCard: 'Cliquez sur le bouton ci-dessous pour créer votre première carte de visite numérique',
        newCard: 'Nouvelle carte',
        orderNewCard: 'Commander une nouvelle carte',
        setInactiveText: 'Désactiver si cette carte a été perdue, volée  ou si elle n\'est plus utilisée. Cette option désactivera le lien vers votre profil.',
        setInactiveText2: 'Désactiver cette carte si vous souhaitez qu\'elle soit invisible.',
        youDontHaveCards: 'Vous n\'avez aucune carte'
    },
    notification: {
        cardActivatedUserTitle: 'Carte activée avec succès !',
        cardActivatedUserDesc: 'Votre carte a été activée et est maintenant prête à l\'emploi. Veuillez la garder en sécurité.',
        cardActivatedOwnerTitle: 'Carte activée avec succès !',
        cardActivatedOwnerDesc: 'Votre carte a été activée. Vous pouvez maintenant la déplacer vers les cartes de l\'entreprise ou changer son propriétaire.',
        cardAssignedTitle: 'Carte assignée à votre compte',
        cardAssignedDesc: 'Une carte a été assignée avec succès à votre compte. Vous pouvez maintenant la gérer via les paramètres de votre compte.',
        cardOwnerTransferredTitle: 'Propriété de la carte transférée',
        cardOwnerTransferredDesc: 'La propriété de votre carte a été transférée à un nouveau propriétaire. Veuillez contacter le support si vous avez des questions ou des préoccupations.',
        cardOwnerReceivedTitle: 'Propriété de la carte reçue',
        cardOwnerReceivedDesc: 'Vous êtes devenu propriétaire d\'une carte. Vous pouvez maintenant la gérer via les paramètres de votre compte.',
        companyRoleAssignedTitle: 'Rôle dans l\'entreprise attribué!',
        companyRoleAssignedDesc: "Vous avez été affecté(e) au nouveau rôle de {{role}} pour l'entreprise {{company}}",
        noNewNotifications: "Vous n'avez aucune nouvelle notification pour le moment"
    },
    labels: {
        buttonBackgroundColor: 'Couleur d\'arrière-plan du bouton',
        buttonTextColor: 'Couleur du texte du bouton',
        cardBackgroundColor: 'Couleur d\'arrière-plan de la carte',
        cardTextColor: 'Couleur du texte de la carte',
        city: 'Ville',
        company: 'Entreprise',
        companyBgColor: 'Couleur de fond de l\'entreprise',
        companyName: 'Nom de l\'entreprise',
        companyTextColor: 'Couleur du texte de l\'entreprise',
        country: 'Pays',
        cover: 'Couverture',
        coverBackgroundColor: 'Couleur d\'arrière-plan de la couverture',
        createCompany: 'Créer une entreprise',
        createNewCompany: 'Créer une nouvelle entreprise',
        department: 'Département',
        filterBy: 'Filtrer par',
        fullname: 'Nom complet',
        iconBackgroundColor: 'Couleur d\'arrière-plan de l\'icône',
        iconsColor: 'Couleur des icônes',
        pendingUserHasNotAcceptYet: 'En attente: attendre que l\'utilisateur active le compte',
        position: 'Poste',
        streetAdress: 'Adresse',
        zipPostal: 'Code postal'
    },
    messages: {
        companyMemberRemoved: 'Le membre de l\'entreprise a été supprimé avec succès.',
        companyWasSuccessfullyCreated: 'L\'entreprise a été créée avec succès',
        internalServerError: 'Erreur interne du serveur',
        noIcardsFound: 'Aucune iCards trouvée',
        passwordHasBeenChanged: 'Le mot de passe a été modifié.',
        passwordsDoNotMatch: 'Le mot de passe et la confirmation du mot de passe ne correspondent pas.',
        profilesSuccessfullyUpdated: '{{noofCards}} profils mis à jour avec succès',
        profileWasSuccessfullyAssigned: 'Le profil a été attribué avec succès.',
        unableToRemoveCompanyMember: 'Impossible de retirer le membre de l\'entreprise car il est assigné à des profils de cartes d\'entreprise.'
    },
    profile: {
        colors: {
            custom: 'Personnalisé',
            default: 'Par défaut',
            green: 'Vert',
            purple: 'Violet',
            red: 'Rouge',
            yellow: 'Jaune'
        },
        error: {
            fileTooLarge: 'Le fichier est trop volumineux. Veuillez télécharger un fichier de moins de 5 Mo.',
            invalidEmailAddress: 'Adresse e-mail invalide',
            invalidFileType: 'Type de fichier invalide. Veuillez télécharger un fichier PNG ou JPEG.',
            specialCharacters: 'Vous ne devez pas utiliser de caractères spéciaux'
        },
        general: {
            changeCover: 'Changer la couverture',
            changePic: 'Changer l\'image',
            dragAndDrop: 'ou faites glisser et déposez',
            noFileChosen: 'Aucun fichier sélectionné',
            pngJpgGif: 'PNG, JPG, GIF jusqu\'à 10 Mo',
            uploadFile: 'Télécharger un fichier',
            uploadPic: 'Télécharger une image'
        },
        links: {
            addLink: 'Ajouter un lien'
        },
        contact: {
            addField: 'Ajouter un champ'
        },
        noContentAdded: 'n\'a pas encore ajouté de contenu',
        poweredBy: 'Propulsé par :'
    },
    selector: {
        last7days: 'Les 7 derniers jours',
        last14days: 'Les 14 derniers jours',
        last30days: 'Les 30 derniers jours',
        totalViews: 'Vues totales'
    },
    settings: {
        deleteYourAccount: 'Supprimer votre compte',
        permanentlyDeleteYourAccount: 'Supprimer votre compte définitivement',
        privacyPolicy: 'Politique de confidentialité',
        termsOfService: 'Conditions d\'utilisation',
        termsAndConditions: 'Conditions d\'utilisation',
        twofaEnableBtn: 'Activer 2FA',
        twofaDisableBtn: 'Désactiver 2FA',
        twofaDesc: 'L\'authentification à deux facteurs protège votre compte en ajoutant une étape de sécurité supplémentaire lorsque vous vous connectez.',
        twofaTitle: 'Authentification à deux facteurs (2FA)'
    },
    activate: {
        assignProfile: 'Voulez-vous activer votre iCard {{uuid}} pour ce compte?',
        failedToActivateProfile: 'Échec de l\'activation de votre iCard',
        profileActivation: 'Activez votre iCard',
        welcomeMessage1: 'Bienvenue sur iCards',
        welcomeMessage2: 'Votre iCard n\'est pas encore activée. Veuillez cliquer sur le bouton ci-dessous pour l\'activer',
        welcomeMessage3: 'Si vous avez déjà un compte, vous devrez vous connecter, sinon vous devrez vous inscrire',
        welcomeActivateButton: 'Activez votre iCard',
        yesActivate: 'Oui, activez!',
        yourProfileIsActivated: 'Votre iCard a été activé avec succès!'
    }
};